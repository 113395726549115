import { Avatar, Button, Card, Space, Table, Tooltip, Typography } from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";

import React, { useCallback, useEffect, useState } from "react";
import { join } from "array-join";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNotification } from "../../utils/util-notification";
import { CardUrl, Page } from "../../components";
import "../../assets/styles/dashboard.css";

import pressImg from "../../assets/media/press.png";
import youtubeImg from "../../assets/media/youtube.png";
import facebookImg from "../../assets/media/facebook.svg";
import instagramImg from "../../assets/media/instagram.png";
import snapchatImg from "../../assets/media/snapchat.svg";
import twitterImg from "../../assets/media/twitter.svg";
import tiktokImg from "../../assets/media/tiktok.png";
import { Country } from "country-state-city";
import { useTranslation } from "react-i18next";

const MyFavorite = () => {
  const { t } = useTranslation();
  const { user } = useUserAuth();
  const { notify } = useNotification();
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState(null);

  //get flag country
  const getFlag = (country) => {
    const val = Country.getAllCountries().find((item) => item.name === country);
    return val.flag;
  };

  const columns = [
    {
      title: t("view-advertisers-myFavorite.title-1"),
      dataIndex: "networkName",
      render: (networkName, all) => (
        <Space size="small">
          <div>{getFlag(all.country)}</div>
          <div>{networkName}</div>
        </Space>
      ),
      width: 120,
      sorter: (a, b) => a.networkName.length - b.networkName.length,
    },
    {
      title: t("view-advertisers-myFavorite.title-2"),
      dataIndex: "url",
      width: 120,
      render: (url, all) => (
        <Tooltip placement="right" title={<CardUrl url={url} />}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Tooltip>
      ),
      sorter: (a, b) => a.url.length - b.url.length,
    },
    {
      title: t("view-advertisers-myFavorite.title-3"),
      dataIndex: "price",
      width: 120,
      render: (price, all) => `€${parseFloat(price).toFixed(2)}`,
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: t("view-advertisers-myFavorite.title-4"),
      dataIndex: "visits",
      width: 120,
      sorter: (a, b) => a.visits.length - b.visits.length,
    },
    {
      title: <div>{t("view-advertisers-myFavorite.title-5")}</div>,
      dataIndex: "networkType",
      render: (networkType, all) =>
        networkType === "facebook" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={facebookImg} />
          </a>
        ) : networkType === "twitter" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={twitterImg} />
          </a>
        ) : networkType === "youtube" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={youtubeImg} />
          </a>
        ) : networkType === "instagram" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={instagramImg} />
          </a>
        ) : networkType === "tiktok" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={tiktokImg} />
          </a>
        ) : networkType === "snapchat" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={snapchatImg} />
          </a>
        ) : (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={pressImg} />
          </a>
        ),
      width: 120,
    },
    {
      title: <div>{t("view-advertisers-myFavorite.title-6")}</div>,
      dataIndex: "actions",
      width: 100,
      render: (id, all) => (
        <div style={{ display: "flex", gap: "2px" }}>
          <Tooltip
            placement="bottom"
            title={t("view-advertisers-myFavorite.title-7")}
          >
            <Button type="primary" ghost icon={<EyeOutlined />} />
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={t("view-advertisers-myFavorite.title-8")}
          >
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onClickDelete(all.idFavorite)}
            />
          </Tooltip>
        </div>
      ),
      fixed: "right",
    },
  ];
  const getAllNetworks = useCallback(async () => {
    setLoadingData(true);

    const q1 = query(collection(db, "networks"));
    const q2 = query(
      collection(db, "favorites"),
      where("userId", "==", user.uid),
      where("status", "==", true)
    );
    const get1 = await getDocs(q1);
    const get2 = await getDocs(q2);

    setData(
      join(
        get1.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        get2.docs.map((doc) => ({ idFavorite: doc.id, ...doc.data() })),
        (left) => left.id,
        (right) => right.idNetwork,
        (left, right) => ({ ...left, ...right })
      ).map((doc) => ({
        networkName: doc.networkName,
        url: doc.url,
        price: doc.gain,
        visits: doc.visits,
        networkType: doc.networkType,
        country: doc.country,
        actions: doc.id,
        idFavorite: doc.idFavorite,
      }))
    );

    setLoadingData(false);
  }, [user.uid]);

  useEffect(() => {
    getAllNetworks();
  }, [getAllNetworks]);
  const onClickDelete = async (id) => {
    const q = doc(db, "favorites", id);
    await deleteDoc(q)
      .then(async () => {
        notify(
          "success",
          t("view-advertisers-myFavorite.notify-1"),
          t("view-advertisers-myFavorite.notify-2")
        );
        getAllNetworks();
      })
      .catch((error) => {
        notify(
          "error",
          t("view-advertisers-myFavorite.notify-3"),
          t("view-advertisers-myFavorite.notify-4")
        );
      });
  };

  return (
    <Page title={t("view-advertisers-myFavorite.title")}>
      <div className="dashboard">
        <Card>
          <Typography.Title level={3}>
            {t("view-advertisers-myFavorite.title")}
          </Typography.Title>
        </Card>
        <Table
          scroll={{
            x: 900,
          }}
          dataSource={data}
          loading={loadingData}
          columns={columns}
        />
      </div>
    </Page>
  );
};

export default MyFavorite;
