import React from "react";
import { Page } from "../../components";
import { InvoicesTable } from "../../sections";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import { useTranslation } from "react-i18next";

const Invoices = () => {
  const { lang } = useUserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Page title={t("view-account-invoices.title-1")}>
      <div style={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            size="large"
            type="primary"
            onClick={() => navigate(`/${lang}/advertiser/me/money`)}
          >
            {t("view-account-invoices.btn")}
          </Button>
        </div>
        <InvoicesTable title={t("view-account-invoices.title-1")} />
      </div>
    </Page>
  );
};

export default Invoices;
