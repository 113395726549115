import {
  Alert,
  Avatar,
  Button,
  Card,
  Form,
  Input,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MessageOutlined,
  FilePdfOutlined,
  CarOutlined,
} from "@ant-design/icons";
import "../../assets/styles/dashboard.css";
import { CardUrl, Page } from "../../components";
import {
  getCollectionAllSearchData,
  getCollectionById,
} from "../../utils/getCollectionData";
import moment from "moment";
import "moment/locale/fr";
import { useUserAuth } from "../../context/UserAuthContext";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { useProSidebar } from "react-pro-sidebar";
import { BiCodeAlt } from "react-icons/bi";
import { TbCodeOff } from "react-icons/tb";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNotification } from "../../utils/util-notification";
import {
  FacebookEmbed,
  InstagramEmbed,
  TikTokEmbed,
  TwitterEmbed,
  YouTubeEmbed,
} from "react-social-media-embed";
import { useTranslation } from "react-i18next";
import pressImg from "../../assets/media/press.png";
import youtubeImg from "../../assets/media/youtube.png";
import facebookImg from "../../assets/media/facebook.svg";
import instagramImg from "../../assets/media/instagram.png";
import snapchatImg from "../../assets/media/snapchat.svg";
import twitterImg from "../../assets/media/twitter.svg";
import tiktokImg from "../../assets/media/tiktok.png";

const OrderView = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const location = useLocation();
  const idOrder = location.pathname.split("/")[5];
  const navigate = useNavigate();
  const { lang, user } = useUserAuth();
  const { notify } = useNotification();
  moment.locale(lang === "fr" ? "fr" : "en");
  const { collapsed, broken } = useProSidebar();
  const [viewCode, setViewCode] = useState(false);
  const [sizeCompleted, setSizeCompleted] = useState(null);
  const [loadingCorrection, setLoadingCorrection] = useState(false);
  const [loadingCompleted, setLoadingCompleted] = useState(false);

  const getTotal = useCallback(async () => {
    const [completed] = await Promise.all([
      getCollectionAllSearchData(
        "completed",
        "idOrder",
        idOrder,
        "userAdv",
        user.uid
      ),
    ]);
    setSizeCompleted(completed.size);
  }, [idOrder, user.uid]);
  useEffect(() => {
    getTotal();
  }, [getTotal]);

  const getOrder = useCallback(async () => {
    const response = await getCollectionById("orders", idOrder);
    form.setFieldsValue({
      correction: response.correction,
    });
    setData(response);
  }, [form, idOrder]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  //columns table
  const columns = [
    {
      title: t("view-advertisers-order-view.text-1"),
      dataIndex: "id",
      ellipsis: true,
      fixed: "left",
      width: 80,
    },
    {
      title: t("view-advertisers-order-view.text-2"),
      dataIndex: "dateCreate",
      width: 100,
      sorter: (a, b) => a.dateCreate.length - b.dateCreate.length,
    },
    {
      title: <div>{t("view-advertisers-order-view.text-3")}</div>,
      dataIndex: "networkType",
      render: (networkType, all) =>
        networkType === "facebook" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={facebookImg} />
          </a>
        ) : networkType === "twitter" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={twitterImg} />
          </a>
        ) : networkType === "youtube" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={youtubeImg} />
          </a>
        ) : networkType === "instagram" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={instagramImg} />
          </a>
        ) : networkType === "tiktok" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={tiktokImg} />
          </a>
        ) : networkType === "snapchat" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={snapchatImg} />
          </a>
        ) : (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={pressImg} />
          </a>
        ),
      width: 100,
    },
    {
      title: t("view-advertisers-order-view.text-4"),
      dataIndex: "deliveryDate",
      render: (deliveryDate, all) => (
        <div>{moment(deliveryDate).format("MMMM-Do-YYYY, h:mm:ss a")}</div>
      ),
      width: 100,
      sorter: (a, b) => a.deliveryDate.length - b.deliveryDate.length,
    },
    {
      title: t("view-advertisers-order-view.text-5"),
      render: (url, all) => (
        <Tooltip placement="right" title={<CardUrl url={url} />}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Tooltip>
      ),
      dataIndex: "published",
      width: 100,
      sorter: (a, b) => a.published.length - b.published.length,
    },
    {
      title: t("view-advertisers-order-view.text-6"),
      dataIndex: "price",
      width: 100,
      render: (price, all) => `€${parseFloat(price).toFixed(2)}`,
      sorter: (a, b) => a.gain.length - b.gain.length,
    },
  ];

  const order = [];

  data &&
    order.push({
      id: data.id,
      campaign: data.campaignName,
      dateCreate: moment(data.dateCreated).format("L"),
      published: data.url,
      price: data.priceOfAdv,
      networkType: data.networkType,
      deliveryDate: data.deliveryDate.toDate(),
    });

  //const style edit code
  const styles = {
    maxWidth: broken
      ? "100%"
      : collapsed
      ? "calc(100% - 80px)"
      : "calc(100% - 250px)",
  };

  const onClickCompleted = async (id) => {
    setLoadingCompleted(true);
    const [treat, todo, orders] = await Promise.all([
      getCollectionAllSearchData("treat", "idOrder", id, "userAdv", user.uid),
      getCollectionAllSearchData("todo", "idOrder", id, "userAdv", user.uid),
      getCollectionById("orders", id),
    ]);
    if (treat.size > 0) {
      treat.data.map(async (item) => {
        const q = doc(db, "orders", id);
        await updateDoc(q, {
          status: "completed",
          dateUpdated: moment().format(),
        });
        await deleteDoc(doc(db, "treat", item.id))
          .then(async () => {
            await addDoc(collection(db, "funds"), {
              userId: user.uid,
              fund: -parseInt(orders.priceOfAdv),
              email: user.email,
              idCampaign: orders.idCampaign,
              idNetwork: orders.idNetwork,
              type: "transfer",
              idOrder: idOrder,
              dateCreated: moment().format(),
              dateUpdated: moment().format(),
            });
            await addDoc(collection(db, "gain"), {
              userId: orders.userPub,
              userIdTrans: orders.userAdv,
              fund: orders.priceOfPub,
              status: "pending",
              type: "gain",
              idCampaign: orders.idCampaign,
              idNetwork: orders.idNetwork,
              idOrder: idOrder,
              dateCreated: moment().format(),
              dateUpdated: moment().format(),
            });
            await addDoc(collection(db, "completed"), {
              idOrder: id,
              idNetwork: orders.idNetwork,
              idCampaign: orders.idCampaign,
              userAdv: orders.userAdv,
              userPub: orders.userPub,
              dateCreated: moment().format(),
              dateUpdated: moment().format(),
            });
            notify(
              "success",
              t("view-advertisers-order-view.notify-1"),
              t("view-advertisers-order-view.notify-2")
            );
            getOrder();
            getTotal();
          })
          .catch((error) => {
            notify(
              "error",
              t("view-advertisers-order-view.notify-3"),
              t("view-advertisers-order-view.notify-4")
            );
          });
      });
      setLoadingCompleted(false);
    } else if (todo) {
      todo.data.map(async (item) => {
        await deleteDoc(doc(db, "todo", item.id))
          .then(async () => {
            await addDoc(collection(db, "funds"), {
              userId: user.uid,
              fund: -parseInt(orders.priceOfAdv),
              email: user.email,
              idCampaign: orders.idCampaign,
              idNetwork: orders.idNetwork,
              type: "transfer",
              idOrder: idOrder,
              dateCreated: moment().format(),
              dateUpdated: moment().format(),
            });
            await addDoc(collection(db, "gain"), {
              userId: orders.userPub,
              userIdTrans: orders.userAdv,
              fund: orders.priceOfPub,
              status: "pending",
              type: "gain",
              idCampaign: orders.idCampaign,
              idNetwork: orders.idNetwork,
              idOrder: idOrder,
              dateCreated: moment().format(),
              dateUpdated: moment().format(),
            });
            await addDoc(collection(db, "completed"), {
              idOrder: id,
              idNetwork: orders.idNetwork,
              idCampaign: orders.idCampaign,
              userAdv: orders.userAdv,
              userPub: orders.userPub,
              dateCreated: moment().format(),
              dateUpdated: moment().format(),
            });
            notify(
              "success",
              t("view-advertisers-order-view.notify-1"),
              t("view-advertisers-order-view.notify-2")
            );
            getOrder();
            getTotal();
          })
          .catch((error) => {
            notify(
              "error",
              t("view-advertisers-order-view.notify-3"),
              t("view-advertisers-order-view.notify-4")
            );
          });
      });
      setLoadingCompleted(false);
    } else {
      notify(
        "error",
        t("view-advertisers-order-view.notify-3"),
        t("view-advertisers-order-view.notify-4")
      );
      setLoadingCompleted(false);
    }
  };

  const onFinishTextCorrection = async (values) => {
    setLoadingCorrection(true);
    const q = doc(db, "orders", idOrder);
    await updateDoc(q, {
      correction: values.correction,
      dateUpdated: moment().format(),
    })
      .then(() => {
        notify(
          "success",
          t("view-advertisers-order-view.notify-1"),
          t("view-advertisers-order-view.notify-5")
        );
      })
      .catch((error) => {
        notify(
          "error",
          t("view-advertisers-order-view.notify-3"),
          t("view-advertisers-order-view.notify-6")
        );
      });
    setLoadingCorrection(false);
  };

  return (
    <Page
      title={`${t("view-advertisers-order-view.title-1")} ${data && data?.url}`}
    >
      {data ? (
        <div className="dashboard" style={{ position: "relative" }}>
          <Card
            title={data && data?.campaignName}
            extra={
              <Button
                type="primary"
                onClick={(e) =>
                  navigate(`/${lang}/advertiser/me/messages/${idOrder}`)
                }
                icon={<MessageOutlined />}
                size="large"
              >
                {t("view-advertisers-order-view.btn-3")}
              </Button>
            }
            style={viewCode ? styles : null}
          >
            <Table
              columns={columns}
              dataSource={order}
              scroll={{
                x: 896,
              }}
            />
          </Card>
          {data && data?.possible ? (
            <Card
              title={t("view-advertisers-order-view.title-2")}
              style={viewCode ? styles : null}
            >
              <Alert
                type="success"
                message={
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignContent: "center",
                    }}
                  >
                    <CarOutlined style={{ fontSize: "2rem" }} />
                    <p>{t("view-advertisers-order-view.text-7")}</p>
                  </div>
                }
              />
            </Card>
          ) : null}

          <Card
            title={t("view-advertisers-order-view.title-3")}
            style={viewCode ? styles : null}
            extra={
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <Tooltip
                  title={
                    viewCode
                      ? t("view-advertisers-order-view.tooltip-1")
                      : t("view-advertisers-order-view.tooltip-2")
                  }
                >
                  <Button
                    icon={viewCode ? <TbCodeOff /> : <BiCodeAlt />}
                    onClick={() => setViewCode(!viewCode)}
                  />
                </Tooltip>
                <Tooltip title={t("view-advertisers-order-view.tooltip-3")}>
                  <Button icon={<FilePdfOutlined />} type="primary" />
                </Tooltip>
              </div>
            }
          >
            {viewCode ? (
              <div style={{ overflowX: "auto" }}>
                <CodeEditor
                  value={data && data.advertorialRequirement}
                  language="html"
                  data-color-mode="dark"
                  padding={15}
                  style={{
                    fontSize: 12,
                    fontFamily:
                      "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    maxHeight: 350,
                    minWidth: styles,
                  }}
                />
              </div>
            ) : (
              <div
                style={{ overflowX: "auto", maxWidth: "calc(100% - 250px)" }}
                dangerouslySetInnerHTML={{
                  __html: data && data.advertorialRequirement,
                }}
              />
            )}
          </Card>
          {data && data?.urlOrder ? (
            <Card title={t("view-advertisers-order-view.text-8")}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {data?.networkType === "facebook" ? (
                  <FacebookEmbed url={data && data?.urlOrder} width={325} />
                ) : data &&
                  data?.urlOrder &&
                  data?.networkType === "instagram" ? (
                  <InstagramEmbed url={data && data?.urlOrder} width={325} />
                ) : data &&
                  data?.urlOrder &&
                  data?.networkType === "twitter" ? (
                  <TwitterEmbed url={data && data?.urlOrder} width={325} />
                ) : data && data?.urlOrder && data?.networkType === "tiktok" ? (
                  <TikTokEmbed url={data && data?.urlOrder} width={325} />
                ) : data &&
                  data?.urlOrder &&
                  data?.networkType === "youtube" ? (
                  <YouTubeEmbed url={data && data?.urlOrder} width={325} />
                ) : (
                  <CardUrl url={data && data?.urlOrder} />
                )}
              </div>
            </Card>
          ) : null}
          {data && data.urlOrder && sizeCompleted <= 0 ? (
            <Card title={t("view-advertisers-order-view.text-9")}>
              <Form
                layout="vertical"
                onFinish={onFinishTextCorrection}
                form={form}
              >
                <Form.Item name="correction">
                  <Input.TextArea
                    placeholder={t("view-advertisers-order-view.placeholder-1")}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    size="large"
                    style={{ width: "100%" }}
                    htmlType="submit"
                    loading={loadingCorrection}
                  >
                    {t("view-advertisers-order-view.btn-1")}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          ) : null}
          {data && data.urlOrder && sizeCompleted <= 0 ? (
            <Button
              size="large"
              type="primary"
              onClick={() => onClickCompleted(idOrder)}
              loading={loadingCompleted}
              style={{ width: "100%", position: "sticky", bottom: 0 }}
            >
              {t("view-advertisers-order-view.btn-2")}
            </Button>
          ) : null}
        </div>
      ) : (
        <Spin tip={t("view-advertisers-order-view.spin")}>
          <Card
            bodyStyle={{ width: "100%", height: "80vh" }}
            title={t("view-advertisers-order-view.text-10")}
          />
        </Spin>
      )}
    </Page>
  );
};

export default OrderView;
