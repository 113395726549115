import { Avatar, Button, Table, Tooltip } from "antd";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { BsCheckCircle } from "react-icons/bs";
import { db } from "../firebase";
import { EyeOutlined, CloseOutlined, MessageOutlined } from "@ant-design/icons";
import { CardUrl } from "../components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import youtubeImg from "../assets/media/youtube.png";
import facebookImg from "../assets/media/facebook.svg";
import instagramImg from "../assets/media/instagram.png";
import snapchatImg from "../assets/media/snapchat.svg";
import twitterImg from "../assets/media/twitter.svg";
import tiktokImg from "../assets/media/tiktok.png";
import pressImg from "../assets/media/press.png";
import {
  getCollectionAllSearchData,
  getCollectionById,
} from "../utils/getCollectionData";
import { useNotification } from "../utils/util-notification";

const AllTablePub = ({ idNetwork, searchText }) => {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(false);
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingDeclined, setLoadingDeclined] = useState(false);
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const { user, lang } = useUserAuth();
  const { notify } = useNotification();
  const navigate = useNavigate();

  //component actions
  const ActionsPub = ({ id }) => {
    const [dataWaiting, setDataWaiting] = useState(null);
    const getTotal = useCallback(async () => {
      const [waiting] = await Promise.all([
        getCollectionAllSearchData(
          "waiting",
          "idOrder",
          id,
          "userPub",
          user.uid
        ),
      ]);
      setDataWaiting(waiting.size);
    }, [id]);
    useEffect(() => {
      getTotal();
    }, [getTotal]);

    return (
      <div style={{ display: "flex", gap: "2px" }}>
        <Tooltip
          placement="bottom"
          title={t("sections-all-table-pub.btn-title-4")}
        >
          <Button
            type="primary"
            ghost
            icon={<EyeOutlined />}
            onClick={(e) => navigate(`/${lang}/publisher/me/order/${id}`)}
          />
        </Tooltip>
        {dataWaiting && dataWaiting > 0 ? (
          <Tooltip
            placement="bottom"
            title={t("sections-all-table-pub.btn-title-1")}
          >
            <Button
              type="primary"
              icon={<BsCheckCircle />}
              onClick={() => onClickAccept(id)}
              loading={loadingAccept}
            />
          </Tooltip>
        ) : null}
        {dataWaiting && dataWaiting > 0 ? (
          <Tooltip
            placement="bottom"
            title={t("sections-all-table-pub.btn-title-2")}
          >
            <Button
              type="primary"
              danger
              icon={<CloseOutlined />}
              onClick={() => onClickDeclined(id)}
              loading={loadingDeclined}
            />
          </Tooltip>
        ) : null}
        <Tooltip
          placement="bottom"
          title={t("sections-all-table-pub.btn-title-3")}
        >
          <Button
            icon={<MessageOutlined />}
            onClick={(e) => navigate(`/${lang}/publisher/me/messages/${id}`)}
          />
        </Tooltip>
      </div>
    );
  };

  //columns table

  const columns = [
    {
      title: t("sections-all-table-pub.title-1"),
      dataIndex: "id",
      ellipsis: true,
      width: 80,
    },
    {
      title: t("sections-all-table-pub.title-2"),
      dataIndex: "campaign",
      width: 100,
      sorter: (a, b) => a.campaign.length - b.campaign.length,
    },
    {
      title: <div>{t("sections-all-table-pub.title-3")}</div>,
      dataIndex: "networkType",
      render: (networkType, all) =>
        networkType === "facebook" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={facebookImg} />
          </a>
        ) : networkType === "twitter" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={twitterImg} />
          </a>
        ) : networkType === "youtube" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={youtubeImg} />
          </a>
        ) : networkType === "instagram" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={instagramImg} />
          </a>
        ) : networkType === "tiktok" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={tiktokImg} />
          </a>
        ) : networkType === "snapchat" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={snapchatImg} />
          </a>
        ) : (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={pressImg} />
          </a>
        ),
      width: 120,
    },
    {
      title: t("sections-all-table-pub.title-4"),
      dataIndex: "order",
      width: 100,
      sorter: (a, b) => a.order.length - b.order.length,
    },
    {
      title: t("sections-all-table-pub.title-5"),
      dataIndex: "dateTodo",
      width: 100,
      sorter: (a, b) => a.dateTodo.length - b.dateTodo.length,
    },
    {
      title: t("sections-all-table-pub.title-6"),
      render: (website, all) => (
        <Tooltip placement="right" title={<CardUrl url={website} />}>
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        </Tooltip>
      ),
      dataIndex: "affectedWeb",
      width: 100,
      sorter: (a, b) => a.affectedWeb.length - b.affectedWeb.length,
    },
    {
      title: t("sections-all-table-pub.title-8"),
      dataIndex: "gain",
      width: 100,
      render: (price, all) => `€${parseFloat(price).toFixed(2)}`,
      sorter: (a, b) => a.gain.length - b.gain.length,
    },
    {
      title: t("sections-all-table-pub.title-9"),
      dataIndex: "actions",
      fixed: "right",
      render: (id, all) => <ActionsPub id={id} />,
      width: 120,
    },
  ];

  //get all orders
  const getAllOrders = useCallback(async () => {
    setLoadingData(true);

    const q1 = query(
      collection(db, "orders"),
      where("userPub", "==", user.uid)
    );
    const q1i = query(
      collection(db, "orders"),
      where("idNetwork", "==", idNetwork),
      where("userPub", "==", user.uid)
    );

    const get1 = await getDocs(idNetwork === "" ? q1 : q1i);

    setData(
      get1.docs.map((order) => ({
        id: order.id,
        campaign: order.data().campaignName,
        networkType: order.data().networkType,
        order: moment(order.data().dateCreated).format("L"),
        affectedWeb: order.data().url,
        gain: order.data().priceOfPub,
        actions: order.id,
        idNetwork: order.data().idNetwork,
        idCampaign: order.data().idCampaign,
      }))
    );

    setLoadingData(false);
  }, [idNetwork, user.uid]);

  useEffect(() => {
    getAllOrders();
  }, [getAllOrders]);

  const handleSearch = useCallback(() => {
    const filteredData =
      data &&
      data.filter(
        (record) =>
          record.campaign.toLowerCase().includes(searchText.toLowerCase()) ||
          record.affectedWeb.toLowerCase().includes(searchText.toLowerCase())
      );
    setFilteredData(filteredData);
  }, [data, searchText]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const onClickAccept = async (id) => {
    setLoadingAccept(true);
    const [waiting, orders] = await Promise.all([
      getCollectionAllSearchData("waiting", "idOrder", id, "userPub", user.uid),
      getCollectionById("orders", id),
    ]);
    if (waiting.size > 0) {
      waiting.data.map(async (item) => {
        const q = doc(db, "orders", id);
        await updateDoc(q, {
          dateAccept: moment().format(),
          dateUpdated: moment().format(),
        })
          .then(async () => {
            await deleteDoc(doc(db, "waiting", item.id))
              .then(async () => {
                await addDoc(collection(db, "progress"), {
                  idOrder: id,
                  idNetwork: orders.idNetwork,
                  idCampaign: orders.idCampaign,
                  userAdv: orders.userAdv,
                  userPub: orders.userPub,
                  dateCreated: moment().format(),
                  dateUpdated: moment().format(),
                });
                notify(
                  "success",
                  t("sections-all-table-pub.notify-1"),
                  t("sections-all-table-pub.notify-2")
                );
                getAllOrders();
              })
              .catch((error) => {
                notify(
                  "error",
                  t("sections-all-table-pub.notify-3"),
                  t("sections-all-table-pub.notify-4")
                );
              });
          })
          .catch((error) => {
            notify(
              "error",
              t("sections-all-table-pub.notify-3"),
              t("sections-all-table-pub.notify-4")
            );
          });
      });
      setLoadingAccept(false);
    } else {
      notify(
        "error",
        t("sections-all-table-pub.notify-3"),
        t("sections-all-table-pub.notify-4")
      );
      setLoadingAccept(false);
    }
  };

  const onClickDeclined = async (id) => {
    setLoadingDeclined(true);
    const [waiting, orders] = await Promise.all([
      getCollectionAllSearchData("waiting", "idOrder", id, "userPub", user.uid),
      getCollectionById("orders", id),
    ]);
    if (waiting.size > 0) {
      waiting.data.map(async (item) => {
        const q = doc(db, "orders", id);
        await updateDoc(q, {
          status: "declined",
          dateAccept: moment().format(),
          dateUpdated: moment().format(),
        })
          .then(async () => {
            await deleteDoc(doc(db, "waiting", item.id))
              .then(async () => {
                await addDoc(collection(db, "declined"), {
                  idOrder: id,
                  idNetwork: orders.idNetwork,
                  idCampaign: orders.idCampaign,
                  userAdv: orders.userAdv,
                  userPub: orders.userPub,
                  dateCreated: moment().format(),
                  dateUpdated: moment().format(),
                });
                notify(
                  "success",
                  t("sections-all-table-pub.notify-1"),
                  t("sections-all-table-pub.notify-5")
                );
                getAllOrders();
              })
              .catch((error) => {
                notify(
                  "error",
                  t("sections-all-table-pub.notify-3"),
                  t("sections-all-table-pub.notify-6")
                );
              });
          })
          .catch((error) => {
            notify(
              "error",
              t("sections-all-table-pub.notify-3"),
              t("sections-all-table-pub.notify-6")
            );
          });
      });
      setLoadingDeclined(false);
    } else {
      notify(
        "error",
        t("sections-all-table-pub.notify-3"),
        t("sections-all-table-pub.notify-6")
      );
      setLoadingDeclined(false);
    }
  };
  return (
    <Table
      columns={columns}
      loading={loadingData}
      scroll={{
        x: 896,
      }}
      dataSource={searchText === "" ? data : filteredData}
    />
  );
};

export default AllTablePub;
