import React, { useState } from "react";
import { Page } from "../../components";
import { NavBarAuth } from "../../global";
import { Button, Checkbox, Form, Input, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useUserAuth } from "../../context/UserAuthContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const { logIn, userAuth, user, lang } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    await logIn(values.email, values.password);
    setLoading(false);
  };
  // const clickGoogleBtn = async () => {
  //   googleSignIn();
  // };
  useEffect(() => {
    if (user && userAuth) {
      navigate(`/${lang}`);
    } else if (user && !userAuth) {
      navigate(`/${lang}/register`);
    } else {
      navigate(`/${lang}/login`);
    }
  }, [lang, navigate, user, userAuth]);

  return (
    <Page title={t("view-auth-login.text-1")}>
      <div className="auth">
        <NavBarAuth />
        <div className="auth-content">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography.Title
              style={{
                fontSize: "42px",
                color: "#000336",
                fontWeight: "bold",
                paddingBottom: "10px",
                borderBottom: "4px solid #2c4e7a",
                marginBottom: "3rem",
              }}
              data-aos="fade-left"
            >
              {t("view-auth-login.text-1")}
            </Typography.Title>
            {/* <Button
              type="default"
              icon={
                <span style={{ marginRight: "0.5rem" }}>
                  <FcGoogle />
                </span>
              }
              size="large"
              onClick={clickGoogleBtn}
              data-aos="fade-right"
            >
              {t("view-auth-login.text-2")}
            </Button>
            <Divider data-aos="zoom-in">{t("view-auth-login.text-3")}</Divider> */}
            <Form
              name="login"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              data-aos="fade-left"
              style={{ maxWidth: 360, minWidth: 300 }}
            >
              <Form.Item
                name="email"
                label={t("view-auth-login.label-1")}
                rules={[
                  {
                    required: true,
                    message: t("view-auth-login.message-1"),
                  },
                  {
                    type: "email",
                    message: t("view-auth-login.message-2"),
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  size="large"
                  placeholder={t("view-auth-login.placeholder-1")}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={t("view-auth-login.label-2")}
                rules={[
                  {
                    required: true,
                    message: t("view-auth-login.message-3"),
                  },
                  {
                    min: 6,
                    message: t("view-auth-login.message-4"),
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={t("view-auth-login.placeholder-2")}
                  size="large"
                />
              </Form.Item>
              <Form.Item style={{ marginTop: "-1.5rem" }}>
                <Link
                  to={`/${lang}/password/reset`}
                  style={{
                    color: "#2C4E7A",
                    textDecoration: "underline",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {t("view-auth-login.text-4")}
                </Link>
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>{t("view-auth-login.text-5")}</Checkbox>
                </Form.Item>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  size="large"
                  loading={loading}
                >
                  {t("view-auth-login.text-6")}
                </Button>
              </Form.Item>
              <Form.Item>
                <div style={{ display: "flex", gap: "2px" }}>
                  <p style={{ textAlign: "start", maxWidth: "250px" }}>
                    {t("view-auth-login.text-7")}
                  </p>
                  <Link
                    to={`/${lang}/register`}
                    style={{
                      color: "#2C4E7A",
                      textDecoration: "underline",
                      textAlign: "justify",
                    }}
                  >
                    {t("view-auth-login.text-8")}
                  </Link>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Login;
