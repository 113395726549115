import { Badge, Card, Input, Select, Tabs, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CardHeaderPub, Page } from "../../components";
import "../../assets/styles/dashboard.css";
import {
  AllTablePub,
  CompletedTablePub,
  DeclinedTablePub,
  ProgressTablePub,
  RevisionTablePub,
  TodoTablePub,
} from "../../sections";
import { useUserAuth } from "../../context/UserAuthContext";
import {
  getCollectionAllData,
  getCollectionAllSearchData,
} from "../../utils/getCollectionData";
import { useTranslation } from "react-i18next";

const Proposals = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const key = location.pathname.split("/")[5];
  const [idNetwork, setIdNetwork] = useState("");
  const [searchText, setSearchText] = useState("");
  const [revision, setRevision] = useState("0");
  const [progress, setProgress] = useState("0");
  const [completed, setCompleted] = useState("0");
  const [refused, setRefused] = useState("0");
  const [todo, setTodo] = useState("0");
  const [all, setAll] = useState("0");
  const { user } = useUserAuth();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCollectionAllData("networks", user.uid, "", "");
      setOptions(response?.data);
    };
    fetchData();
  }, [user.uid]);

  const getMyProposal = useCallback(() => {
    // Call the function to retrieve the data and size
    getCollectionAllSearchData(
      "orders",
      "userPub",
      user.uid,
      "idNetwork",
      idNetwork
    ).then((response) => {
      setAll(response.size);
    });
    getCollectionAllSearchData(
      "completed",
      "userPub",
      user.uid,
      "idNetwork",
      idNetwork
    ).then((response) => {
      setCompleted(response.size);
    });
    getCollectionAllData(
      "declined",
      "userPub",
      user.uid,
      "idNetwork",
      idNetwork
    ).then((response) => {
      setRefused(response.size);
    });
    getCollectionAllData(
      "progress",
      "userPub",
      user.uid,
      "idNetwork",
      idNetwork
    ).then((response) => {
      setProgress(response.size);
    });
    getCollectionAllData(
      "revision",
      "userPub",
      user.uid,
      "idNetwork",
      idNetwork
    ).then((response) => {
      setRevision(response.size);
    });
    getCollectionAllData(
      "todo",
      "userPub",
      user.uid,
      "idNetwork",
      idNetwork
    ).then((response) => {
      setTodo(response.size);
    });
  }, [idNetwork, user.uid]);
  useEffect(() => {
    getMyProposal();
  }, [getMyProposal]);

  const items = [
    {
      key: "1",
      label: (
        <Badge count={all} showZero size="small" color="#2C4E7A">
          {t("view-publisher-proposals.label-1")}
        </Badge>
      ),
      children: <AllTablePub idNetwork={idNetwork} searchText={searchText} />,
    },
    {
      key: "2",
      label: (
        <Badge size="small" count={revision} showZero color="#401717">
          {t("view-publisher-proposals.label-2")}
        </Badge>
      ),
      children: (
        <RevisionTablePub idNetwork={idNetwork} searchText={searchText} />
      ),
    },
    {
      key: "3",
      label: (
        <Badge count={todo} size="small" showZero color="#08f">
          {t("view-publisher-proposals.label-3")}
        </Badge>
      ),
      children: <TodoTablePub idNetwork={idNetwork} searchText={searchText} />,
    },

    {
      key: "4",
      label: (
        <Badge size="small" count={progress} showZero color="#f80">
          {t("view-publisher-proposals.label-4")}
        </Badge>
      ),
      children: (
        <ProgressTablePub idNetwork={idNetwork} searchText={searchText} />
      ),
    },

    {
      key: "5",
      label: (
        <Badge size="small" count={completed} showZero color="#1C8">
          {t("view-publisher-proposals.label-5")}
        </Badge>
      ),
      children: (
        <CompletedTablePub idNetwork={idNetwork} searchText={searchText} />
      ),
    },
    {
      key: "6",
      label: (
        <Badge size="small" count={refused} showZero color="#f00">
          {t("view-publisher-proposals.label-6")}
        </Badge>
      ),
      children: (
        <DeclinedTablePub idNetwork={idNetwork} searchText={searchText} />
      ),
    },
  ];
  return (
    <Page title="Proposals">
      <div className="dashboard">
        <CardHeaderPub title="Proposals" />
        <div className="dashboard-select">
          <Typography.Title level={5}>
            {t("view-publisher-proposals.typography-title-1")}
          </Typography.Title>
          <Select
            style={{
              width: 240,
            }}
            size="large"
            defaultValue=""
            onChange={(e) => setIdNetwork(e)}
            placeholder={t("view-publisher-proposals.placeholder-1")}
          >
            <Select.Option value="">
              {t("view-publisher-proposals.Select-option-text")}
            </Select.Option>
            {options &&
              options?.map((item, key) => (
                <Select.Option value={item.id} key={key}>
                  {item.networkName}
                </Select.Option>
              ))}
          </Select>
        </div>
        <Card>
          <Tabs
            defaultActiveKey={key}
            items={items}
            tabBarExtraContent={
              <Input.Search
                size="large"
                placeholder={t("view-publisher-proposals.placeholder-2")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            }
          />
        </Card>
      </div>
    </Page>
  );
};

export default Proposals;
