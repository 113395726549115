import {
  Button,
  Card,
  Table,
  Typography,
  Modal,
  Form,
  Input,
  Tooltip,
  Select,
  InputNumber,
  Avatar,
  Divider,
  Space,
} from "antd";
import {
  CloseOutlined,
  EditFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Country } from "country-state-city";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FiGlobe } from "react-icons/fi";
import { CardUrl, Page } from "../../components";
import { useUserAuth } from "../../context/UserAuthContext";
import { db } from "../../firebase";
import { useNotification } from "../../utils/util-notification";
import "../../assets/styles/dashboard.css";
import { useTranslation } from "react-i18next";
import pressImg from "../../assets/media/press.png";
import youtubeImg from "../../assets/media/youtube.png";
import facebookImg from "../../assets/media/facebook.svg";
import instagramImg from "../../assets/media/instagram.png";
import snapchatImg from "../../assets/media/snapchat.svg";
import twitterImg from "../../assets/media/twitter.svg";
import tiktokImg from "../../assets/media/tiktok.png";
import axios from "axios";

const { confirm } = Modal;

const Networks = () => {
  const [form] = Form.useForm();
  const { user } = useUserAuth();
  const { notify } = useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [idNetwork, setIdNetwork] = useState(null);
  const [networkType, setNetworkType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const { t } = useTranslation();

  //data network types
  const options = [
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={pressImg} />
          <div>Blog/Press</div>
        </div>
      ),
      value: "press",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={youtubeImg} />
          <div>YouTube</div>
        </div>
      ),
      value: "youtube",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={facebookImg} />
          <div>Facebook</div>
        </div>
      ),
      value: "facebook",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={instagramImg} />
          <div>Instagram</div>
        </div>
      ),
      value: "instagram",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={twitterImg} />
          <div>Twitter</div>
        </div>
      ),
      value: "twitter",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={snapchatImg} />
          <div>Snapchat</div>
        </div>
      ),
      value: "snapchat",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={tiktokImg} />
          <div>TikTok</div>
        </div>
      ),
      value: "tiktok",
    },
  ];

  //get flag network

  const getFlag = (country) => {
    const val = Country.getAllCountries().find((item) => item.name === country);
    return val.flag;
  };

  //columns
  const columns = [
    {
      title: t("view-publisher-networks.title-1"),
      dataIndex: "id",
      ellipsis: true,
      width: 80,
    },
    {
      title: t("view-publisher-networks.title-2"),
      dataIndex: "date",
      width: 120,
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: t("view-publisher-networks.title-3"),
      dataIndex: "networkName",
      render: (networkName, all) => (
        <Space size="small">
          <div>{getFlag(all.country)}</div>
          <div>{networkName}</div>
        </Space>
      ),
      width: 120,
      sorter: (a, b) => a.networkName.length - b.networkName.length,
    },
    {
      title: t("view-publisher-networks.title-4"),
      dataIndex: "url",
      render: (url, all) => (
        <Tooltip placement="right" title={<CardUrl url={url} />}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Tooltip>
      ),
      width: 120,
      sorter: (a, b) => a.url.length - b.url.length,
    },
    {
      title: t("view-publisher-networks.title-5"),
      dataIndex: "gain",
      render: (gain, all) => `€${parseFloat(gain).toFixed(2)}`,
      width: 120,
      sorter: (a, b) => a.gain.length - b.gain.length,
    },
    {
      title: <div>{t("view-publisher-networks.title-6")}</div>,
      dataIndex: "networkType",
      render: (networkType, all) =>
        networkType === "facebook" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={facebookImg} />
          </a>
        ) : networkType === "twitter" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={twitterImg} />
          </a>
        ) : networkType === "youtube" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={youtubeImg} />
          </a>
        ) : networkType === "instagram" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={instagramImg} />
          </a>
        ) : networkType === "tiktok" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={tiktokImg} />
          </a>
        ) : networkType === "snapchat" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={snapchatImg} />
          </a>
        ) : (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={pressImg} />
          </a>
        ),
      width: 120,
    },
    {
      title: <div>{t("view-publisher-networks.title-7")}</div>,
      dataIndex: "actions",
      fixed: "right",
      render: (id, all) => (
        <div style={{ display: "flex", gap: "2px" }}>
          {/* <Tooltip
            placement="bottom"
            title={t("view-publisher-networks.Tooltip-title-3")}
          >
            <Button type="primary" ghost icon={<EyeOutlined />} />
          </Tooltip> */}
          <Tooltip
            placement="bottom"
            title={t("view-publisher-networks.Tooltip-title-2")}
          >
            <Button
              type="primary"
              onClick={() => onClickEditNetwork(id)}
              icon={<EditFilled />}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={t("view-publisher-networks.Tooltip-title-1")}
          >
            <Button
              type="primary"
              danger
              icon={<CloseOutlined />}
              onClick={() => onClickDeleteNetwork(id)}
            />
          </Tooltip>
        </div>
      ),
      width: 120,
    },
  ];

  //get all network

  const getAllNetworks = useCallback(async () => {
    setLoadingData(true);
    try {
      const q = query(
        collection(db, "networks"),
        where("userId", "==", user.uid)
      );

      const get = await getDocs(q);

      setData(
        get.docs.map((doc) => ({
          id: doc.id,
          date: moment(doc.data().dateCreated).format("L"),
          networkName: doc.data().networkName,
          url: doc.data().url,
          gain: doc.data().gain,
          country: doc.data().country,
          networkType: doc.data().networkType,
          actions: doc.id,
        }))
      );
      setLoadingData(false);
    } catch (error) {
      notify(
        "error",
        t("view-publisher-networks.notify-1-1-1"),
        t("view-publisher-networks.notify-1-1-2")
      );
      setLoadingData(false);
    }
  }, [notify, t, user.uid]);

  //delete data
  const onClickDeleteNetwork = (id) => {
    confirm({
      title: "Delete network",
      icon: <ExclamationCircleFilled />,
      content: t("view-publisher-networks.content-1"),
      okText: t("view-publisher-networks.okText-1"),
      okType: "danger",
      cancelText: t("view-publisher-networks.cancelText-1"),
      async onOk() {
        await deleteDoc(doc(db, "networks", id))
          .then(async () => {
            notify(
              "success",
              t("view-publisher-networks.notify-1-2"),
              t("view-publisher-networks.notify-1-3")
            );
            setLoading(false);
            getAllNetworks();
          })
          .catch((error) => {
            notify(
              "error",
              t("view-publisher-networks.notify-1-5"),
              t("view-publisher-networks.notify-1-6")
            );
            setLoading(false);
          });
      },
    });
  };

  useEffect(() => {
    getAllNetworks();
  }, [getAllNetworks]);

  // onClickEditNetwork
  const onClickEditNetwork = async (id) => {
    const q = doc(db, "networks", id);
    const get = await getDoc(q);
    if (get.exists()) {
      setIdNetwork(id);
      setNetworkType(get.data().networkType);
      form.setFieldsValue({
        country: get.data().country,
        description: get.data().description,
        networkName: get.data().networkName,
        networkType: get.data().networkType,
        url: get.data().url,
        gain: get.data().gain,
        visits: get.data().visits,
      });
    } else {
      // doc.data() will be undefined in this case
      notify(
        "info",
        t("view-publisher-networks.notify-1-8"),
        t("view-publisher-networks.notify-1-9")
      );
    }
    setIsModalOpen(true);
  };

  // show modal for network
  const showModal = () => {
    setIdNetwork(null);
    setIsModalOpen(true);
  };

  // onnfinish for network
  const onFinish = async (values) => {
    setLoading(true);
    if (idNetwork) {
      const q = doc(db, "networks", idNetwork);
      await updateDoc(q, {
        country: values.country,
        description: values.description === undefined ? "" : values.description,
        networkName: values.networkName,
        networkType: values.networkType,
        url: values.url,
        visits: values.visits ? values.visits : 0,
        gain: values.gain,
        dateUpdated: moment().format(),
      })
        .then(async () => {
          notify(
            "success",
            t("view-publisher-networks.notify-1-11"),
            t("view-publisher-networks.notify-1-12")
          );
          setLoading(false);
          getAllNetworks();
          setNetworkType("");
        })
        .catch((error) => {
          notify(
            "error",
            t("view-publisher-networks.notify-1-14"),
            t("view-publisher-networks.notify-1-15")
          );
          setLoading(false);
        });
    } else {
      const get = await getDocs(
        query(collection(db, "networks"), where("url", "==", values.url))
      );
      if (get.size > 0) {
        notify(
          "error",
          t("view-publisher-networks.notify-1-10"),
          t("view-publisher-networks.notify-1-10-2")
        );
        setLoading(false);
      } else {
        if (values.networkType === "press") {
          const data = {
            targets: [values.url],
          };
          await axios
            .post("https://precious-puce-cheetah.cyclic.app/url_metrics", data)
            .then(async (response) => {
              await addDoc(collection(db, "networks"), {
                userId: user.uid,
                country: values.country,
                description:
                  values.description === undefined ? "" : values.description,
                networkName: values.networkName,
                networkType: values.networkType,
                url: values.url,
                gain: values.gain,
                authority: response.data.results[0].domain_authority,
                backlinks: response.data.results[0].root_domains_to_root_domain,
                spam: response.data.results[0].spam_score,
                dateCreated: moment().format(),
                dateUpdated: moment().format(),
              })
                .then(async () => {
                  notify(
                    "success",
                    t("view-publisher-networks.notify-1-20"),
                    t("view-publisher-networks.notify-1-21")
                  );
                  setLoading(false);
                  form.resetFields();
                  getAllNetworks();
                  setNetworkType("");
                })
                .catch((error) => {
                  notify(
                    "error",
                    t("view-publisher-networks.notify-1-23"),
                    t("view-publisher-networks.notify-1-24")
                  );
                  setLoading(false);
                });
            })
            .catch((error) => {
              notify(
                "error",
                t("view-publisher-networks.notify-1-23"),
                t("view-publisher-networks.notify-1-24")
              );
              setLoading(false);
            });
        } else if (values.networkType === "youtube") {
          await axios
            .get(
              `https://precious-puce-cheetah.cyclic.app/youtube?url=${values.url}`
            )
            .then(async (response) => {
              await addDoc(collection(db, "networks"), {
                userId: user.uid,
                country: values.country,
                description:
                  values.description === undefined ? "" : values.description,
                networkName: values.networkName,
                networkType: values.networkType,
                url: values.url,
                gain: values.gain,
                visits: response.data.statistics.subscriberCount,
                dateCreated: moment().format(),
                dateUpdated: moment().format(),
              })
                .then(async () => {
                  notify(
                    "success",
                    t("view-publisher-networks.notify-1-20"),
                    t("view-publisher-networks.notify-1-21")
                  );
                  setLoading(false);
                  form.resetFields();
                  getAllNetworks();
                  setNetworkType("");
                })
                .catch((error) => {
                  notify(
                    "error",
                    t("view-publisher-networks.notify-1-23"),
                    t("view-publisher-networks.notify-1-24")
                  );
                  setLoading(false);
                });
            })
            .catch((error) => {
              notify(
                "error",
                t("view-publisher-networks.notify-1-23"),
                t("view-publisher-networks.notify-1-24")
              );
              setLoading(false);
            });
        } else {
          await addDoc(collection(db, "networks"), {
            userId: user.uid,
            country: values.country,
            description:
              values.description === undefined ? "" : values.description,
            networkName: values.networkName,
            networkType: values.networkType,
            url: values.url,
            visits: values.visits,
            gain: values.gain,
            dateCreated: moment().format(),
            dateUpdated: moment().format(),
          })
            .then(async () => {
              notify(
                "success",
                t("view-publisher-networks.notify-1-20"),
                t("view-publisher-networks.notify-1-21")
              );
              setLoading(false);
              form.resetFields();
              getAllNetworks();
              setNetworkType("");
            })
            .catch((error) => {
              notify(
                "error",
                t("view-publisher-networks.notify-1-23"),
                t("view-publisher-networks.notify-1-24")
              );
              setLoading(false);
            });
        }
      }
    }
  };

  //serach
  const handleSearch = (value) => {
    setSearchText(value);

    const filteredData = data.filter(
      (record) =>
        record.networkName.toLowerCase().includes(value.toLowerCase()) ||
        record.url.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };
  // handleCancel for network
  const handleCancel = () => {
    form.resetFields();
    setIdNetwork(null);
    setNetworkType("");
    setIsModalOpen(false);
    setLoading(false);
  };

  return (
    <Page title={t("view-publisher-networks.title-8")}>
      <div className="dashboard">
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Title level={4}>
              {t("view-publisher-networks.typography-title-1")}
            </Typography.Title>
            <Button
              type="primary"
              ghost
              icon={<FiGlobe style={{ marginRight: "8px" }} />}
              size="large"
              onClick={showModal}
            >
              {t("view-publisher-networks.btn-1")}
            </Button>
          </div>
        </Card>
        <Card
          extra={
            <Input.Search
              style={{ width: "250px" }}
              size="large"
              placeholder={t("view-publisher-networks.search-placeholder")}
              allowClear
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          }
        >
          <Table
            scroll={{
              x: 800,
            }}
            loading={loadingData}
            columns={columns}
            dataSource={searchText === "" ? data : filteredData}
          />
        </Card>
      </div>

      {/* Modal add */}

      <Modal
        title={
          idNetwork
            ? t("view-publisher-networks.title-9")
            : t("view-publisher-networks.title-10")
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Divider />
          <Form.Item
            label={t("view-publisher-networks.label-6")}
            name="networkType"
            rules={[
              {
                required: true,
                message: t("view-publisher-networks.message-6"),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t("view-publisher-networks.placeholder-6")}
              size="large"
              value={networkType}
              onChange={(e) => setNetworkType(e)}
            >
              {options.map((value, key) => (
                <Select.Option value={value.value} key={key}>
                  {value.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {networkType !== "" && (
            <div>
              <Form.Item
                name="networkName"
                label={
                  networkType === "press"
                    ? t("view-publisher-networks.label-1-1")
                    : networkType === "youtube"
                    ? t("view-publisher-networks.label-1-2")
                    : networkType === "facebook"
                    ? t("view-publisher-networks.label-1-3")
                    : networkType === "instagram"
                    ? t("view-publisher-networks.label-1-4")
                    : networkType === "twitter"
                    ? t("view-publisher-networks.label-1-5")
                    : networkType === "snapchat"
                    ? t("view-publisher-networks.label-1-6")
                    : t("view-publisher-networks.label-1-7")
                }
                rules={[
                  {
                    required: true,
                    message:
                      networkType === "press"
                        ? t("view-publisher-networks.message-1-1")
                        : networkType === "youtube"
                        ? t("view-publisher-networks.message-1-2")
                        : networkType === "facebook"
                        ? t("view-publisher-networks.message-1-3")
                        : networkType === "instagram"
                        ? t("view-publisher-networks.message-1-4")
                        : networkType === "twitter"
                        ? t("view-publisher-networks.message-1-5")
                        : networkType === "snapchat"
                        ? t("view-publisher-networks.message-1-6")
                        : t("view-publisher-networks.message-1-7"),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={
                    networkType === "press"
                      ? t("view-publisher-networks.placeholder-1-1")
                      : networkType === "youtube"
                      ? t("view-publisher-networks.placeholder-1-2")
                      : networkType === "facebook"
                      ? t("view-publisher-networks.placeholder-1-3")
                      : networkType === "instagram"
                      ? t("view-publisher-networks.placeholder-1-4")
                      : networkType === "twitter"
                      ? t("view-publisher-networks.placeholder-1-5")
                      : networkType === "snapchat"
                      ? t("view-publisher-networks.placeholder-1-6")
                      : t("view-publisher-networks.placeholder-1-7")
                  }
                />
              </Form.Item>
              <Form.Item
                name="url"
                label={
                  networkType === "press"
                    ? t("view-publisher-networks.label-2-1")
                    : networkType === "youtube"
                    ? t("view-publisher-networks.label-2-2")
                    : networkType === "facebook"
                    ? t("view-publisher-networks.label-2-3")
                    : networkType === "instagram"
                    ? t("view-publisher-networks.label-2-4")
                    : networkType === "twitter"
                    ? t("view-publisher-networks.label-2-5")
                    : networkType === "snapchat"
                    ? t("view-publisher-networks.label-2-6")
                    : t("view-publisher-networks.label-2-7")
                }
                rules={[
                  {
                    required: true,
                    message:
                      networkType === "press"
                        ? t("view-publisher-networks.message-2-1")
                        : networkType === "youtube"
                        ? t("view-publisher-networks.message-2-2")
                        : networkType === "facebook"
                        ? t("view-publisher-networks.message-2-3")
                        : networkType === "instagram"
                        ? t("view-publisher-networks.message-2-4")
                        : networkType === "twitter"
                        ? t("view-publisher-networks.message-2-5")
                        : networkType === "snapchat"
                        ? t("view-publisher-networks.message-2-6")
                        : t("view-publisher-networks.message-2-7"),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={
                    networkType === "press"
                      ? t("view-publisher-networks.placeholder-2-1")
                      : networkType === "youtube"
                      ? t("view-publisher-networks.placeholder-2-2")
                      : networkType === "facebook"
                      ? t("view-publisher-networks.placeholder-2-3")
                      : networkType === "instagram"
                      ? t("view-publisher-networks.placeholder-2-4")
                      : networkType === "twitter"
                      ? t("view-publisher-networks.placeholder-2-5")
                      : networkType === "snapchat"
                      ? t("view-publisher-networks.placeholder-2-6")
                      : t("view-publisher-networks.placeholder-2-7")
                  }
                />
              </Form.Item>
              <Form.Item
                name="country"
                label={t("view-publisher-networks.label-2")}
                rules={[
                  {
                    required: true,
                    message: t("view-publisher-networks.message-2"),
                  },
                ]}
              >
                <Select
                  placeholder={t("view-publisher-networks.placeholder-2")}
                  showSearch
                  size="large"
                >
                  {Country.getAllCountries() &&
                    Country.getAllCountries().map((item, key) => (
                      <Select.Option value={item.name} key={key}>
                        {item.flag} {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              {networkType !== "press" && networkType !== "youtube" && (
                <Form.Item
                  name="visits"
                  label={
                    networkType === "press"
                      ? t("view-publisher-networks.label-3-1")
                      : t("view-publisher-networks.label-3-2")
                  }
                  rules={[
                    {
                      required: true,
                      message:
                        networkType === "press"
                          ? t("view-publisher-networks.message-3-1")
                          : t("view-publisher-networks.message-3-2"),
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    min={1}
                    style={{ width: "100%" }}
                    placeholder="Example: 457786"
                  />
                </Form.Item>
              )}
              <Form.Item
                name="gain"
                label={t("view-publisher-networks.label-5")}
                rules={[
                  {
                    required: true,
                    message: t("view-publisher-networks.message-5-1"),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  min={5}
                  decimalSeparator=","
                  step="0.01"
                  addonBefore="€"
                  placeholder="Min: 5,00"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label={t("view-publisher-networks.label-4")}
              >
                <Input.TextArea
                  size="large"
                  placeholder={t("view-publisher-networks.placeholder-4-1")}
                />
              </Form.Item>

              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "1rem",
                  }}
                >
                  <Button size="large" type="default" onClick={handleCancel}>
                    {t("view-publisher-networks.btn-4")}
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    {t("view-publisher-networks.btn-6")}
                  </Button>
                </div>
              </Form.Item>
            </div>
          )}
        </Form>
      </Modal>
    </Page>
  );
};

export default Networks;
