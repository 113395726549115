import { Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GetStargetImg from "../assets/media/get-starget.svg";
import "../assets/styles/get-starget.css";
import { useUserAuth } from "../context/UserAuthContext";
import { getCollectionAllData } from "../utils/getCollectionData";

const GetStarted = () => {
  const { t } = useTranslation();
  const { lang } = useUserAuth();
  const navigate = useNavigate();
  const [totalPub, setTotalPub] = useState(0);
  const getTotal = async () => {
    const pub = await getCollectionAllData("networks", "", "", "");

    setTotalPub(pub.size);
  };
  useEffect(() => {
    getTotal();
  }, []);
  return (
    <div id="#get-starget" className="get-starget">
      <div>
        <div className="get-starget-left" data-aos="fade-left">
          <Typography.Title style={{ color: "white" }}>
            {t("sections-GetStarget.text-1-1")} {totalPub}{" "}
            {t("sections-GetStarget.text-1-2")}
          </Typography.Title>
          <Typography.Paragraph style={{ color: "#ccc" }}>
            {t("sections-GetStarget.text-2")}
          </Typography.Paragraph>
          <Button
            size="large"
            type="default"
            style={{ width: "fit-content" }}
            onClick={() => navigate(`/${lang}/register`)}
          >
            {t("sections-GetStarget.btn-1")}
          </Button>
        </div>
        <div className="get-starget-right" data-aos="fade-right">
          <img
            src={GetStargetImg}
            alt="GetstargetImg"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
