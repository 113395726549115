import { Alert, Card, Select, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  FcDatabase,
  FcClock,
  FcSynchronize,
  FcApproval,
  FcCancel,
} from "react-icons/fc";
import { FaEdit } from "react-icons/fa";

import { CardHeaderPub, Loader, Page } from "../../components";
import "../../assets/styles/dashboard.css";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import { getCollectionAllData } from "../../utils/getCollectionData";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { userAuth, user, lang } = useUserAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [revision, setRevision] = useState("0");
  const [progress, setProgress] = useState("0");
  const [completed, setCompleted] = useState("0");
  const [refused, setRefused] = useState("0");
  const [todo, setTodo] = useState("0");
  const [all, setAll] = useState("0");
  const [networkName, setNetworkName] = useState("No Name");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCollectionAllData("networks", user.uid, "", "");
      setOptions(response?.data);
    };
    fetchData();
  }, [user.uid]);

  const getCountOrders = async (selectedNetworkId) => {
    if (selectedNetworkId !== "") {
      setLoading(true);

      const q = doc(db, "networks", selectedNetworkId);
      const get = await getDoc(q);
      if (get.exists()) {
        const { networkName } = get.data();
        const [completed, declined, progress, revision, todo, orders] =
          await Promise.all([
            getCollectionAllData(
              "completed",
              "",
              "idNetwork",
              selectedNetworkId
            ),
            getCollectionAllData(
              "declined",
              "",
              "idNetwork",
              selectedNetworkId
            ),
            getCollectionAllData(
              "progress",
              "",
              "idNetwork",
              selectedNetworkId
            ),
            getCollectionAllData(
              "revision",
              "",
              "idNetwork",
              selectedNetworkId
            ),
            getCollectionAllData("todo", "", "idNetwork", selectedNetworkId),
            getCollectionAllData("orders", "", "idNetwork", selectedNetworkId),
          ]);

        setCompleted(`${completed?.size}`);
        setRefused(`${declined?.size}`);
        setProgress(`${progress?.size}`);
        setRevision(`${revision?.size}`);
        setTodo(`${todo?.size}`);
        setAll(`${orders?.size}`);
        setNetworkName(networkName);
        setLoading(false);
      }
    } else {
      getAllCount();
    }
  };

  const getAllCount = useCallback(async () => {
    setLoading(true);
    const [completed, declined, progress, revision, todo, orders] =
      await Promise.all([
        getCollectionAllData("completed", "", "userPub", user.uid),
        getCollectionAllData("declined", "", "userPub", user.uid),
        getCollectionAllData("progress", "", "userPub", user.uid),
        getCollectionAllData("revision", "", "userPub", user.uid),
        getCollectionAllData("todo", "", "userPub", user.uid),
        getCollectionAllData("orders", "", "userPub", user.uid),
      ]);
    setCompleted(`${completed?.size}`);
    setRefused(`${declined?.size}`);
    setProgress(`${progress?.size}`);
    setRevision(`${revision?.size}`);
    setTodo(`${todo?.size}`);
    setAll(`${orders?.size}`);
    setNetworkName(t("view-publisher-dashboard.text-1"));
    setLoading(false);
  }, [t, user.uid]);
  useEffect(() => {
    getAllCount();
  }, [getAllCount]);

  return (
    <Page title={t("view-publisher-dashboard.title-1")}>
      <div className="dashboard">
        <Alert
          message={`${t("view-publisher-dashboard.message-1")}  ${
            userAuth?.firstName
          }  ${userAuth?.lastName}`}
          description={t("view-publisher-dashboard.description-1")}
          type="success"
          closable
        />

        <CardHeaderPub title={t("view-publisher-dashboard.title-2")} />
        <div className="dashboard-select">
          <Typography.Title level={5}>
            {t("view-publisher-dashboard.typography-title")}
          </Typography.Title>
          <Select
            style={{
              width: 240,
            }}
            defaultValue=""
            onChange={(value) => getCountOrders(value)}
            placeholder="Select network name"
            size="large"
          >
            <Select.Option value="">
              {t("view-publisher-dashboard.text-1")}
            </Select.Option>
            {options.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.networkName}
              </Select.Option>
            ))}
          </Select>
        </div>
        {loading ? (
          <Loader>
            <div className="dashboard-static">
              <Card
                hoverable
                onClick={() => navigate(`/${lang}/publisher/me/proposals/1`)}
                actions={[networkName]}
              >
                <Card.Meta
                  avatar={<FcDatabase size="50px" />}
                  title={t("view-publisher-dashboard.title-3")}
                  description={all}
                />
              </Card>
              <Card
                hoverable
                onClick={() => navigate(`/${lang}/publisher/me/proposals/2`)}
                actions={[networkName]}
              >
                <Card.Meta
                  avatar={<FcSynchronize size="40px" />}
                  title={t("view-publisher-dashboard.title-4")}
                  description={revision}
                />
              </Card>
              <Card
                hoverable
                onClick={() => navigate(`/${lang}/publisher/me/proposals/3`)}
                actions={[networkName]}
              >
                <Card.Meta
                  avatar={<FaEdit color="#00a" size="40px" />}
                  title={t("view-publisher-dashboard.title-5")}
                  description={todo}
                />
              </Card>
              <Card
                hoverable
                onClick={() => navigate(`/${lang}/publisher/me/proposals/4`)}
                actions={[networkName]}
              >
                <Card.Meta
                  avatar={<FcClock size="50px" />}
                  title={t("view-publisher-dashboard.title-6")}
                  description={progress}
                />
              </Card>
              <Card
                hoverable
                onClick={() => navigate(`/${lang}/publisher/me/proposals/5`)}
                actions={[networkName]}
              >
                <Card.Meta
                  avatar={<FcApproval size="50px" />}
                  title={t("view-publisher-dashboard.title-7")}
                  description={completed}
                />
              </Card>
              <Card
                hoverable
                onClick={() => navigate(`/${lang}/publisher/me/proposals/6`)}
                actions={[networkName]}
              >
                <Card.Meta
                  avatar={<FcCancel size="50px" />}
                  title={t("view-publisher-dashboard.title-8")}
                  description={refused}
                />
              </Card>
            </div>
          </Loader>
        ) : (
          <div className="dashboard-static">
            <Card
              hoverable
              onClick={() => navigate(`/${lang}/publisher/me/proposals/1`)}
              actions={[networkName]}
            >
              <Card.Meta
                avatar={<FcDatabase size="50px" />}
                title={t("view-publisher-dashboard.title-3")}
                description={all}
              />
            </Card>
            <Card
              hoverable
              onClick={() => navigate(`/${lang}/publisher/me/proposals/2`)}
              actions={[networkName]}
            >
              <Card.Meta
                avatar={<FcSynchronize size="40px" />}
                title={t("view-publisher-dashboard.title-4")}
                description={revision}
              />
            </Card>
            <Card
              hoverable
              onClick={() => navigate(`/${lang}/publisher/me/proposals/3`)}
              actions={[networkName]}
            >
              <Card.Meta
                avatar={<FaEdit color="#00a" size="40px" />}
                title={t("view-publisher-dashboard.title-5")}
                description={todo}
              />
            </Card>
            <Card
              hoverable
              onClick={() => navigate(`/${lang}/publisher/me/proposals/4`)}
              actions={[networkName]}
            >
              <Card.Meta
                avatar={<FcClock size="50px" />}
                title={t("view-publisher-dashboard.title-6")}
                description={progress}
              />
            </Card>
            <Card
              hoverable
              onClick={() => navigate(`/${lang}/publisher/me/proposals/5`)}
              actions={[networkName]}
            >
              <Card.Meta
                avatar={<FcApproval size="50px" />}
                title={t("view-publisher-dashboard.title-7")}
                description={completed}
              />
            </Card>
            <Card
              hoverable
              onClick={() => navigate(`/${lang}/publisher/me/proposals/6`)}
              actions={[networkName]}
            >
              <Card.Meta
                avatar={<FcCancel size="50px" />}
                title={t("view-publisher-dashboard.title-8")}
                description={refused}
              />
            </Card>
          </div>
        )}
      </div>
    </Page>
  );
};

export default Dashboard;
