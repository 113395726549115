import React from "react";
import { CardBlog } from "../components";
import AboutImg01 from "../assets/media/about-01.jpeg";
import AboutImg02 from "../assets/media/about-02.jpeg";
import AboutImg03 from "../assets/media/about-03.jpeg";
import "../assets/styles/blogs.css";
import { Card, Image, Typography } from "antd";
import { useTranslation } from "react-i18next";

const Blogs = () => {
  const { t } = useTranslation();
  return (
    <div id="#blogs" className="blogs">
      <div className="blogs-left">
        <Card cover={<Image src={AboutImg03} />}>
          <Typography.Title level={5}>
            {t("sections-blogs.typography-title-1")}
          </Typography.Title>
          <Typography.Paragraph style={{ textAlign: "justify" }}>
            {t("sections-blogs.text-1-1")}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ textAlign: "justify" }}>
            {t("sections-blogs.text-1-2")}
          </Typography.Paragraph>
          <Typography.Title level={5}>
            {t("sections-blogs.typography-title-2")}
          </Typography.Title>
          <Typography.Paragraph style={{ textAlign: "justify" }}>
            {t("sections-blogs.text-2-1")}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ textAlign: "justify" }}>
            {t("sections-blogs.text-2-2")}
          </Typography.Paragraph>
        </Card>
      </div>
      <div className="blogs-right">
        <CardBlog
          img={AboutImg02}
          title={t("sections-blogs.title-1")}
          paragraph={t("sections-blogs.paragraph")}
          li_1={t("sections-blogs.li-1-1")}
          li_2={t("sections-blogs.li-1-2")}
          li_3={t("sections-blogs.li-1-3")}
        />
        <CardBlog
          img={AboutImg01}
          title={t("sections-blogs.title-2")}
          li_1={t("sections-blogs.li-2-1")}
          li_2={t("sections-blogs.li-2-2")}
          li_3={t("sections-blogs.li-2-3")}
        />
      </div>
    </div>
  );
};

export default Blogs;
