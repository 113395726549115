import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  sendPasswordResetEmail,
  deleteUser,
  sendEmailVerification,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { auth, db } from "../firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  query,
  updateDoc,
  where,
  getDocs,
} from "firebase/firestore";
import { useNotification } from "../utils/util-notification";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { join } from "array-join";
import { getCollectionAllData } from "../utils/getCollectionData";
import moment from "moment";

export const UserContext = createContext({});

export const UserAuthContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [userAuth, setUserAuth] = useState(null);
  const lang = Cookies.get("i18next") || "en"; // fallback to "en" if cookie is not set
  const { t } = useTranslation();
  const { notify } = useNotification();
  const [cart, setCart] = useState([]);
  const [summary, setSummary] = useState([]);
  const [stepsCart, setStepsCart] = useState(0);
  const [steps, setSteps] = useState(0);

  const [totalAdv, setTotalAdv] = useState(null);
  const [totalPub, setTotalPub] = useState(null);

  const getTotal = async () => {
    const [adv_agency, adv_brand, pub] = await Promise.all([
      getCollectionAllData("users", "", "accountType", "adv-agency"),
      getCollectionAllData("users", "", "accountType", "adv-brand"),
      getCollectionAllData("networks", "", "", ""),
    ]);

    setTotalAdv(adv_agency.size + adv_brand.size);
    setTotalPub(pub.size);
  };
  useEffect(() => {
    getTotal();
  }, []);

  const getUserLogin = () => {
    setLoading(true);
    const unsubscribeUser = onAuthStateChanged(auth, async (res) => {
      if (res) {
        const users = await getDocs(
          query(collection(db, "users"), where("userId", "==", res.uid))
        );
        setUser(res);
        users.forEach((doc) => {
          setUserAuth({ ...doc.data(), id: doc.id });
        });
        setLoading(false);
      } else {
        setUser(null);
        setUserAuth(null);
        setLoading(false);
      }
    });
    return () => unsubscribeUser();
  };

  useEffect(() => {
    getUserLogin();
  }, []);

  //login
  const logIn = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        getUserLogin();
        notify(
          "success",
          t("context-user.notify-1-1"),
          t("context-user.notify-1-2")
        );
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/user-not-found":
            // Handle user not found error
            notify(
              "error",
              t("context-user.notify-1-3"),
              t("context-user.notify-1-4")
            );
            break;
          case "auth/invalid-email":
            // Handle invalid email error
            notify(
              "error",
              t("context-user.notify-1-5"),
              t("context-user.notify-1-6")
            );
            break;
          case "auth/wrong-password":
            // Handle weak password error
            notify(
              "error",
              t("context-user.notify-1-7"),
              t("context-user.notify-1-8")
            );
            break;
          case "auth/user-disabled":
            // Handle user disabled error
            notify(
              "error",
              t("context-user.notify-1-9"),
              t("context-user.notify-1-10")
            );
            break;
          default:
            // Handle other errors
            notify(
              "error",
              t("context-user.notify-1-11"),
              t("context-user.notify-1-12")
            );
        }
      });
  };
  //sign Up
  const signUp = async (
    email,
    password,
    type,
    firstName,
    lastName,
    phoneNumber
  ) => {
    if (user === null) {
      await createUserWithEmailAndPassword(auth, email, password)
        .then(async (u) => {
          await addDoc(collection(db, "users"), {
            userId: u.user.uid,
            firstName: firstName,
            lastName: lastName,
            accountType: type,
            phoneNumber: phoneNumber,
            email: email,
          })
            .then(async () => {
              getUserLogin();
              await sendEmailVerification(u.user);
              notify(
                "success",
                t("context-user.notify-1-13"),
                t("context-user.notify-1-14")
              );
            })
            .catch((error) => {
              notify(
                "error",
                t("context-user.notify-1-15"),
                t("context-user.notify-1-16")
              );
            });
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              notify(
                "error",
                t("context-user.notify-1-17"),
                t("context-user.notify-1-18")
              );
              break;
            case "auth/invalid-email":
              notify(
                "error",
                t("context-user.notify-1-19"),
                t("context-user.notify-1-20")
              );
              break;
            case "auth/weak-password":
              notify(
                "error",
                t("context-user.notify-1-21"),
                t("context-user.notify-1-22")
              );
              break;
            default:
              notify(
                "error",
                t("context-user.notify-1-23"),
                t("context-user.notify-1-24")
              );
          }
        });
    } else {
      await addDoc(collection(db, "users"), {
        userId: user?.uid,
        firstName: firstName,
        lastName: lastName,
        accountType: type,
        phoneNumber: phoneNumber,
      })
        .then(async () => {
          getUserLogin();
          await sendEmailVerification(user);
          notify(
            "success",
            t("context-user.notify-1-13"),
            t("context-user.notify-1-14")
          );
        })
        .catch((error) => {
          notify(
            "error",
            t("context-user.notify-1-15"),
            t("context-user.notify-1-16")
          );
        });
    }
  };

  //logout
  const logOut = async () => {
    await signOut(auth)
      .then(() => {
        notify(
          "success",
          t("context-user.notify-2-25"),
          t("context-user.notify-2-26")
        );
      })
      .catch((error) => {
        notify(
          "error",
          t("context-user.notify-1-27"),
          t("context-user.notify-1-28")
        );
      });
  };

  //login with google
  const googleSignIn = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    await signInWithPopup(auth, googleAuthProvider)
      .then(() => {
        getUserLogin();
        notify(
          "success",
          t("context-user.notify-1-29"),
          t("context-user.notify-1-30")
        );
      })
      .catch((error) => {
        // Handle login with google error
        switch (error.code) {
          case "auth/invalid-email":
            // Handle invalid email error
            notify(
              "error",
              t("context-user.notify-1-31"),
              t("context-user.notify-1-32")
            );

            break;
          case "auth/wrong-password":
            // Handle weak password error
            notify(
              "error",
              t("context-user.notify-1-33"),
              t("context-user.notify-1-34")
            );

            break;
          case "auth/user-disabled":
            // Handle user disabled error
            notify(
              "error",
              t("context-user.notify-1-35"),
              t("context-user.notify-1-36")
            );

            break;
          case "auth/popup-closed-by-user":
            break;
          default:
            // Handle other errors
            notify(
              "error",
              t("context-user.notify-1-37"),
              t("context-user.notify-1-38")
            );
        }
      });
  };

  //recaptha
  const setUpRecaptha = async (number) => {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    await signInWithPhoneNumber(auth, number, recaptchaVerifier);
  };

  // forgot password
  const forgotPassword = async (email) => {
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        notify(
          "success",
          t("context-user.notify-1-39"),
          t("context-user.notify-1-40")
        );
      })
      .catch((error) => {
        // Handle login with google error
        switch (error.code) {
          case "auth/invalid-email":
            // Handle invalid email error
            notify(
              "error",
              t("context-user.notify-1-41"),
              t("context-user.notify-1-42")
            );
            break;
          case "auth/user-not-found":
            // Handle user not found error
            notify(
              "error",
              t("context-user.notify-1-43"),
              t("context-user.notify-1-44")
            );
            break;
          case "auth/user-disabled":
            // Handle user disabled error
            notify(
              "error",
              t("context-user.notify-1-45"),
              t("context-user.notify-1-46")
            );
            break;
          default:
            // Handle other errors
            notify(
              "error",
              t("context-user.notify-1-47"),
              t("context-user.notify-1-48")
            );
        }
      });
  };

  //update email
  const updateEmailUser = async (email, id) => {
    const u = auth.currentUser;
    const q = doc(db, "users", id);

    await updateEmail(u, email)
      .then(async () => {
        const userUpd = auth.currentUser;
        await sendEmailVerification(userUpd);
        await updateDoc(q, {
          email: email,
          dateUpdated: moment().format(),
        })
          .then(() => {
            notify(
              "success",
              t("context-user.notify-1-49"),
              t("context-user.notify-1-50")
            );
          })
          .catch((error) => {
            notify(
              "error",
              t("context-user.notify-1-51"),
              t("context-user.notify-1-52")
            );
          });
      })
      .catch((error) => {
        notify(
          "error",
          t("context-user.notify-1-51"),
          t("context-user.notify-1-52")
        );
      });
  };
  //Update user
  const updateUser = async (id, firstName, lastName, phoneNumber) => {
    const userDoc = doc(db, "users", id);
    const incereaseage = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
    };
    await updateDoc(userDoc, incereaseage)
      .then(async () => {
        notify(
          "success",
          t("context-user.notify-1-53"),
          t("context-user.notify-1-54")
        );
      })
      .catch((error) => {
        notify(
          "error",
          t("context-user.notify-1-55"),
          t("context-user.notify-1-56")
        );
      });
  };
  // update password
  const updatePwd = async (newPassword) => {
    const u = auth.currentUser;
    await updatePassword(u, newPassword)
      .then(() => {
        notify(
          "success",
          t("context-user.notify-1-57"),
          t("context-user.notify-1-58")
        );
      })
      .catch((error) => {
        notify(
          "error",
          t("context-user.notify-1-59"),
          t("context-user.notify-1-60")
        );
      });
  };
  //delete user
  const deleteAccount = async (id) => {
    const u = auth.currentUser;
    const userDoc = doc(db, "users", id);
    await deleteDoc(userDoc)
      .then(async () => {
        await deleteUser(u)
          .then(() => {
            notify(
              "success",
              t("context-user.notify-1-61"),
              t("context-user.notify-1-62")
            );
          })
          .catch((error) => {
            notify(
              "error",
              t("context-user.notify-1-63"),
              t("context-user.notify-1-64")
            );
          });
      })
      .catch((error) => {
        notify(
          "error",
          t("context-user.notify-1-65"),
          t("context-user.notify-1-66")
        );
      });
  };

  //get cart
  const getCart = () => {
    const cartData = JSON.parse(localStorage.getItem("__cart"));
    setCart(cartData || []);
  };

  //add cart
  const onValidate = async (rows) => {
    const arrayNetwork = [];
    const networks = await getDocs(query(collection(db, "networks")));
    networks.forEach((doc) => {
      arrayNetwork.push({ id: doc.id, pub: doc.data().userId });
    });
    const arraySelect = [];
    rows.rows.map((row) =>
      arraySelect.push({
        idNetwork: row.original.id,
        country: row.original.country,
        networkName: row.original.networkName,
        networkType: row.original.networkType,
        price: row.original.price,
        url: row.original.url,
        visits: row.original.visits,
      })
    );
    localStorage.setItem(
      "__cart",
      JSON.stringify(
        join(
          arrayNetwork,
          arraySelect,
          (left) => left.id,
          (right) => right.idNetwork,
          (left, right) => ({ ...left, ...right })
        )
      )
    );
    getCart();
    notify(
      "success",
      t("context-user.notify-1-67"),
      t("context-user.notify-1-68")
    );
    setStepsCart(1);
  };

  const removeOneCart = (id) => {
    const encryptCartData = localStorage.getItem("__cart");
    const cartData = JSON.parse(encryptCartData);
    // replace with the actual ID you want to remove
    const indexToRemove = cartData.findIndex((element) => element.id === id);
    cartData.splice(indexToRemove, 1);
    localStorage.setItem("__cart", JSON.stringify(cartData));
    getCart();
    notify(
      "success",
      t("context-user.notify-1-69"),
      t("context-user.notify-1-70")
    );
  };

  useEffect(() => {
    getCart();
  }, []);

  //on steps cart
  const onIncreasingSteps = (val) => {
    setStepsCart(val);
  };

  const onDecreasingSteps = (val) => {
    setStepsCart(val);
  };

  useEffect(() => {
    setSteps(stepsCart);
  }, [stepsCart]);

  //cart summary
  const onFinishSummary = async (values) => {
    localStorage.setItem(
      "__summary",
      JSON.stringify({ id: values.idCampaign })
    );
    getSummary();
    notify(
      "success",
      t("context-user.notify-1-71"),
      t("context-user.notify-1-72")
    );
    setStepsCart(2);
  };

  const getSummary = () => {
    setSummary(
      localStorage.getItem("__summary")
        ? JSON.parse(localStorage.getItem("__summary"))
        : []
    );
  };

  useEffect(() => {
    getSummary();
  }, []);

  //remove localStorage

  const removeStorageAll = () => {
    localStorage.removeItem("__cart");
    localStorage.removeItem("__summary");
    getCart();
    getSummary();
  };

  const contextValue = {
    logIn,
    user,
    userAuth,
    updateEmailUser,
    updatePwd,
    updateUser,
    deleteAccount,
    signUp,
    logOut,
    googleSignIn,
    setUpRecaptha,
    forgotPassword,
    loading,
    lang,
    onValidate,
    removeOneCart,
    cart,
    steps,
    onIncreasingSteps,
    onDecreasingSteps,
    onFinishSummary,
    summary,
    removeStorageAll,
    totalAdv,
    totalPub,
  };
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUserAuth = () => {
  return useContext(UserContext);
};
