import { Divider, Layout } from "antd";
import {
  FacebookFilled,
  TwitterSquareFilled,
  InstagramFilled,
  YoutubeFilled,
} from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/footer.css";
import { useTranslation } from "react-i18next";
import { useUserAuth } from "../context/UserAuthContext";

const Footer = () => {
  const { t } = useTranslation();
  const { lang } = useUserAuth();
  return (
    <Layout.Footer className="footer" data-aos="fade-up">
      <div className="footer-content">
        <div className="footer-left">
          <Link to={`/${lang}/legal-notice`}>{t("global-footer.link-1")}</Link>
          <Link to={`/${lang}/terms-conditions`}>
            {t("global-footer.link-2")}
          </Link>
          <Link to={`/${lang}/terms-advertiser`}>
            {t("global-footer.link-3")}
          </Link>
        </div>
        <div className="footer-right">
          <a
            href="https://www.youtube.com/@rocketfluence"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeFilled style={{ color: "#2c4e7a", fontSize: "1.9rem" }} />
          </a>
          <a
            href="https://www.facebook.com/rocketfluence"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookFilled style={{ color: "#2c4e7a", fontSize: "1.5rem" }} />
          </a>
          <a
            href="https://www.instagram.com/rocketfluence"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramFilled style={{ color: "#2c4e7a", fontSize: "1.5rem" }} />
          </a>
          <a
            href="https://twitter.com/rocketfluence "
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterSquareFilled
              style={{ color: "#2c4e7a", fontSize: "1.5rem" }}
            />
          </a>
          {/* <a
            href="https://www.facebook.com/rocketfluence"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinFilled style={{ color: "#2c4e7a", fontSize: "1.5rem" }} />
          </a> */}
        </div>
      </div>
      <Divider />
      <a
        href="https://www.ferberenterprises.com"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textAlign: "center",
          textDecoration: "underline",
          color: "#2c4e7a",
        }}
      >
        ©{new Date().getFullYear()} Ferber Enterprises
      </a>
    </Layout.Footer>
  );
};

export default Footer;
