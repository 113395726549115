import React from "react";
import { useProSidebar } from "react-pro-sidebar";
import "../assets/styles/sidebar-header.css";
import Logo from "../assets/media/logo.png";
import LogoCollapsed from "../assets/media/logo-collapsed.png";

const SidebarHeader = () => {
  const { collapsed } = useProSidebar();
  return (
    <div className='sidebar-header'>
      <div>
        {collapsed ? (
          <img className='logo-collapsed' src={LogoCollapsed} alt='Logo' />
        ) : (
          <img src={Logo} alt='Logo' width='100%' />
        )}
      </div>
    </div>
  );
};
export default SidebarHeader;
