import { Card, Image, Typography } from "antd";
import React from "react";

const CardBlog = ({ img, title, paragraph, li_1, li_2, li_3, ...props }) => {
  return (
    <Card {...props} cover={<Image src={img} />} hoverable size="small">
      <Card.Meta
        title={title}
        description={
          <div>
            <Typography.Paragraph>{paragraph}</Typography.Paragraph>
            <ul>
              <li>{li_1}</li>
              <li>{li_2}</li>
              <li>{li_3}</li>
            </ul>
          </div>
        }
      />
    </Card>
  );
};

export default CardBlog;
