import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useUserAuth } from "../context/UserAuthContext";
import { Form, Button } from "antd";
import { useNotification } from "../utils/util-notification";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StripeForm = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { lang, user } = useUserAuth();
  const { notify } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onFinish = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/${lang}/advertiser/me/completion`,
      },
      redirect: "if_required",
    });

    if (error) {
      notify("error", "Error", error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      await addDoc(collection(db, "funds"), {
        userId: user.uid,
        fund: amount,
        email: user.email,
        type: "deposit",
        dateCreated: moment().format(),
        dateUpdated: moment().format(),
      })
        .then(async () => {
          notify(
            "success",
            t("sections-stripeForm.notify-1"),
            t("sections-stripeForm.notify-2")
          );
          navigate(`/${lang}/advertiser/me/completion`);
        })
        .catch((error) => {
          notify(
            "error",
            t("sections-stripeForm.notify-3"),
            t("sections-stripeForm.notify-4")
          );
        });
    } else if (paymentIntent && paymentIntent.status === "processing") {
      notify(
        "info",
        t("sections-stripeForm.notify-5"),
        t("sections-stripeForm.notify-6")
      );
    } else if (
      paymentIntent &&
      paymentIntent.status === "requires_payment_method"
    ) {
      notify(
        "error",
        t("sections-stripeForm.notify-7"),
        t("sections-stripeForm.notify-8")
      );
    } else {
      notify(
        "error",
        t("sections-stripeForm.notify-3"),
        t("sections-stripeForm.notify-9")
      );
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <Form onFinish={onFinish}>
      <Form.Item>
        <PaymentElement options={paymentElementOptions} />
      </Form.Item>
      {stripe || elements ? (
        <Button
          disabled={isLoading || !stripe || !elements}
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
          style={{ width: "100%" }}
        >
          {t("sections-stripeForm.btn")}
        </Button>
      ) : null}
    </Form>
  );
};

export default StripeForm;
