import { Button, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import PublisherImg from "../assets/media/type_editeur.png";
import AdvertisersImg from "../assets/media/type_annonceur.png";
import "../assets/styles/use-app.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserAuth } from "../context/UserAuthContext";

const UseApp = () => {
  const { t } = useTranslation();
  const { lang } = useUserAuth();
  const navigate = useNavigate();
  return (
    <div id="#use-app" className="use-app">
      <div className="divider-content"></div>
      <Typography.Title data-aos="fade-up">
        {t("sections-useApp.title-1")}
      </Typography.Title>
      <Typography.Text
        style={{ maxWidth: "30rem", textAlign: "center" }}
        data-aos="fade-up"
      >
        {t("sections-useApp.text-1")}
      </Typography.Text>
      <div className="use-app-grid">
        <div data-aos="fade-left">
          <img src={PublisherImg} alt="PublisherImg" />
          <Typography.Title level={4}>
            {t("sections-useApp.title-2")}
          </Typography.Title>
          <Typography.Link
            href="https://www.youtube.com/@rocketfluence"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("sections-useApp.text-2")} <ArrowRightOutlined />
          </Typography.Link>
        </div>
        <div data-aos="fade-right">
          <img src={AdvertisersImg} alt="AdvertisersImg" />
          <Typography.Title level={4}>
            {t("sections-useApp.title-3")}
          </Typography.Title>
          <Typography.Link
            href="https://www.youtube.com/@rocketfluence"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("sections-useApp.text-3")} <ArrowRightOutlined />
          </Typography.Link>
        </div>
      </div>
      <Button
        size="large"
        type="primary"
        onClick={() => navigate(`/${lang}/register`)}
        data-aos="fade-up"
      >
        {t("sections-useApp.text-4")}
      </Button>
    </div>
  );
};

export default UseApp;
