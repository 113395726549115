import React from "react";
import { Page } from "../components";
import "../assets/styles/terms-conditions.css";
import { NavBarAuth } from "../global";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

const TermsConditions = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("view-terms-conditions.title")}>
      <div className="terms-conditions">
        <NavBarAuth />
        <div className="terms-conditions-container">
          <Typography.Title style={{ textAlign: "center" }}>
            {t("view-terms-conditions.title-1")}
          </Typography.Title>
          <div className="terms-conditions-content">
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-2")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-1")}{" "}
                  <a href={t("view-terms-conditions.link-1")}>
                    {t("view-terms-conditions.domaine-1")}
                  </a>
                  {t("view-terms-conditions.paragraph-2")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-3")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-3")}
              </Typography.Title>
              <Typography.Paragraph style={{ textAlign: "justify" }}>
                {t("view-terms-conditions.paragraph-4")}
              </Typography.Paragraph>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-4")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-5")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-6")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-5")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-7")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-8")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-9")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-10")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-11")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-6")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-12")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-13")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-14")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-15")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-7")}
              </Typography.Title>
              <div>
                <Typography.Title level={5} style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-16")}
                </Typography.Title>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-17")}
                </Typography.Paragraph>
                <Typography.Title level={5} style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-18")}
                </Typography.Title>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-19")}
                </Typography.Paragraph>
                <Typography.Title level={5} style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-20")}
                </Typography.Title>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-21")}
                </Typography.Paragraph>
                <Typography.Title level={5}>
                  {t("view-terms-conditions.paragraph-22")}
                </Typography.Title>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-23")}
                </Typography.Paragraph>
                <Typography.Title level={5} style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-24")}
                </Typography.Title>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-25")}
                </Typography.Paragraph>
                <Typography.Title level={5} style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-26")}
                </Typography.Title>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-27")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-28")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-29")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-30")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-8")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-31")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-32")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-9")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-33")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-34")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-10")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-35")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-36")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-37")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-11")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-38")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-39")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-40")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-41")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-12")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-42")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-43")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-44")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-45")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-13")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-46")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-47")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-48")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-49")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-50")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-14")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-51")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-52")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-53")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-54")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-55")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-56")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-15")}
              </Typography.Title>

              <Typography.Paragraph style={{ textAlign: "justify" }}>
                {t("view-terms-conditions.paragraph-57")}
              </Typography.Paragraph>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-16")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-58")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-59")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-17")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-60")}{" "}
                  <a href="mailto:support@rocketfluence.com">
                    support@rocketfluence.com
                  </a>{" "}
                  {t("view-terms-conditions.paragraph-61")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-62")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-63")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-64")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-18")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-65")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-66")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-67")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-terms-conditions.paragraph-68")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-19")}
              </Typography.Title>

              <Typography.Paragraph style={{ textAlign: "justify" }}>
                {t("view-terms-conditions.paragraph-69")}
              </Typography.Paragraph>
            </div>
            <div className="terms-conditions-item">
              <Typography.Title level={4}>
                {t("view-terms-conditions.title-20")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  <b>{t("view-terms-conditions.paragraph-70")} </b>
                  {t("view-terms-conditions.paragraph-71")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  <b>{t("view-terms-conditions.paragraph-72")} </b>
                  {t("view-terms-conditions.paragraph-73")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  <b>{t("view-terms-conditions.paragraph-74")} </b>
                  {t("view-terms-conditions.paragraph-75")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  <b>Rocketfluence: </b>
                  {t("view-terms-conditions.paragraph-76")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  <b>{t("view-terms-conditions.paragraph-77")} </b>
                  {t("view-terms-conditions.paragraph-78")}
                </Typography.Paragraph>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default TermsConditions;
