import React, { useCallback, useEffect } from "react";
import { Page } from "../../components";
import "../../assets/styles/dashboard.css";
import {
  Button,
  Card,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Select,
  Table,
} from "antd";
import { useState } from "react";
import { useUserAuth } from "../../context/UserAuthContext";
import { getCollectionAllData } from "../../utils/getCollectionData";

const Discounts = () => {
  const [open, setOpen] = useState(false);
  const { user } = useUserAuth();
  const [networkData, setNetworkData] = useState(null);
  const [data, setData] = useState(null);

  const getNetworks = useCallback(async () => {
    const arrayData = await getCollectionAllData("networks", user.uid, "", "");
    setNetworkData(arrayData.data);
    setData(arrayData.data.find((network) => network.discount));
  }, [user.uid]);
  useEffect(() => {
    getNetworks();
  }, [getNetworks]);
  console.log(data);

  return (
    <Page title="My Discounts">
      <div className="dashboard">
        <Card
          title="My Discounts"
          extra={
            <Button type="primary" size="large" onClick={() => setOpen(true)}>
              Add discount
            </Button>
          }
        >
          <Table />
        </Card>
        <Modal
          open={open}
          onCancel={() => setOpen(false)}
          footer={null}
          title="Add discount"
        >
          <Form layout="vertical">
            <Form.Item />
            <Form.Item>
              {networkData && (
                <Select placeholder="Select network" size="large">
                  {networkData.map((network, key) => (
                    <Select.Option key={key} value={network.id}>
                      {network.networkName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item>
              <InputNumber
                size="large"
                placeholder="Your discount"
                style={{ width: "100%" }}
                min={0}
                decimalSeparator=","
                step="0.01"
                addonBefore="€"
              />
            </Form.Item>
            <Form.Item>
              <DatePicker.RangePicker size="large" />
            </Form.Item>
            <Form.Item>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button size="large" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" size="large">
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Page>
  );
};

export default Discounts;
