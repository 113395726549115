import React, { useEffect, useState } from "react";
import { HiUser, HiOutlineMail } from "react-icons/hi";
import { BsCartPlus, BsFillCreditCardFill } from "react-icons/bs";
import { FaFileInvoice } from "react-icons/fa";
import { RiLogoutCircleFill } from "react-icons/ri";
import { CiViewList } from "react-icons/ci";
import {
  AiOutlineDashboard,
  AiOutlineUser,
  AiOutlineUnorderedList,
  AiOutlineHeart,
} from "react-icons/ai";
import { Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { Badge, Tooltip } from "antd";
import { useUserAuth } from "../context/UserAuthContext";
import { useTranslation } from "react-i18next";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";

const SidebarPub = () => {
  const { collapsed } = useProSidebar();
  const { logOut, lang, user, cart } = useUserAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const [dataMessage, setDataMessage] = useState([]); // Initialize state to an empty array

  useEffect(() => {
    // Define Firestore queries to retrieve data
    if (user) {
      const q = query(
        collection(db, "messages"),
        where("userAdv", "==", user.uid),
        where("statusAdv", "==", true)
      );

      // Listen for changes in the 'networks' collection and update dataNetworks array
      const unsubscribeMessage = onSnapshot(q, (querySnapshot) => {
        setDataMessage(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      });

      // Return cleanup function to unsubscribe from Firestore listeners
      return () => {
        unsubscribeMessage();
      };
    }
  }, [user]);

  return (
    <Menu
      menuItemStyles={{
        button: ({ level, active }) => {
          // only apply styles on first level elements of the tree
          if (level === 0)
            return {
              backgroundColor: active ? "#f5f5f5" : undefined,
            };
          if (level === 1)
            return {
              backgroundColor: active ? "#f5f5f5" : undefined,
            };
        },
      }}
    >
      {collapsed ? (
        <Tooltip
          placement="right"
          title={t("components-side-bar-adv.menu-item-1")}
        >
          <MenuItem
            icon={<AiOutlineDashboard />}
            component={<Link to={`/${lang}/advertiser/me/dashboard`} />}
            active={
              location.pathname === `/${lang}/advertiser/me/dashboard` && true
            }
          >
            {t("components-side-bar-adv.menu-item-1")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<AiOutlineDashboard />}
          component={<Link to={`/${lang}/advertiser/me/dashboard`} />}
          active={
            location.pathname === `/${lang}/advertiser/me/dashboard`
              ? true
              : false
          }
        >
          {t("components-side-bar-adv.menu-item-2")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip placement="right" title={t("components-side-bar-adv.label-1")}>
          <SubMenu
            label={t("components-side-bar-adv.label-1")}
            icon={<AiOutlineUser />}
            active={
              location.pathname === `/${lang}/advertiser/me/login` ||
              location.pathname === `/${lang}/advertiser/me/money` ||
              location.pathname === `/${lang}/advertiser/me/invoices`
                ? true
                : false
            }
          >
            <MenuItem
              icon={<HiUser />}
              component={<Link to={`/${lang}/advertiser/me/login`} />}
              active={
                location.pathname === `/${lang}/advertiser/me/login`
                  ? true
                  : false
              }
            >
              {t("components-side-bar-adv.menu-item-3")}
            </MenuItem>
            <MenuItem
              icon={<BsFillCreditCardFill />}
              component={<Link to={`/${lang}/advertiser/me/money`} />}
              active={
                location.pathname === `/${lang}/advertiser/me/money`
                  ? true
                  : false
              }
            >
              {t("components-side-bar-adv.menu-item-4")}
            </MenuItem>
            <MenuItem
              icon={<FaFileInvoice />}
              component={<Link to={`/${lang}/advertiser/me/invoices`} />}
              active={
                location.pathname === `/${lang}/advertiser/me/invoices`
                  ? true
                  : false
              }
            >
              {t("components-side-bar-adv.menu-item-5")}
            </MenuItem>
            <MenuItem onClick={logOut} icon={<RiLogoutCircleFill />}>
              {t("components-side-bar-adv.menu-item-6")}
            </MenuItem>
          </SubMenu>
        </Tooltip>
      ) : (
        <SubMenu
          label={t("components-side-bar-adv.label-1")}
          icon={<AiOutlineUser />}
          active={
            location.pathname === `/${lang}/advertiser/me/login` ||
            location.pathname === `/${lang}/advertiser/me/money` ||
            location.pathname === `/${lang}/advertiser/me/invoices`
              ? true
              : false
          }
        >
          <MenuItem
            icon={<HiUser />}
            component={<Link to={`/${lang}/advertiser/me/login`} />}
            active={
              location.pathname === `/${lang}/advertiser/me/login`
                ? true
                : false
            }
          >
            {t("components-side-bar-adv.menu-item-3")}
          </MenuItem>
          <MenuItem
            icon={<BsFillCreditCardFill />}
            component={<Link to={`/${lang}/advertiser/me/money`} />}
            active={
              location.pathname === `/${lang}/advertiser/me/money`
                ? true
                : false
            }
          >
            {t("components-side-bar-adv.menu-item-4")}
          </MenuItem>
          <MenuItem
            icon={<FaFileInvoice />}
            component={<Link to={`/${lang}/advertiser/me/invoices`} />}
            active={
              location.pathname === `/${lang}/advertiser/me/invoices`
                ? true
                : false
            }
          >
            {t("components-side-bar-adv.menu-item-5")}
          </MenuItem>
          <MenuItem onClick={logOut} icon={<RiLogoutCircleFill />}>
            {t("components-side-bar-adv.menu-item-6")}
          </MenuItem>
        </SubMenu>
      )}

      {collapsed ? (
        <Tooltip placement="right" title={t("components-side-bar-adv.title-3")}>
          <MenuItem
            icon={<AiOutlineUnorderedList />}
            component={<Link to={`/${lang}/advertiser/me/campaigns`} />}
            active={
              location.pathname === `/${lang}/advertiser/me/campaigns`
                ? true
                : false
            }
          >
            {t("components-side-bar-adv.menu-item-11")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<AiOutlineUnorderedList />}
          component={<Link to={`/${lang}/advertiser/me/campaigns`} />}
          active={
            location.pathname === `/${lang}/advertiser/me/campaigns`
              ? true
              : false
          }
        >
          {t("components-side-bar-adv.menu-item-12")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip
          placement="right"
          title={
            <div>
              <span>{t("components-side-bar-adv.menu-item-13")}</span>
              <Badge count={cart.length} />
            </div>
          }
        >
          <MenuItem
            icon={<BsCartPlus />}
            component={<Link to={`/${lang}/advertiser/me/new/order`} />}
            active={
              location.pathname === `/${lang}/advertiser/me/new/order`
                ? true
                : false
            }
          >
            {t("components-side-bar-adv.menu-item-13")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<BsCartPlus />}
          component={<Link to={`/${lang}/advertiser/me/new/order`} />}
          active={
            location.pathname === `/${lang}/advertiser/me/new/order`
              ? true
              : false
          }
          suffix={<Badge count={cart.length} />}
        >
          {t("components-side-bar-adv.menu-item-14")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip
          placement="right"
          title={t("components-side-bar-adv.menu-item-15")}
        >
          <MenuItem
            icon={<CiViewList />}
            component={<Link to={`/${lang}/advertiser/me/missions/1`} />}
            active={
              location.pathname === `/${lang}/advertiser/me/missions/1` ||
              location.pathname === `/${lang}/advertiser/me/missions/2` ||
              location.pathname === `/${lang}/advertiser/me/missions/3` ||
              location.pathname === `/${lang}/advertiser/me/missions/4` ||
              location.pathname === `/${lang}/advertiser/me/missions/5` ||
              location.pathname === `/${lang}/advertiser/me/missions/6` ||
              location.pathname === `/${lang}/advertiser/me/missions/7`
                ? true
                : false
            }
          >
            {t("components-side-bar-adv.menu-item-15")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<CiViewList />}
          component={<Link to={`/${lang}/advertiser/me/missions/1`} />}
          active={
            location.pathname === `/${lang}/advertiser/me/missions/1` ||
            location.pathname === `/${lang}/advertiser/me/missions/2` ||
            location.pathname === `/${lang}/advertiser/me/missions/3` ||
            location.pathname === `/${lang}/advertiser/me/missions/4` ||
            location.pathname === `/${lang}/advertiser/me/missions/5` ||
            location.pathname === `/${lang}/advertiser/me/missions/6` ||
            location.pathname === `/${lang}/advertiser/me/missions/7`
              ? true
              : false
          }
        >
          {t("components-side-bar-adv.menu-item-16")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip
          placement="right"
          title={
            <div>
              <span>{t("components-side-bar-adv.menu-item-17")}</span>
              <Badge count={dataMessage.length} />
            </div>
          }
        >
          <MenuItem
            icon={<HiOutlineMail />}
            component={<Link to={`/${lang}/advertiser/me/messages`} />}
            active={
              location.pathname === `/${lang}/advertiser/me/messages`
                ? true
                : false
            }
            suffix={<Badge count={dataMessage.length} />}
          >
            {t("components-side-bar-adv.menu-item-17")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<HiOutlineMail />}
          component={<Link to={`/${lang}/advertiser/me/messages`} />}
          active={
            location.pathname === `/${lang}/advertiser/me/messages`
              ? true
              : false
          }
          suffix={<Badge count={dataMessage.length} />}
        >
          {t("components-side-bar-adv.menu-item-18")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip
          placement="right"
          title={t("components-side-bar-adv.menu-item-19")}
        >
          <MenuItem
            icon={<AiOutlineHeart />}
            component={<Link to={`/${lang}/advertiser/me/favorites`} />}
            active={
              location.pathname === `/${lang}/advertiser/me/favorites`
                ? true
                : false
            }
          >
            {t("components-side-bar-adv.menu-item-19")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<AiOutlineHeart />}
          component={<Link to={`/${lang}/advertiser/me/favorites`} />}
          active={
            location.pathname === `/${lang}/advertiser/me/favorites`
              ? true
              : false
          }
        >
          {t("components-side-bar-adv.menu-item-20")}
        </MenuItem>
      )}
    </Menu>
  );
};

export default SidebarPub;
