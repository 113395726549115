import React from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../components";
import { Blogs, GetStarted, Hero, UseApp, Social, Count } from "../sections";

const Home = () => {
  const { t } = useTranslation();

  return (
    <Page
      title={t("view-home.title-1")}
      metaContent={t("view-home.metaContent")}
    >
      <Hero />
      {/* <Partner /> */}
      <Count />
      <Social />
      <UseApp />
      <GetStarted />
      <Blogs />
    </Page>
  );
};

export default Home;
