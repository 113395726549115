import { Button, Card, Modal, Tabs, Typography } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import React from "react";
import { CgClose } from "react-icons/cg";
import { Page } from "../../components";
import { useUserAuth } from "../../context/UserAuthContext";
import "../../assets/styles/my-account.css";
import { MyEmail, MyInformation, Settings, UpdatePwd } from "../../sections";
import { useTranslation } from "react-i18next";
const { confirm } = Modal;

const MyLogin = () => {
  const { t } = useTranslation();
  const { userAuth, deleteAccount } = useUserAuth();
  const showDeleteConfirm = () => {
    confirm({
      title: t("view-account-MyLogin.title-1"),
      icon: <ExclamationCircleFilled />,
      content: t("view-account-MyLogin.content-1"),
      okText: t("view-account-MyLogin.okText-1"),
      okType: "danger",
      cancelText: t("view-account-MyLogin.cancelText-1"),
      onOk() {
        deleteAccount(userAuth?.id);
      },
    });
  };
  const items = [
    {
      key: "1",
      label: t("view-account-MyLogin.label-1"),
      children: <MyEmail />,
    },
    {
      key: "2",
      label: t("view-account-MyLogin.label-2"),
      children: <MyInformation />,
    },
    {
      key: "3",
      label: t("view-account-MyLogin.label-3"),
      children: <UpdatePwd />,
    },
    {
      key: "4",
      label: t("view-account-MyLogin.label-4"),
      children: <Settings />,
    },
  ];
  return (
    <Page
      title={`${t("view-account-MyLogin.title-2")}(${
        userAuth?.firstName + " " + userAuth?.lastName
      })`}
    >
      <div className="my-account">
        <Card>
          <Typography.Title level={4}>
            {t("view-account-MyLogin.title-3")}
          </Typography.Title>
        </Card>
        <Card title={t("view-account-MyLogin.title-4")}>
          <Tabs defaultActiveKey="1" items={items} />
        </Card>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            danger
            onClick={showDeleteConfirm}
            icon={<CgClose style={{ marginRight: "8px" }} />}
            size="large"
          >
            {t("view-account-MyLogin.text-1")}
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default MyLogin;
