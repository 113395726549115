import {
  Alert,
  Avatar,
  Button,
  Card,
  Form,
  Input,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MessageOutlined,
  FilePdfOutlined,
  CarOutlined,
} from "@ant-design/icons";
import "../../assets/styles/dashboard.css";
import { CardUrl, Page } from "../../components";
import {
  getCollectionAllSearchData,
  getCollectionById,
} from "../../utils/getCollectionData";
import moment from "moment";
import "moment/locale/fr";
import { useUserAuth } from "../../context/UserAuthContext";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { useProSidebar } from "react-pro-sidebar";
import { BiCodeAlt } from "react-icons/bi";
import { TbCodeOff } from "react-icons/tb";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useNotification } from "../../utils/util-notification";
import {
  FacebookEmbed,
  InstagramEmbed,
  TikTokEmbed,
  TwitterEmbed,
  YouTubeEmbed,
} from "react-social-media-embed";
import { useTranslation } from "react-i18next";
import pressImg from "../../assets/media/press.png";
import youtubeImg from "../../assets/media/youtube.png";
import facebookImg from "../../assets/media/facebook.svg";
import instagramImg from "../../assets/media/instagram.png";
import snapchatImg from "../../assets/media/snapchat.svg";
import twitterImg from "../../assets/media/twitter.svg";
import tiktokImg from "../../assets/media/tiktok.png";

const OrderView = () => {
  const [data, setData] = useState(null);
  const location = useLocation();
  const idOrder = location.pathname.split("/")[5];
  const navigate = useNavigate();
  const { lang, user } = useUserAuth();
  const { notify } = useNotification();
  moment.locale(lang === "fr" ? "fr" : "en");
  const { collapsed, broken } = useProSidebar();
  const [viewCode, setViewCode] = useState(false);
  const [dataProgress, setDataProgress] = useState(null);
  const [dataWaiting, setDataWaiting] = useState(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingDeclined, setLoadingDeclined] = useState(false);
  const [loading, setLoading] = useState(false);

  const getTotal = useCallback(async () => {
    const [progress, waiting] = await Promise.all([
      getCollectionAllSearchData(
        "progress",
        "idOrder",
        idOrder,
        "userPub",
        user.uid
      ),
      getCollectionAllSearchData(
        "waiting",
        "idOrder",
        idOrder,
        "userPub",
        user.uid
      ),
    ]);
    setDataProgress(progress.size);
    setDataWaiting(waiting.size);
  }, [idOrder, user.uid]);
  useEffect(() => {
    getTotal();
  }, [getTotal]);

  const getOrder = useCallback(async () => {
    const response = await getCollectionById("orders", idOrder);
    setData(response);
  }, [idOrder]);

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  //columns table
  const columns = [
    {
      title: t("view-publisher-view-order.text-1"),
      dataIndex: "id",
      ellipsis: true,
      fixed: "left",
      width: 80,
    },
    {
      title: t("view-publisher-view-order.text-2"),
      dataIndex: "dateCreate",
      width: 100,
      sorter: (a, b) => a.dateCreate.length - b.dateCreate.length,
    },
    {
      title: <div>{t("view-publisher-view-order.text-3")}</div>,
      dataIndex: "networkType",
      render: (networkType, all) =>
        networkType === "facebook" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={facebookImg} />
          </a>
        ) : networkType === "twitter" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={twitterImg} />
          </a>
        ) : networkType === "youtube" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={youtubeImg} />
          </a>
        ) : networkType === "instagram" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={instagramImg} />
          </a>
        ) : networkType === "tiktok" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={tiktokImg} />
          </a>
        ) : networkType === "snapchat" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={snapchatImg} />
          </a>
        ) : (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={pressImg} />
          </a>
        ),
      width: 100,
    },
    {
      title: t("view-publisher-view-order.text-4"),
      dataIndex: "deliveryDate",
      render: (deliveryDate, all) => (
        <div>{moment(deliveryDate).format("MMMM-Do-YYYY, h:mm:ss a")}</div>
      ),
      width: 100,
      sorter: (a, b) => a.deliveryDate.length - b.deliveryDate.length,
    },
    {
      title: t("view-publisher-view-order.text-5"),
      render: (url, all) => (
        <Tooltip placement="right" title={<CardUrl url={url} />}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Tooltip>
      ),
      dataIndex: "published",
      width: 100,
      sorter: (a, b) => a.published.length - b.published.length,
    },
    {
      title: t("view-publisher-view-order.text-6"),
      dataIndex: "gain",
      width: 100,
      render: (price, all) => `€${parseFloat(price).toFixed(2)}`,
      sorter: (a, b) => a.gain.length - b.gain.length,
    },
  ];

  const order = [];

  data &&
    order.push({
      id: data.id,
      campaign: data.campaignName,
      dateCreate: moment(data.dateCreated).format("L"),
      published: data.url,
      gain: data.priceOfPub,
      networkType: data.networkType,
      deliveryDate: data.deliveryDate.toDate(),
    });

  //const style edit code
  const styles = {
    maxWidth: broken
      ? "100%"
      : collapsed
      ? "calc(100% - 80px)"
      : "calc(100% - 250px)",
  };

  const onClickAccept = async (id) => {
    setLoadingAccept(true);
    const [waiting, orders] = await Promise.all([
      getCollectionAllSearchData("waiting", "idOrder", id, "userPub", user.uid),
      getCollectionById("orders", id),
    ]);
    if (waiting.size > 0) {
      waiting.data.map(async (item) => {
        await deleteDoc(doc(db, "waiting", item.id))
          .then(async () => {
            await addDoc(collection(db, "progress"), {
              idOrder: id,
              idNetwork: orders.idNetwork,
              idCampaign: orders.idCampaign,
              userAdv: orders.userAdv,
              userPub: orders.userPub,
              dateCreated: moment().format(),
              dateUpdated: moment().format(),
            });
            notify(
              "success",
              t("view-publisher-view-order.notify-1"),
              t("view-publisher-view-order.notify-2")
            );
            getOrder();
            getTotal();
          })
          .catch((error) => {
            notify(
              "error",
              t("view-publisher-view-order.notify-3"),
              t("view-publisher-view-order.notify-4")
            );
          });
      });
      setLoadingAccept(false);
    } else {
      notify(
        "error",
        t("view-publisher-view-order.notify-3"),
        t("view-publisher-view-order.notify-4")
      );
      setLoadingAccept(false);
    }
  };

  const onClickDeclined = async (id) => {
    setLoadingDeclined(true);
    const [waiting, orders] = await Promise.all([
      getCollectionAllSearchData("waiting", "idOrder", id, "userPub", user.uid),
      getCollectionById("orders", id),
    ]);
    if (waiting.size > 0) {
      waiting.data.map(async (item) => {
        await deleteDoc(doc(db, "waiting", item.id))
          .then(async () => {
            await addDoc(collection(db, "declined"), {
              idOrder: id,
              idNetwork: orders.idNetwork,
              idCampaign: orders.idCampaign,
              userAdv: orders.userAdv,
              userPub: orders.userPub,
              dateCreated: moment().format(),
              dateUpdated: moment().format(),
            });
            notify(
              "success",
              t("view-publisher-view-order.notify-1"),
              t("view-publisher-view-order.notify-5")
            );
            getOrder();
            getTotal();
          })
          .catch((error) => {
            notify(
              "error",
              t("view-publisher-view-order.notify-3"),
              t("view-publisher-view-order.notify-6")
            );
          });
      });
      setLoadingDeclined(false);
    } else {
      notify(
        "error",
        t("view-publisher-view-order.notify-3"),
        t("view-publisher-view-order.notify-6")
      );
      setLoadingDeclined(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (idOrder) {
      const q = doc(db, "orders", idOrder);
      await updateDoc(q, {
        urlOrder: values.urlOrder,
        dateUpdated: moment().format(),
      })
        .then(async () => {
          getTotal();
          const [progress] = await Promise.all([
            getCollectionAllSearchData(
              "progress",
              "idOrder",
              idOrder,
              "userPub",
              user.uid
            ),
          ]);
          progress.data.map(async (item) => {
            await deleteDoc(doc(db, "progress", item.id));
            await addDoc(collection(db, "treat"), {
              idOrder: idOrder,
              idNetwork: data && data?.idNetwork,
              idCampaign: data && data?.idCampaign,
              userAdv: data && data?.userAdv,
              userPub: data && data?.userPub,
              dateCreated: moment().format(),
              dateUpdated: moment().format(),
            });
            notify(
              "success",
              t("view-publisher-view-order.notify-1"),
              t("view-publisher-view-order.notify-7")
            );
            getOrder();
          });
        })
        .catch((error) => {
          notify(
            "error",
            t("view-publisher-view-order.notify-3"),
            t("view-publisher-view-order.notify-8")
          );
        });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        urlOrder: data && data.urlOrder,
      });
    }
  }, [data, form]);

  return (
    <Page title={`Order ${data && data?.campaignName}`}>
      {data ? (
        <div className="dashboard">
          <Card
            title={data && data?.campaignName}
            extra={
              <Button
                type="primary"
                onClick={(e) =>
                  navigate(`/${lang}/publisher/me/messages/${idOrder}`)
                }
                icon={<MessageOutlined />}
                size="large"
              >
                {t("view-publisher-view-order.btn-5")}
              </Button>
            }
            style={viewCode ? styles : null}
          >
            <Table
              columns={columns}
              dataSource={order}
              scroll={{
                x: 896,
              }}
            />
          </Card>
          {data && data?.possible ? (
            <Card
              title={t("view-publisher-view-order.text-7")}
              style={viewCode ? styles : null}
            >
              <Alert
                type="success"
                message={
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignContent: "center",
                    }}
                  >
                    <CarOutlined style={{ fontSize: "2rem" }} />
                    <p>{t("view-publisher-view-order.text-8")}</p>
                  </div>
                }
              />
            </Card>
          ) : null}

          <Card
            title={t("view-publisher-view-order.text-9")}
            style={viewCode ? styles : null}
            extra={
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <Tooltip
                  title={
                    viewCode
                      ? t("view-publisher-view-order.tooltip-1")
                      : t("view-publisher-view-order.tooltip-2")
                  }
                >
                  <Button
                    icon={viewCode ? <TbCodeOff /> : <BiCodeAlt />}
                    onClick={() => setViewCode(!viewCode)}
                  />
                </Tooltip>
                <Tooltip title={t("view-publisher-view-order.tooltip-3")}>
                  <Button icon={<FilePdfOutlined />} type="primary" />
                </Tooltip>
              </div>
            }
          >
            {viewCode ? (
              <div style={{ overflowX: "auto" }}>
                <CodeEditor
                  value={data && data.advertorialRequirement}
                  language="html"
                  data-color-mode="dark"
                  padding={15}
                  style={{
                    fontSize: 12,
                    fontFamily:
                      "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    maxHeight: 350,
                    minWidth: styles,
                  }}
                />
              </div>
            ) : (
              <div
                style={{ overflowX: "auto", maxWidth: "calc(100% - 250px)" }}
                dangerouslySetInnerHTML={{
                  __html: data && data.advertorialRequirement,
                }}
              />
            )}
          </Card>
          {data && !data.urlOrder && dataProgress && dataProgress > 0 ? (
            <Card
              title={t("view-publisher-view-order.text-10")}
              style={viewCode ? styles : null}
            >
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item
                  name="urlOrder"
                  rules={[
                    {
                      required: true,
                      message: t("view-publisher-view-order.rules-1"),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t("view-publisher-view-order.placeholder-1")}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loading}
                  >
                    {t("view-publisher-view-order.btn-1")}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          ) : data && data.urlOrder && dataWaiting <= 0 ? null : (
            <Card title={t("view-publisher-view-order.text-11")}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => onClickAccept(idOrder)}
                  loading={loadingAccept}
                >
                  {t("view-publisher-view-order.btn-2")}
                </Button>
                <Button
                  size="large"
                  type="primary"
                  danger
                  onClick={() => onClickDeclined(idOrder)}
                  loading={loadingDeclined}
                >
                  {t("view-publisher-view-order.btn-3")}
                </Button>
              </div>
            </Card>
          )}
          {data && data?.urlOrder && data?.status !== "completed" ? (
            <Card
              title={t("view-publisher-view-order.text-10")}
              style={viewCode ? styles : null}
            >
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <Form.Item
                  name="urlOrder"
                  rules={[
                    {
                      required: true,
                      message: t("view-publisher-view-order.rules-1"),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t("view-publisher-view-order.placeholder-1")}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loading}
                  >
                    {t("view-publisher-view-order.btn-4")}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          ) : null}
          {data && data?.urlOrder ? (
            <Card title={t("view-publisher-view-order.text-12")}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {data?.networkType === "facebook" ? (
                  <FacebookEmbed url={data && data?.urlOrder} width={325} />
                ) : data &&
                  data?.urlOrder &&
                  data?.networkType === "instagram" ? (
                  <InstagramEmbed url={data && data?.urlOrder} width={325} />
                ) : data &&
                  data?.urlOrder &&
                  data?.networkType === "twitter" ? (
                  <TwitterEmbed url={data && data?.urlOrder} width={325} />
                ) : data && data?.urlOrder && data?.networkType === "tiktok" ? (
                  <TikTokEmbed url={data && data?.urlOrder} width={325} />
                ) : data &&
                  data?.urlOrder &&
                  data?.networkType === "youtube" ? (
                  <YouTubeEmbed url={data && data?.urlOrder} width={325} />
                ) : (
                  <CardUrl url={data && data?.urlOrder} />
                )}
              </div>
            </Card>
          ) : null}
          {data && data?.correction ? (
            <Card title={t("view-publisher-view-order.text-13")}>
              <Alert type="info" message={data && data?.correction} />
            </Card>
          ) : null}
          {data && data?.status === "completed" ? (
            <Alert
              type="success"
              message={t("view-publisher-view-order.alert")}
            />
          ) : null}
        </div>
      ) : (
        <Spin tip={t("view-publisher-view-order.spin")}>
          <Card
            bodyStyle={{ width: "100%", height: "80vh" }}
            title={t("view-publisher-view-order.text-14")}
          />
        </Spin>
      )}
    </Page>
  );
};

export default OrderView;
