import {
  Avatar,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Modal,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { Fragment, useCallback, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { CardUrl } from "../components";
import pressImg from "../assets/media/press.png";
import youtubeImg from "../assets/media/youtube.png";
import facebookImg from "../assets/media/facebook.svg";
import instagramImg from "../assets/media/instagram.png";
import snapchatImg from "../assets/media/snapchat.svg";
import twitterImg from "../assets/media/twitter.svg";
import tiktokImg from "../assets/media/tiktok.png";
import SunEditor from "suneditor-react";
import { Country } from "country-state-city";
import { ExclamationCircleFilled, EditOutlined } from "@ant-design/icons";
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";
import { useNotification } from "../utils/util-notification";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCollectionAllData } from "../utils/getCollectionData";
import { sendEmail } from "../utils/useEmail";

const { confirm } = Modal;
const AdvertorialRequirements = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataCampaign, setDataCampaign] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [summaryName, setSummaryName] = useState(null);
  const [funds, setFunds] = useState(0);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [allUsers, setAllUsers] = useState(null);
  const {
    summary,
    cart,
    user,
    onIncreasingSteps,
    onDecreasingSteps,
    lang,
    removeLocalStorageAll,
    onFinishSummary,
    userAuth,
  } = useUserAuth();
  const { notify } = useNotification();
  const navigate = useNavigate();

  const getAllUsers = async () => {
    await getCollectionAllData("users", "", "", "")
      .then((val) => {
        setAllUsers(val.data);
      })
      .catch((error) => {
        setAllUsers([]);
      });
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  // get fund
  useEffect(() => {
    if (user) {
      // Define Firestore queries to retrieve data
      const q = query(collection(db, "funds"), where("userId", "==", user.uid));

      // Listen for changes in the 'networks.' collection and update dataNetworks array
      const unsubscribeFunds = onSnapshot(q, (querySnapshot) => {
        setFunds(
          querySnapshot.docs
            .reduce(
              (accumulator, currentItem) =>
                parseFloat(accumulator) + parseFloat(currentItem.fund),
              0
            )
            .toFixed(2)
        );
      });

      // Return cleanup function to unsubscribe from Firestore listeners
      return () => {
        unsubscribeFunds();
      };
    }
  }, [user]);

  //get flag country
  const getFlag = (country) => {
    const val = Country.getAllCountries().find((item) => item.name === country);
    return val.flag;
  };

  const columns = [
    {
      title: t("sections-advertorialRequirements.text-1"),
      dataIndex: "networkName",
      width: 120,
      sorter: (a, b) => a.networkName.length - b.networkName.length,
    },
    {
      title: t("sections-advertorialRequirements.text-2"),
      dataIndex: "country",
      width: 120,
      render: (country, all) => (
        <Space size="small">
          <div>{getFlag(country)}</div>
          <div>{country}</div>
        </Space>
      ),
      sorter: (a, b) => a.networkName.length - b.networkName.length,
    },
    {
      title: t("sections-advertorialRequirements.text-3"),
      dataIndex: "url",
      render: (url, all) => (
        <Tooltip placement="right" title={<CardUrl url={url} />}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Tooltip>
      ),
      width: 120,
      sorter: (a, b) => a.url.length - b.url.length,
    },
    {
      title: t("sections-advertorialRequirements.text-4"),
      dataIndex: "price",
      render: (price, all) => `€${parseFloat(price).toFixed(2)}`,
      width: 120,
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: t("sections-advertorialRequirements.text-5"),
      dataIndex: "visits",
      width: 120,
      sorter: (a, b) => a.visits.length - b.visits.length,
    },
    {
      title: <div>{t("sections-advertorialRequirements.text-6")}</div>,
      dataIndex: "networkType",
      render: (networkType, all) =>
        networkType === "facebook" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={facebookImg} />
          </a>
        ) : networkType === "twitter" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={twitterImg} />
          </a>
        ) : networkType === "youtube" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={youtubeImg} />
          </a>
        ) : networkType === "instagram" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={instagramImg} />
          </a>
        ) : networkType === "tiktok" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={tiktokImg} />
          </a>
        ) : networkType === "snapchat" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={snapchatImg} />
          </a>
        ) : (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={pressImg} />
          </a>
        ),
      width: 120,
    },
  ];

  const options = {
    mode: "classic",
    rtl: false,
    katex: "window.katex",
    imageGalleryUrl:
      "https://etyswjpn79.execute-api.ap-northeast-1.amazonaws.com/suneditor-demo",
    videoFileInput: false,
    tabDisable: false,
    buttonList: [
      [
        "undo",
        "redo",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "blockquote",
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
        "lineHeight",
        "table",
        "link",
        "image",
        "video",
        "audio",
        "math",
        "imageGallery",
        "fullScreen",
        "showBlocks",
        "codeView",
        "preview",
        "print",
        "save",
      ],
    ],
  };
  const getSummary = useCallback(() => {
    if (summary) {
      form.setFieldsValue({
        idCampaign: summary.id,
      });
      setSummaryName(
        dataCampaign && dataCampaign.find((item) => item.id === summary.id)
      );
    }
  }, [dataCampaign, form, summary]);
  useEffect(() => {
    getSummary();
  }, [getSummary]);

  const getAllCampaigns = useCallback(async () => {
    const q = query(
      collection(db, "campaigns"),
      where("userId", "==", user.uid)
    );
    const get = await getDocs(q);
    setDataCampaign(
      get.docs.map((doc) => ({
        id: doc.id,
        campaignName: doc.data().campaignName,
      }))
    );
  }, [user.uid]);

  useEffect(() => {
    getAllCampaigns();
  }, [getAllCampaigns]);

  const onFinishUpdateSummary = (values) => {
    setLoading(true);
    onFinishSummary(values);
    setLoading(false);
    setIsModalOpen(false);
    getSummary();
  };

  const onFinish = (values) => {
    setLoadingFinish(true);
    if (funds <= 0) {
      confirm({
        title: t("sections-advertorialRequirements.confirm-title-1"),
        icon: <ExclamationCircleFilled />,
        content: t("sections-advertorialRequirements.confirm-content-1"),
        okText: t("sections-advertorialRequirements.confirm-okText-1"),
        cancelText: t("sections-advertorialRequirements.confirm-cancelText-1"),
        async onOk() {
          navigate(`/${lang}/advertiser/me/money`);
        },
      });
      setLoadingFinish(false);
    } else {
      confirm({
        title: (
          <Typography.Title level={5}>
            Confirm order €
            {cart
              .reduce(
                (accumulator, currentItem) =>
                  parseFloat(accumulator) + parseFloat(currentItem.price),
                0
              )
              .toFixed(2)}
          </Typography.Title>
        ),
        icon: <ExclamationCircleFilled />,
        content: t("sections-advertorialRequirements.confirm-content-2"),
        okText: t("sections-advertorialRequirements.confirm-okText-2"),
        okType: "danger",
        cancelText: t("sections-advertorialRequirements.confirm-cancelText-2"),
        onOk() {
          cart.map(async (item) => {
            const addOrder = await addDoc(collection(db, "orders"), {
              userAdv: user.uid,
              userPub: item.pub,
              idCampaign: summary.id,
              idNetwork: item.id,
              networkType: item.networkType,
              networkName: item.networkName,
              campaignName: summaryName && summaryName.campaignName,
              url: item.url,
              country: item.country,
              advertorialRequirement: values.advertorialRequirement,
              deliveryDate: values.deliveryDate.$d,
              priceOfPub: parseFloat(item.price / 1.4).toFixed(2),
              priceOfAdv: item.price,
              possible: values.possible === undefined ? false : values.possible,
              dateCreated: moment().format(),
              dateUpdated: moment().format(),
            });
            if (addOrder.id) {
              await addDoc(collection(db, "waiting"), {
                idOrder: addOrder.id,
                idNetwork: item.id,
                idCampaign: summary.id,
                userAdv: user.uid,
                userPub: item.pub,
                dateCreated: moment().format(),
                dateUpdated: moment().format(),
              });
              const userTo =
                allUsers && allUsers.find((val) => val.userId === item.pub);
              await sendEmail(
                lang,
                addOrder.id,
                userTo.email,
                userTo.firstName,
                userAuth.firstName,
                values.advertorialRequirement,
                item.price,
                item.networkType
              );
              notify(
                "success",
                t("sections-advertorialRequirements.notify-1"),
                t("sections-advertorialRequirements.notify-2")
              );
              removeLocalStorageAll();
              onIncreasingSteps(3);
            } else {
              notify(
                "error",
                t("sections-advertorialRequirements.notify-3"),
                t("sections-advertorialRequirements.notify-4")
              );
            }
          });
        },
      });
      setLoadingFinish(false);
    }
  };

  useEffect(() => {
    if (!cart || cart.length <= 0) {
      onIncreasingSteps(0);
    }
  }, [cart, onIncreasingSteps]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Fragment>
      <Space size="large" direction="vertical">
        <Card
          title={
            <Button onClick={() => showModal()} icon={<EditOutlined />}>
              {summaryName && summaryName.campaignName}
            </Button>
          }
        >
          <Table
            scroll={{
              x: 900,
            }}
            dataSource={cart}
            columns={columns}
            loading={!cart ? true : false}
          />
          <h3 style={{ textAlign: "right" }}>
            TOTAL:{" "}
            {`€${cart
              .reduce(
                (accumulator, currentItem) =>
                  parseFloat(accumulator) + parseFloat(currentItem.price),
                0
              )
              .toFixed(2)}`}
          </h3>
        </Card>

        <Form onFinish={onFinish} layout="vertical">
          <Card title={t("sections-advertorialRequirements.title-1")}>
            <Form.Item
              name="advertorialRequirement"
              label={t("sections-advertorialRequirements.label-1")}
              rules={[
                {
                  required: true,
                  message: t("sections-advertorialRequirements.rules-1"),
                },
              ]}
            >
              <SunEditor
                height="300px"
                setOptions={options}
                lang={lang === "fr" ? "fr" : "en"}
                placeholder={t(
                  "sections-advertorialRequirements.placeholder-1"
                )}
              />
            </Form.Item>
          </Card>
          <Divider />
          <Card title="">
            <Form.Item
              name="deliveryDate"
              label={t("sections-advertorialRequirements.label-2")}
              rules={[
                {
                  required: true,
                  message: t("sections-advertorialRequirements.rules-2"),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                showTime={{
                  format: "HH:mm",
                }}
                format="YYYY-MM-DD HH:mm"
                size="large"
              />
            </Form.Item>
            <Form.Item name="possible" valuePropName="checked">
              <Checkbox>
                {t("sections-advertorialRequirements.checkbox-1")}
              </Checkbox>
            </Form.Item>
          </Card>
          <Form.Item>
            <div
              style={{
                margin: "2rem 2rem",
                display: "flex",
                justifyContent: "space-between",
                position: "sticky",
                bottom: 0,
              }}
            >
              <Button onClick={() => onDecreasingSteps(1)} size="large">
                {t("sections-advertorialRequirements.btn-1")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loadingFinish}
              >
                {t("sections-advertorialRequirements.btn-2")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Space>
      <Modal
        title={t("sections-advertorialRequirements.title-3")}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={onFinishUpdateSummary} form={form}>
          <Form.Item
            label={t("sections-advertorialRequirements.label-3")}
            name="idCampaign"
            rules={[
              {
                required: true,
                message: t("sections-advertorialRequirements.rules-3"),
              },
            ]}
          >
            <Select
              size="large"
              placeholder={t("sections-advertorialRequirements.placeholder-3")}
            >
              {dataCampaign?.map((item, key) => (
                <Select.Option value={item.id} key={key}>
                  {item.campaignName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "1rem",
              }}
            >
              <Button type="default" onClick={handleCancel} size="large">
                {t("sections-advertorialRequirements.btn-3")}
              </Button>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t("sections-advertorialRequirements.btn-4")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default AdvertorialRequirements;
