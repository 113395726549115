import React from "react";
import { Layout, theme } from "antd";
import { Outlet } from "react-router-dom";
import { Footer } from "../global";

const LayoutAuth = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout
      style={{
        background: colorBgContainer,
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Outlet />
      <Footer />
    </Layout>
  );
};
export default LayoutAuth;
