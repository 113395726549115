import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import { useUserAuth } from "../context/UserAuthContext";
import { useTranslation } from "react-i18next";

const MyEmail = () => {
  const [form] = Form.useForm();
  const { user, updateEmailUser } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const onFinish = async (values) => {
    setLoading(true);
    await updateEmailUser(values.email, user.uid);
    setLoading(false);
  };
  useEffect(() => {
    form.setFieldsValue({
      email: user && user?.email,
    });
  }, [form, user]);
  return (
    <Form name="my-email" layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item
        name="email"
        label={t("sections-MyEmail.label")}
        rules={[
          {
            required: true,
            message: t("sections-MyEmail.message-1"),
          },
          {
            type: "email",
            message: t("sections-MyEmail.message-2"),
          },
        ]}
      >
        <Input
          prefix={<MailOutlined />}
          size="large"
          placeholder={t("sections-MyEmail.placeholder")}
        />
      </Form.Item>

      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" loading={loading}>
          {t("sections-MyEmail.btn")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MyEmail;
