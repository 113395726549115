import React from "react";
import { Page } from "../components";
import "../assets/styles/legal-notice.css";
import { NavBarAuth } from "../global";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

const LegalNotice = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("view-legal-notice.title")}>
      <div className="legal-notice">
        <NavBarAuth />
        <div className="legal-notice-container">
          <Typography.Title style={{ textAlign: "center" }}>
            {t("view-legal-notice.title-1")}
          </Typography.Title>
          <div className="legal-notice-content">
            <div className="legal-notice-item">
              <Typography.Title level={4}>
                {t("view-legal-notice.title-2")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  ROCKETFLUENCE
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  Unit 201, 11C Street, Al Zaahia Building
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  Al Qusaidat, Ras Al Khaimah
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  ÉMIRATS ARABES UNIS
                </Typography.Paragraph>
              </div>
            </div>
            <div className="legal-notice-item">
              <Typography.Title level={4}>
                {t("view-legal-notice.title-3")}
              </Typography.Title>
              <div>
                <Typography.Paragraph>ROCKETFLUENCE</Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  Unit 201, 11C Street, Al Zaahia Building
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  Al Qusaidat, Ras Al Khaimah
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  ÉMIRATS ARABES UNIS
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-legal-notice.paragraph-1")}{" "}
                  <a href="tel:+1(302)289-6245">+1(302)289-6245</a>
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-legal-notice.paragraph-2")}{" "}
                  <a href="mailto:support@rocketfluence.com">
                    support@rocketfluence.com
                  </a>
                </Typography.Paragraph>
              </div>
            </div>
            <div className="legal-notice-item">
              <Typography.Title level={4}>
                {t("view-legal-notice.title-4")}
              </Typography.Title>
              <Typography.Paragraph style={{ textAlign: "justify" }}>
                MAHAVONJY Zafindresamby
              </Typography.Paragraph>
              <Typography.Paragraph style={{ textAlign: "justify" }}>
                {t("view-legal-notice.paragraph-2")}{" "}
                <a href="mailto:mahavonjy@ferberenterprises.com">
                  mahavonjy@ferberenterprises.com
                </a>
              </Typography.Paragraph>
            </div>
            <div className="legal-notice-item">
              <Typography.Title level={4}>
                {t("view-legal-notice.title-5")}
              </Typography.Title>
              <Typography.Paragraph style={{ textAlign: "justify" }}>
                {t("view-legal-notice.paragraph-3")}
              </Typography.Paragraph>
            </div>
            <div className="legal-notice-item">
              <Typography.Title level={4}>
                {t("view-legal-notice.title-6")}
              </Typography.Title>
              <div>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-legal-notice.paragraph-4")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-legal-notice.paragraph-5")}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ textAlign: "justify" }}>
                  {t("view-legal-notice.paragraph-6")}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="legal-notice-item">
              <Typography.Title level={4}>
                {" "}
                {t("view-legal-notice.title-7")}
              </Typography.Title>
              <Typography.Paragraph style={{ textAlign: "justify" }}>
                {t("view-legal-notice.paragraph-7")}
              </Typography.Paragraph>
            </div>
            <div className="legal-notice-item">
              <Typography.Title level={4}>
                {t("view-legal-notice.title-8")}
              </Typography.Title>
              <Typography.Paragraph style={{ textAlign: "justify" }}>
                {t("view-legal-notice.paragraph-8")}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ textAlign: "justify" }}>
                {t("view-legal-notice.paragraph-9")}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ textAlign: "justify" }}>
                {t("view-legal-notice.paragraph-10")}
              </Typography.Paragraph>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default LegalNotice;
