import { Button, Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { useTranslation } from "react-i18next";

const UpdatePwd = () => {
  const { updatePwd } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const onFinish = async (values) => {
    setLoading(true);
    await updatePwd(values.newPwd);
    setLoading(false);
  };
  return (
    <Form name="login" layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="newPwd"
        label={t("sections-updatePwd.label-1")}
        rules={[
          {
            required: true,
            message: t("sections-updatePwd.message-1"),
          },
          {
            min: 6,
            message: t("sections-updatePwd.message-2"),
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t("sections-updatePwd.placeholder-1")}
          size="large"
        />
      </Form.Item>
      <Form.Item
        name="confirmPwd"
        label={t("sections-updatePwd.label-2")}
        rules={[
          {
            required: true,
            message: t("sections-updatePwd.message-3"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("newPwd") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("sections-updatePwd.error-1")));
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder={t("sections-updatePwd.placeholder-2")}
          size="large"
        />
      </Form.Item>
      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" loading={loading}>
          {t("sections-updatePwd.btn")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdatePwd;
