import React from "react";
import { Page } from "../components";
import "../assets/styles/terms-advertiser.css";
import { NavBarAuth } from "../global";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import "../assets/styles/terms-advertiser.css";

const TermsAdvertiser = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("view-terms-advertiser.title")}>
      <NavBarAuth />
      <div className="terms-advertiser">
        <Typography.Title style={{ textAlign: "center" }}>
          {t("view-terms-advertiser.text-1")}
        </Typography.Title>
        <div className="terms-advertiser-content">
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-2")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-3-1")}
              <a href={t("view-terms-advertiser.link")}>
                {" "}
                https://www.rocketfluence.com/{" "}
              </a>
              {t("view-terms-advertiser.text-3-2")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-4")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-5")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-6-1")}
              <a href={t("view-terms-advertiser.link")}>
                {" "}
                https://www.rocketfluence.com/.{" "}
              </a>
              {t("view-terms-advertiser.text-6-2")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-7")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-8")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-9")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-10")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-11")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-12")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-13")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-14")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-15")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-16")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-17")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-18")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-19")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-20")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-21")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-22")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-23")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-24")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-25")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-26")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-27")}
            </Typography.Title>
            <Typography.Title level={5} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-28")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-29")}
            </Typography.Paragraph>
            <Typography.Title level={5} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-30")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-31")}
            </Typography.Paragraph>
            <Typography.Title level={5} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-32")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-33")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-34")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-35")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-36")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-37")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-38")}
            </Typography.Paragraph>
            <Typography.Title level={5} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-39")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-40")}
            </Typography.Paragraph>
            <Typography.Title level={5} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-41")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-42")}
            </Typography.Paragraph>
            <Typography.Title level={5} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-43")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-44")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-45")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-46")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-47")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-48")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-49")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-49-1")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-49-2")}
            </Typography.Paragraph>
            <Typography.Title level={5} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-50")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-51")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-52")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-53")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-54")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-55")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-56")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-57")}
            </Typography.Paragraph>
            <Typography.Title level={5} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-58")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-59")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-60")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-61")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-62")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-63")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-64")}
            </Typography.Paragraph>
            <Typography.Title level={5} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-65")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-65-1")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-66")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-67")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-68")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-69")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-70")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-71")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-72")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-73")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-74")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-75")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-76")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-77")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-78")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-79")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-80")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-81")}
            </Typography.Paragraph>
            <Typography.Title level={5} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-82")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-83")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-84")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-85")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-86")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-87")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-88")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-89")}
            </Typography.Paragraph>
          </div>

          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-90")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-91")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-92")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-93")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-94")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-95")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-96")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-97")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-98")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-99")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-100")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-101")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-102")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-103")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-104")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-105")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-106")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-107")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-108")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-109")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-110")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-111")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-112")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-113")}
            </Typography.Paragraph>
          </div>
          <div className="terms-advertiser-item">
            <Typography.Title level={4} style={{ textAlign: "justify" }}>
              {t("view-terms-advertiser.text-114")}
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              <b>{t("view-terms-advertiser.text-115")}: </b>
              {t("view-terms-advertiser.text-116")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              <b>{t("view-terms-advertiser.text-117")}: </b>
              {t("view-terms-advertiser.text-118")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              <b>{t("view-terms-advertiser.text-119")}: </b>
              {t("view-terms-advertiser.text-120")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              <b>{t("view-terms-advertiser.text-121")}: </b>
              {t("view-terms-advertiser.text-122")}
            </Typography.Paragraph>
            <Typography.Paragraph style={{ textAlign: "justify" }}>
              <b>{t("view-terms-advertiser.text-123")}: </b>
              {t("view-terms-advertiser.text-124")}
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default TermsAdvertiser;
