import { Button, Typography } from "antd";
import React from "react";
import { NavBarAuth } from "../global";
import HeroGlog from "../assets/media/hero-svg.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "../assets/styles/hero.css";
import { useTranslation } from "react-i18next";
import { useUserAuth } from "../context/UserAuthContext";

const Hero = () => {
  const { t } = useTranslation();
  const { lang } = useUserAuth();
  //hooks navigate
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="hero">
      <NavBarAuth />
      <div className="hero-content">
        <div className="hero-content-left" data-aos="fade-right">
          <Typography.Paragraph
            style={{
              fontSize: "14px",
              color: "#687690",
              fontWeight: "500",
            }}
            className="hero-content-left-text"
          >
            {t("sections-hero.text-1")}
          </Typography.Paragraph>
          {location.pathname === `/${lang}` ? (
            <Typography.Title
              style={{
                fontSize: "42px",
                color: "#000336",
                fontWeight: "bold",
              }}
              className="hero-content-left-text"
            >
              {t("sections-hero.text-2")}
            </Typography.Title>
          ) : (
            <Typography.Title
              style={{
                fontSize: "42px",
                color: "#000336",
                fontWeight: "bold",
              }}
              className="hero-content-left-text"
            >
              {t("sections-hero.text-4")}
            </Typography.Title>
          )}
          <Typography.Paragraph
            style={{ fontSize: "14px", color: "#5A6473" }}
            className="hero-content-left-text"
          >
            {t("sections-hero.text-3")}
          </Typography.Paragraph>
          <Button
            size="large"
            type="primary"
            onClick={() => navigate(`/${lang}/register`)}
          >
            {t("sections-hero.btn-1")}
          </Button>
        </div>
        <div className="hero-content-right" data-aos="fade-left">
          <img
            src={HeroGlog}
            alt="rocketfluence hero"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
