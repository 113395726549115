import React from "react";
import ReactDOM from "react-dom/client";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { ConfigProvider } from "antd";
import { ProSidebarProvider } from "react-pro-sidebar";
import "react-phone-input-2/lib/style.css";
import "suneditor/dist/css/suneditor.min.css";
import "antd/dist/reset.css";
import "./index.css";
import App from "./App";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { NotificationProvider } from "./utils/util-notification";
import en_US from "antd/es/calendar/locale/en_US";
import fr_FR from "antd/es/calendar/locale/fr_FR";
import Cookies from "js-cookie";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "fr"],
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    react: {
      useSuspense: true,
    },
  });

const lang = Cookies.get("i18next") || "en"; // fallback to "en" if cookie is not set

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#2C4E7A",
        },
      }}
      locale={lang === "fr" ? fr_FR : en_US}
    >
      <NotificationProvider>
        <UserAuthContextProvider>
          <ProSidebarProvider>
            <App />
          </ProSidebarProvider>
        </UserAuthContextProvider>
      </NotificationProvider>
    </ConfigProvider>
  </React.StrictMode>
);
