import React from "react";
import { Button, Card, Typography, Alert, Space, Result } from "antd";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { Page } from "../../components";
import "../../assets/styles/my-account.css";

import { useTranslation } from "react-i18next";
import StripeImg from "../../assets/media/stripe.png";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import { InvoicesTable } from "../../sections";

const Completion = () => {
  const { t } = useTranslation();
  const { lang } = useUserAuth();
  const navigate = useNavigate();

  return (
    <Page title={t("view-account-moneydeposit.title-1")}>
      <div className="my-account">
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Title level={4}>
              {t("view-account-moneydeposit.title-2")}
            </Typography.Title>
            <Button
              type="primary"
              ghost
              icon={<MdOutlineAddShoppingCart style={{ marginRight: "8px" }} />}
              size="large"
              onClick={() => navigate(`/${lang}/advertiser/me/new/order`)}
            >
              {t("view-account-moneydeposit.btn-1")}
            </Button>
          </div>
        </Card>
        <div className="account-content">
          <Card
            title={
              <Typography.Title level={4}>Method payment</Typography.Title>
            }
          >
            <Space direction="vertical" size="large">
              <img
                style={{ width: "100%", maxHeight: "150px" }}
                src={StripeImg}
                alt="Stripe"
              />
              <Alert
                type="info"
                message="See the full suite of Stripe Payments features, including supported payment methods, revenue optimization, fraud protection, mobile app payments, and more."
              />
            </Space>
          </Card>
          <Card title="Payment successful">
            <Result
              status="success"
              extra={[
                <Button
                  size="large"
                  onClick={() => navigate(`/${lang}/advertiser/me/invoices`)}
                >
                  Go to invoices
                </Button>,
                <Button
                  size="large"
                  type="primary"
                  onClick={() => navigate(`/${lang}/advertiser/me/money`)}
                >
                  Got to payment
                </Button>,
              ]}
            />
          </Card>
        </div>
        <InvoicesTable title="History" />
      </div>
    </Page>
  );
};

export default Completion;
