import { Avatar, Button, Table, Tooltip } from "antd";
import { join } from "array-join";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { db } from "../firebase";
import { EyeOutlined, CloseOutlined, MessageOutlined } from "@ant-design/icons";
import { BsCheckCircle } from "react-icons/bs";
import { CardUrl } from "../components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getCollectionAllSearchData,
  getCollectionById,
} from "../utils/getCollectionData";
import { useNotification } from "../utils/util-notification";
import youtubeImg from "../assets/media/youtube.png";
import facebookImg from "../assets/media/facebook.svg";
import instagramImg from "../assets/media/instagram.png";
import snapchatImg from "../assets/media/snapchat.svg";
import twitterImg from "../assets/media/twitter.svg";
import tiktokImg from "../assets/media/tiktok.png";
import pressImg from "../assets/media/press.png";

const RevisionTablePub = ({ idNetwork, searchText }) => {
  const { t } = useTranslation();
  const [loadingData, setLoadingData] = useState(null);
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const { user, lang } = useUserAuth();
  const navigate = useNavigate();
  const { notify } = useNotification();

  //component actions
  const ActionsPub = ({ id }) => {
    const [dataRevision, setDataRevision] = useState(null);
    const getTotal = useCallback(async () => {
      const [revision] = await Promise.all([
        getCollectionAllSearchData("revision", "idOrder", id, "", ""),
      ]);
      setDataRevision(revision.size);
    }, [id]);
    useEffect(() => {
      getTotal();
    }, [getTotal]);

    return (
      <div style={{ display: "flex", gap: "2px" }}>
        <Tooltip placement="bottom" title="View">
          <Button
            type="primary"
            ghost
            icon={<EyeOutlined />}
            onClick={(e) => navigate(`/${lang}/publisher/me/order/${id}`)}
          />
        </Tooltip>
        {dataRevision && dataRevision > 0 ? (
          <Tooltip
            placement="bottom"
            title={t("sections-all-table-pub.btn-title-1")}
          >
            <Button
              type="primary"
              icon={<BsCheckCircle />}
              onClick={() => onClickAccept(id)}
            />
          </Tooltip>
        ) : null}
        {dataRevision && dataRevision > 0 ? (
          <Tooltip
            placement="bottom"
            title={t("sections-all-table-pub.btn-title-2")}
          >
            <Button
              type="primary"
              danger
              icon={<CloseOutlined />}
              onClick={() => onClickDeclined(id)}
            />
          </Tooltip>
        ) : null}
        <Tooltip
          placement="bottom"
          title={t("sections-all-table-pub.btn-title-3")}
        >
          <Button
            icon={<MessageOutlined />}
            onClick={(e) => navigate(`/${lang}/publisher/me/messages/${id}`)}
          />
        </Tooltip>
      </div>
    );
  };

  //columns table
  const columns = [
    {
      title: t("sections-all-table-pub.title-1"),
      dataIndex: "id",
      ellipsis: true,
      width: 80,
    },
    {
      title: t("sections-all-table-pub.title-2"),
      dataIndex: "campaign",
      width: 100,
      sorter: (a, b) => a.campaign.length - b.campaign.length,
    },
    {
      title: <div>{t("sections-all-table-pub.title-3")}</div>,
      dataIndex: "networkType",
      render: (networkType, all) =>
        networkType === "facebook" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={facebookImg} />
          </a>
        ) : networkType === "twitter" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={twitterImg} />
          </a>
        ) : networkType === "youtube" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={youtubeImg} />
          </a>
        ) : networkType === "instagram" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={instagramImg} />
          </a>
        ) : networkType === "tiktok" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={tiktokImg} />
          </a>
        ) : networkType === "snapchat" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={snapchatImg} />
          </a>
        ) : (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={pressImg} />
          </a>
        ),
      width: 120,
    },
    {
      title: t("sections-all-table-pub.title-4"),
      dataIndex: "order",
      width: 100,
      sorter: (a, b) => a.order.length - b.order.length,
    },
    {
      title: t("sections-all-table-pub.title-5"),
      dataIndex: "todo",
      width: 100,
      sorter: (a, b) => a.todo.length - b.todo.length,
    },
    {
      title: t("sections-all-table-pub.title-6"),
      render: (website, all) => (
        <Tooltip placement="right" title={<CardUrl url={website} />}>
          <a href={website} target="_blank" rel="noopener noreferrer">
            {website}
          </a>
        </Tooltip>
      ),
      dataIndex: "affectedWeb",
      width: 100,
      sorter: (a, b) => a.affectedWeb.length - b.affectedWeb.length,
    },
    {
      title: t("sections-all-table-pub.title-8"),
      dataIndex: "gain",
      width: 100,
      render: (price, all) => `€${parseFloat(price).toFixed(2)}`,
      sorter: (a, b) => a.gain.length - b.gain.length,
    },
    {
      title: t("sections-all-table-pub.title-9"),
      dataIndex: "actions",
      fixed: "right",
      render: (id, all) => <ActionsPub id={id} />,
      width: 120,
    },
  ];

  //get all orders
  const getAllOrders = useCallback(async () => {
    setLoadingData(true);
    const q1 = query(collection(db, "orders"));
    const q1i = query(
      collection(db, "orders"),
      where("userPub", "==", user.uid),
      where("idNetwork", "==", idNetwork)
    );

    const q2 = query(
      collection(db, "revision"),
      where("userPub", "==", user.uid)
    );

    const get1 = await getDocs(idNetwork === "" ? q1 : q1i);
    const get2 = await getDocs(q2);

    const orders = join(
      get2.docs.map((doc) => ({
        idRevision: doc.id,
        idOrder: doc.data().idOrder,
      })),
      get1.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      (left) => left.idOrder,
      (right) => right.id,
      (left, right) => ({ ...left, ...right })
    );

    setData(
      orders &&
        orders.map((order) => ({
          id: order.id,
          campaign: order.campaignName,
          networkType: order.networkType,
          order: moment(order.dateCreated).format("L"),
          affectedWeb: order.url,
          gain: order.priceOfPub,
          actions: order.id,
          idCampaign: order.idCampaign,
          idNetwork: order.idNetwork,
          idRevision: order.idRevision,
        }))
    );

    setLoadingData(false);
  }, [idNetwork, user.uid]);

  useEffect(() => {
    getAllOrders();
  }, [getAllOrders]);

  const onClickAccept = async (id) => {
    const [waiting, orders] = await Promise.all([
      getCollectionAllSearchData("revision", "idOrder", id, "", ""),
      getCollectionById("orders", id),
    ]);
    if (waiting.size > 0) {
      waiting.data.map(async (item) => {
        const q = doc(db, "orders", id);
        await updateDoc(q, {
          dateAccept: moment().format(),
          dateUpdated: moment().format(),
        })
          .then(async () => {
            await deleteDoc(doc(db, "revision", item.id))
              .then(async () => {
                await addDoc(collection(db, "progress"), {
                  idOrder: id,
                  idNetwork: orders.idNetwork,
                  idCampaign: orders.idCampaign,
                  userAdv: orders.userAdv,
                  userPub: orders.userPub,
                  dateCreated: moment().format(),
                  dateUpdated: moment().format(),
                });
                notify(
                  "success",
                  "Success",
                  "Accepting the order is successful"
                );
                getAllOrders();
              })
              .catch((error) => {
                notify("error", "Error", "Error accepting this order");
              });
          })
          .catch((error) => {
            notify("error", "Error", "Error accepting this order");
          });
      });
    } else {
      notify("error", "Error", "Error accepting this order");
    }
  };

  const onClickDeclined = async (id) => {
    const [waiting, orders] = await Promise.all([
      getCollectionAllSearchData("waiting", "idOrder", id, "", ""),
      getCollectionById("orders", id),
    ]);
    if (waiting.size > 0) {
      waiting.data.map(async (item) => {
        const q = doc(db, "orders", id);
        await updateDoc(q, {
          dateAccept: moment().format(),
          dateUpdated: moment().format(),
        })
          .then(async () => {
            await deleteDoc(doc(db, "revision", item.id))
              .then(async () => {
                await addDoc(collection(db, "declined"), {
                  idOrder: id,
                  idNetwork: orders.idNetwork,
                  idCampaign: orders.idCampaign,
                  userAdv: orders.userAdv,
                  userPub: orders.userPub,
                  dateCreated: moment().format(),
                  dateUpdated: moment().format(),
                });
                notify(
                  "success",
                  "Success",
                  "Accepting the order is successful"
                );
                getAllOrders();
              })
              .catch((error) => {
                notify("error", "Error", "Error accepting this order");
              });
          })
          .catch((error) => {
            notify("error", "Error", "Error accepting this order");
          });
      });
    } else {
      notify("error", "Error", "Error accepting this order");
    }
  };

  const handleSearch = useCallback(() => {
    const filteredData =
      data &&
      data.filter(
        (record) =>
          record.campaign.toLowerCase().includes(searchText.toLowerCase()) ||
          record.affectedWeb.toLowerCase().includes(searchText.toLowerCase())
      );
    setFilteredData(filteredData);
  }, [data, searchText]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);
  return (
    <Table
      columns={columns}
      loading={loadingData}
      scroll={{
        x: 896,
      }}
      dataSource={searchText === "" ? data : filteredData}
    />
  );
};

export default RevisionTablePub;
