import React from "react";
import "../assets/styles/count.css";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useUserAuth } from "../context/UserAuthContext";

const Count = () => {
  const { t } = useTranslation();
  const { totalAdv, totalPub } = useUserAuth();
  return (
    <div className="count">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          placeItems: "center",
        }}
      >
        <Typography.Title
          level={2}
          style={{ textAlign: "center" }}
          data-aos="fade-right"
        >
          {t("sections-count.typography-title-1")}
        </Typography.Title>
      </div>
      <div className="count-number">
        <div data-aos="zoom-in">
          <h1>{totalAdv}</h1>
          <h3>{t("sections-count.typography-text-1")}</h3>
        </div>
        <div data-aos="zoom-in">
          <h1>{totalPub}</h1>
          <h3>{t("sections-count.typography-text-2")}</h3>
        </div>
      </div>
    </div>
  );
};

export default Count;
