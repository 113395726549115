import React from "react";
import { Button, Divider, Drawer, Grid } from "antd";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "../assets/styles/nav-bar-auth.css";
import { Logo } from "../components";
import { useUserAuth } from "../context/UserAuthContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const { useBreakpoint } = Grid;

const NavBarAuth = () => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  //hooks react-router-dom
  const location = useLocation();
  const navigate = useNavigate();
  const { userAuth, lang } = useUserAuth();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  //data navlink
  const navlink = [
    { to: `/${lang}`, text: t("nav-bar-auth.text-1") },
    { to: `/${lang}/publishers`, text: t("nav-bar-auth.text-2") },
    { to: `/${lang}/about-us`, text: t("nav-bar-auth.text-4") },
  ];
  const navlinkLoginAndRegister = [
    { to: `/${lang}/login`, text: t("nav-bar-auth.btn-1") },
    { to: `/${lang}/register`, text: t("nav-bar-auth.btn-2") },
  ];
  //click button login and register
  const clickLogin = () => {
    navigate(`/${lang}/login`);
  };
  const clickRegister = () => {
    navigate(`/${lang}/register`);
  };
  return (
    <div className="nav-bar-auth">
      <Link to={`/${lang}`}>
        <Logo />
      </Link>
      <nav className="nav-bar-auth-link">
        {navlink.map((item, i) => (
          <NavLink
            key={i}
            to={item.to}
            className={
              item.to === location.pathname
                ? "auth-navlink active-navlink"
                : "auth-navlink"
            }
          >
            {item.text}
          </NavLink>
        ))}
      </nav>
      {userAuth === null ? (
        location.pathname === `/${lang}/register` ? (
          <div className="nav-bar-auth-right">
            <Button
              type="primary"
              onClick={clickLogin}
              size="large"
              className="nav-bar-auth-right-btn"
            >
              {t("nav-bar-auth.btn-1")}
            </Button>
            <Button
              type="default"
              onClick={clickRegister}
              size="large"
              className="nav-bar-auth-right-btn"
            >
              {t("nav-bar-auth.btn-2")}
            </Button>
            {screens.sm === true ? null : (
              <Button
                className="btn-nav-menu"
                icon={<AiOutlineMenu />}
                onClick={showDrawer}
                size="large"
              />
            )}
          </div>
        ) : location.pathname === `/${lang}/login` ? (
          <div className="nav-bar-auth-right">
            <Button
              type="default"
              onClick={clickLogin}
              size="large"
              className="nav-bar-auth-right-btn"
            >
              {t("nav-bar-auth.btn-1")}
            </Button>
            <Button
              type="primary"
              onClick={clickRegister}
              size="large"
              className="nav-bar-auth-right-btn"
            >
              {t("nav-bar-auth.btn-2")}
            </Button>
            {screens.sm === true ? null : (
              <Button
                className="btn-nav-menu"
                icon={<AiOutlineMenu />}
                onClick={showDrawer}
                size="large"
              />
            )}
          </div>
        ) : (
          <div className="nav-bar-auth-right">
            <Button
              type="primary"
              onClick={clickLogin}
              size="large"
              className="nav-bar-auth-right-btn"
            >
              {t("nav-bar-auth.btn-1")}
            </Button>
            <Button
              type="primary"
              onClick={clickRegister}
              size="large"
              className="nav-bar-auth-right-btn"
            >
              {t("nav-bar-auth.btn-2")}
            </Button>
            <Button
              className="btn-nav-menu"
              icon={<AiOutlineMenu />}
              onClick={showDrawer}
              size="large"
            />
          </div>
        )
      ) : null}
      {userAuth === null ? (
        screens.lg === true ? null : (
          <Drawer
            placement="right"
            onClose={onClose}
            open={open}
            className="drawer-auth"
            title={<Logo />}
          >
            <nav className="drawer-auth-link">
              {navlink.map((item, i) => (
                <NavLink
                  key={i}
                  to={item.to}
                  className={
                    item.to === location.pathname
                      ? "auth-navlink active-navlink"
                      : "auth-navlink"
                  }
                >
                  {item.text}
                </NavLink>
              ))}
              {screens.sm === true ? null : <Divider />}

              {screens.sm === true
                ? null
                : navlinkLoginAndRegister.map((item, i) => (
                    <NavLink
                      key={i}
                      to={item.to}
                      className={
                        item.to === location.pathname
                          ? "auth-navlink active-navlink"
                          : "auth-navlink"
                      }
                    >
                      {item.text}
                    </NavLink>
                  ))}
            </nav>
          </Drawer>
        )
      ) : null}
    </div>
  );
};

export default NavBarAuth;
