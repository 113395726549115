import { Button, Card, DatePicker, Table, Tooltip } from "antd";
import React from "react";
import { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { useUserAuth } from "../context/UserAuthContext";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";
import { useEffect } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const InvoicesTable = ({ title }) => {
  const [data, setData] = useState();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [loadingData, setLoadingData] = useState(false);
  const { user } = useUserAuth();
  const { t } = useTranslation();
  const columns = [
    {
      title: t("sections-invoicesTable.text-1"),
      dataIndex: "id",
      ellipsis: true,
      width: 80,
    },
    {
      title: t("sections-invoicesTable.text-2"),
      dataIndex: "date",
      width: 120,
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: t("sections-invoicesTable.text-3"),
      dataIndex: "amount",
      width: 120,
      render: (amount, all) => `€${parseFloat(amount).toFixed(2)}`,
      sorter: (a, b) => a.campaign.length - b.campaign.length,
    },
    {
      title: t("sections-invoicesTable.text-4"),
      dataIndex: "email",
      width: 200,
      sorter: (a, b) => a.treat.length - b.treat.length,
    },
    {
      title: t("sections-invoicesTable.text-5"),
      dataIndex: "type",
      ellipsis: true,
      width: 120,
      sorter: (a, b) => a.waiting.length - b.waiting.length,
    },
    {
      title: t("sections-invoicesTable.text-6"),
      dataIndex: "action",
      render: (id, all) => (
        <Tooltip placement="bottom" title={t("sections-invoicesTable.tooltip")}>
          <Button type="primary" ghost icon={<EyeOutlined />} />
        </Tooltip>
      ),
      fixed: "right",
      width: 60,
    },
  ];
  const getAllInvoices = useCallback(() => {
    setLoadingData(true);
    // Define Firestore queries to retrieve data
    const q = query(collection(db, "funds"), where("userId", "==", user.uid));

    // Listen for changes in the 'networks' collection and update dataNetworks array
    const unsubscribeFunds = onSnapshot(q, (querySnapshot) => {
      setData(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          amount: doc.data().fund,
          email: doc.data().email,
          type:
            doc.data().type === "deposit"
              ? t("sections-invoicesTable.text-7")
              : t("sections-invoicesTable.text-8"),
          date: moment(doc.data().dateCreated).format("L"),
        }))
      );
    });
    setLoadingData(false);

    // Return cleanup function to unsubscribe from Firestore listeners
    return () => {
      unsubscribeFunds();
    };
  }, [t, user.uid]);

  useEffect(() => {
    getAllInvoices();
  }, [getAllInvoices]);

  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = data.filter((record) =>
      record.date.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  return (
    <Card
      title={title}
      extra={
        <DatePicker
          onChange={(e) =>
            handleSearch(e ? moment(e.toDate()).format("L") : "")
          }
          size="large"
        />
      }
    >
      <Table
        scroll={{
          x: 986,
        }}
        loading={loadingData}
        columns={columns}
        dataSource={searchText === "" ? data : filteredData}
      />
    </Card>
  );
};

export default InvoicesTable;
