import { Button, Card, DatePicker, Space, Table, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import moment from "moment";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { join } from "array-join";
import { getCollectionAllData } from "../utils/getCollectionData";
import { CardUrl } from "../components";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useTranslation } from "react-i18next";

const MessageTableAdv = () => {
  const { user, lang } = useUserAuth();
  const [loadingData, setLoadingData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns = [
    {
      title: t("sections-message.text-1"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("sections-message.text-2"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t("sections-message.text-3"),
      dataIndex: "domainName",
      key: "domainName",
      render: (domainName, all) => (
        <Tooltip title={<CardUrl url={domainName} />}>
          <a href={domainName} target="_blank" rel="noopener noreferrer">
            {domainName}
          </a>
        </Tooltip>
      ),
    },
    {
      title: t("sections-message.text-4"),
      dataIndex: "status",
      key: "status",
    },
    {
      title: t("sections-message.text-5"),
      dataIndex: "actions",
      render: (id, all) => (
        <Space>
          <Tooltip placement="bottom" title={t("sections-message.text-8")}>
            <Button type="primary" danger icon={<CloseOutlined />} />
          </Tooltip>
          <Tooltip placement="bottom" title={t("sections-message.text-9")}>
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => onClickView(id, all.idOrder)}
            />
          </Tooltip>
        </Space>
      ),
      key: "actions",
    },
  ];

  const getAllMessages = useCallback(async () => {
    setLoadingData(true);
    if (user) {
      const [arrayMessages, arrayNetworks] = await Promise.all([
        getCollectionAllData("messages", "", "userAdv", user.uid),
        getCollectionAllData("networks", "", "", ""),
      ]);
      setData(
        join(
          arrayMessages.data.map((doc) => ({
            ...doc,
          })),
          arrayNetworks.data.map((doc) => ({
            idNetwork: doc.id,
            url: doc.url,
          })),
          (left) => left.idNetwork,
          (right) => right.idNetwork,
          (left, right) => ({ ...left, ...right })
        ).map((item) => ({
          id: item.id,
          idNetwork: item.idNetwork,
          domainName: item.url,
          status:
            item.statusPub === true
              ? t("sections-message.text-6")
              : t("sections-message.text-7"),
          date: moment(item.dateUpdated).format("L"),
          idOrder: item.idOrder,
          actions: item.id,
        }))
      );
      setLoadingData(false);
    }
  }, [t, user]);

  useEffect(() => {
    getAllMessages();
  }, [getAllMessages]);

  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = data.filter((record) =>
      record.date.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  const onClickView = async (id, idOrder) => {
    const messageRef = doc(db, "messages", id);
    await updateDoc(messageRef, {
      statusAdv: false,
    });
    navigate(`/${lang}/advertiser/me/messages/${idOrder}`);
  };
  return (
    <Card
      extra={
        <DatePicker
          onChange={(e) => handleSearch(moment(e.toDate()).format("L"))}
          size="large"
        />
      }
    >
      <Table
        columns={columns}
        loading={loadingData}
        scroll={{
          x: 896,
        }}
        dataSource={searchText === "" ? data : filteredData}
      />
    </Card>
  );
};

export default MessageTableAdv;
