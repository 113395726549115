import React, { useEffect, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { Avatar, Card, Select } from "antd";
import {
  BlogAndPressTable,
  FacebookTable,
  InstagramTable,
  SnapchatTable,
  TikTokTable,
  TwitterTable,
  YoutubeTable,
} from "../components";
import pressImg from "../assets/media/press.png";
import youtubeImg from "../assets/media/youtube.png";
import facebookImg from "../assets/media/facebook.svg";
import instagramImg from "../assets/media/instagram.png";
import snapchatImg from "../assets/media/snapchat.svg";
import twitterImg from "../assets/media/twitter.svg";
import tiktokImg from "../assets/media/tiktok.png";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getCollectionAllData } from "../utils/getCollectionData";

const ChooseNetwork = () => {
  const { user, cart, onIncreasingSteps } = useUserAuth();
  const [networkType, setNetworkType] = useState("");
  const [getMyDeposit, setGetMyDeposit] = useState(null);
  const { t } = useTranslation();

  //get my deposit
  const getDepositSize = useCallback(async () => {
    const deposit = await getCollectionAllData("funds", user.uid, "", "");
    setGetMyDeposit(deposit.size);
  }, [user.uid]);
  useEffect(() => {
    getDepositSize();
  }, [getDepositSize]);

  //data network types
  const options = [
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={pressImg} />
          <div>Blog/Press</div>
        </div>
      ),
      value: "press",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={youtubeImg} />
          <div>YouTube</div>
        </div>
      ),
      value: "youtube",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={facebookImg} />
          <div>Facebook</div>
        </div>
      ),
      value: "facebook",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={instagramImg} />
          <div>Instagram</div>
        </div>
      ),
      value: "instagram",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={twitterImg} />
          <div>Twitter</div>
        </div>
      ),
      value: "twitter",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={snapchatImg} />
          <div>Snapchat</div>
        </div>
      ),
      value: "snapchat",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <Avatar src={tiktokImg} />
          <div>TikTok</div>
        </div>
      ),
      value: "tiktok",
    },
  ];

  useEffect(() => {
    if (cart.length > 0) {
      onIncreasingSteps(1);
    }
  }, [cart.length, onIncreasingSteps]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "3rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          placeItems: "center",
        }}
      >
        <Card title={t("sections-chooseNetwork.title-1")}>
          <div
            style={{
              minWidth: 300,
              display: "flex",
              flexDirection: "column",
              gap: "3rem",
              margin: "1.5rem 0",
            }}
          >
            <Select
              placeholder={t("sections-chooseNetwork.title-1")}
              size="large"
              value={networkType}
              onChange={(e) => setNetworkType(e)}
              style={{ width: "100%" }}
            >
              {options.map((value, key) => (
                <Select.Option value={value.value} key={key}>
                  {value.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Card>
      </div>
      {networkType === "press" ? (
        <BlogAndPressTable
          networkType={networkType}
          getMyDeposit={getMyDeposit}
        />
      ) : networkType === "youtube" ? (
        <YoutubeTable networkType={networkType} getMyDeposit={getMyDeposit} />
      ) : networkType === "facebook" ? (
        <FacebookTable networkType={networkType} getMyDeposit={getMyDeposit} />
      ) : networkType === "instagram" ? (
        <InstagramTable networkType={networkType} getMyDeposit={getMyDeposit} />
      ) : networkType === "twitter" ? (
        <TwitterTable networkType={networkType} getMyDeposit={getMyDeposit} />
      ) : networkType === "snapchat" ? (
        <SnapchatTable networkType={networkType} getMyDeposit={getMyDeposit} />
      ) : (
        <TikTokTable networkType={networkType} getMyDeposit={getMyDeposit} />
      )}
    </div>
  );
};

export default ChooseNetwork;
