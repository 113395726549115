import {
  Button,
  Card,
  InputNumber,
  Typography,
  Divider,
  Form,
  Alert,
  Space,
} from "antd";
import React, { useCallback } from "react";
import { FaCcStripe, FaEdit } from "react-icons/fa";
import { MdEuro, MdOutlineAddShoppingCart } from "react-icons/md";
import { Page } from "../../components";
import "../../assets/styles/my-account.css";
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { InvoicesTable, StripeForm } from "../../sections";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import StripeImg from "../../assets/media/stripe.png";
import axios from "axios";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import { getCollectionAllData } from "../../utils/getCollectionData";
import { useNotification } from "../../utils/util-notification";

const MoneyDeposit = () => {
  const { t } = useTranslation();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [form] = Form.useForm();
  const { lang, user } = useUserAuth();
  const { notify } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://precious-puce-cheetah.cyclic.app/config")
      .then(async (response) => {
        const { publishableKey } = await response.data;
        setStripePromise(loadStripe(publishableKey));
      });
  }, []);

  useEffect(() => {
    if (clientSecret) {
      setStep(1);
    }
  }, [clientSecret]);

  useEffect(() => {
    form.setFieldsValue({
      amount: amount,
    });
  }, [amount, form]);

  const onFinish = useCallback(
    async (values) => {
      // Create PaymentIntent as soon as the page loads
      setLoading(true);
      const funds = await getCollectionAllData("funds", user.uid, "", "");
      if (funds.size > 0) {
        setAmount(values.amount);
        axios
          .post(
            "https://precious-puce-cheetah.cyclic.app/create-payment-intent",
            {
              amount: parseFloat(values.amount).toFixed(2),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (result) => {
            var { clientSecret } = await result.data;
            setClientSecret(clientSecret);
          });
      } else {
        if (parseFloat(values.amount).toFixed(2) >= 50) {
          setAmount(values.amount);
          axios
            .post(
              "https://precious-puce-cheetah.cyclic.app/create-payment-intent",
              {
                amount: parseFloat(values.amount).toFixed(2),
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(async (result) => {
              var { clientSecret } = await result.data;
              setClientSecret(clientSecret);
            });
        } else {
          notify(
            "error",
            t("view-account-moneydeposit.notify-2"),
            t("view-account-moneydeposit.notify-1")
          );
        }
      }

      setLoading(false);
    },
    [notify, t, user.uid]
  );

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Page title={t("view-account-moneydeposit.title-1")}>
      <div className="my-account">
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Title level={4}>
              {t("view-account-moneydeposit.title-2")}
            </Typography.Title>
            <Button
              type="primary"
              ghost
              icon={<MdOutlineAddShoppingCart style={{ marginRight: "8px" }} />}
              size="large"
              onClick={() => navigate(`/${lang}/advertiser/me/new/order`)}
            >
              {t("view-account-moneydeposit.btn-1")}
            </Button>
          </div>
        </Card>
        <div className="account-content">
          <Card
            title={
              <Typography.Title level={4}>
                {t("view-account-moneydeposit.title-4")}
              </Typography.Title>
            }
          >
            <Space direction="vertical" size="large">
              <img
                style={{ width: "100%", maxHeight: "150px" }}
                src={StripeImg}
                alt="Stripe"
              />
              <Alert
                type="info"
                message={t("view-account-moneydeposit.message")}
              />
            </Space>
          </Card>
          <Card
            title={
              step === 0 ? (
                <Typography.Title level={4}>
                  {t("view-account-moneydeposit.title-3")}
                </Typography.Title>
              ) : (
                <div style={{ display: "flex", gap: "2px" }}>
                  <FaCcStripe size="1.5rem" />
                  <Typography.Title level={4}>
                    {t("view-account-moneydeposit.label-1")}
                  </Typography.Title>
                </div>
              )
            }
          >
            {step === 0 ? (
              <Form layout="vertical" onFinish={onFinish} form={form}>
                <Divider>{t("view-account-moneydeposit.divider-1")}</Divider>
                <Form.Item
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: t("view-account-moneydeposit.rules"),
                    },
                  ]}
                >
                  <InputNumber
                    prefix={<MdEuro />}
                    placeholder="1,00"
                    step="0.1"
                    min={1.0}
                    required
                    decimalSeparator=","
                    style={{
                      width: "100%",
                    }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    size="large"
                    type="primary"
                    loading={loading}
                    style={{ width: "100%" }}
                  >
                    {t("view-account-moneydeposit.btn-2")}
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              clientSecret && (
                <Space
                  size="large"
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Button
                    icon={<FaEdit />}
                    onClick={() => setStep(0)}
                    size="large"
                    style={{ width: "100%" }}
                  >
                    {t("view-account-moneydeposit.btn-3")}: <b>{amount} €</b>
                  </Button>
                  <Divider>{t("view-account-moneydeposit.divider-2")}:</Divider>
                  <Elements options={options} stripe={stripePromise}>
                    <StripeForm amount={amount} />
                  </Elements>
                </Space>
              )
            )}
          </Card>
        </div>
        <InvoicesTable title={t("view-account-moneydeposit.invoice")} />
      </div>
    </Page>
  );
};

export default MoneyDeposit;
