import {
  Avatar,
  Badge,
  Button,
  Card,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FaAngleDown, FaFileInvoice } from "react-icons/fa";
import { BsFillCreditCardFill } from "react-icons/bs";
import { RiLogoutCircleFill } from "react-icons/ri";
import { HiUser } from "react-icons/hi";
import { FiDatabase } from "react-icons/fi";
import { useProSidebar } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { useTranslation } from "react-i18next";
import {
  ShoppingCartOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";
import Bowser from "bowser";
import pressImg from "../assets/media/press.png";
import youtubeImg from "../assets/media/youtube.png";
import facebookImg from "../assets/media/facebook.svg";
import instagramImg from "../assets/media/instagram.png";
import snapchatImg from "../assets/media/snapchat.svg";
import twitterImg from "../assets/media/twitter.svg";
import tiktokImg from "../assets/media/tiktok.png";

const NavBar = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [funds, setFunds] = useState([]);
  const [gain, setGain] = useState([]);
  const { toggleSidebar, broken } = useProSidebar();
  const {
    userAuth,
    logOut,
    user,
    lang,
    cart,
    removeLocalStorageAll,
    removeOneCart,
  } = useUserAuth();
  const browser = Bowser.getParser(window.navigator.userAgent);
  const navigate = useNavigate();

  const itemsPub = [
    {
      label: (
        <Link to={`/${lang}/publisher/me/login`}>
          {t("global-navbar.label-1")}
        </Link>
      ),
      icon: <HiUser />,
      key: "0",
    },
    {
      type: "divider",
    },

    {
      label: (
        <Link to={`/${lang}/publisher/me/withdrawal/methods`}>
          {t("global-navbar.label-2")}
        </Link>
      ),
      icon: <BsFillCreditCardFill />,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link to={`/${lang}/publisher/me/withdrawals/history`}>
          {t("global-navbar.label-3")}
        </Link>
      ),
      icon: <FaFileInvoice />,
      key: "2",
    },
    {
      type: "divider",
    },
    {
      label: <span onClick={logOut}>{t("global-navbar.label-4")}</span>,
      icon: <RiLogoutCircleFill />,
      key: "3",
    },
  ];
  const itemsAdv = [
    {
      label: (
        <Link to={`/${lang}/advertiser/me/login`}>
          {t("global-navbar.label-5")}
        </Link>
      ),
      icon: <HiUser />,
      key: "0",
    },

    {
      type: "divider",
    },
    {
      label: (
        <Link to={`/${lang}/advertiser/me/money`}>
          {t("global-navbar.label-6")}
        </Link>
      ),
      icon: <BsFillCreditCardFill />,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link to={`/${lang}/advertiser/me/invoices`}>
          {t("global-navbar.label-7")}
        </Link>
      ),
      icon: <FaFileInvoice />,
      key: "2",
    },
    {
      type: "divider",
    },
    {
      label: <span onClick={logOut}>{t("global-navbar.label-8")}</span>,
      icon: <RiLogoutCircleFill />,
      key: "3",
    },
  ];

  //Dropdown
  const items =
    user && userAuth && userAuth.accountType === "pub" ? itemsPub : itemsAdv;
  const getUserName = () => {
    const userName = userAuth && userAuth.firstName[0] + userAuth.lastName[0];
    return userName;
  };

  //drawer
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  // get fund
  useEffect(() => {
    if (user) {
      // Define Firestore queries to retrieve data
      const q = query(collection(db, "funds"), where("userId", "==", user.uid));

      // Listen for changes in the 'networks' collection and update dataNetworks array
      const unsubscribeFunds = onSnapshot(q, (querySnapshot) => {
        setFunds(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      });

      // Return cleanup function to unsubscribe from Firestore listeners
      return () => {
        unsubscribeFunds();
      };
    }
  }, [user]);

  // get gain
  useEffect(() => {
    if (user) {
      // Define Firestore queries to retrieve data
      const q = query(collection(db, "gain"), where("userId", "==", user.uid));

      // Listen for changes in the 'networks' collection and update dataNetworks array
      const unsubscribeFunds = onSnapshot(q, (querySnapshot) => {
        setGain(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      });

      // Return cleanup function to unsubscribe from Firestore listeners
      return () => {
        unsubscribeFunds();
      };
    }
  }, [user]);

  return (
    <Fragment>
      <div
        style={{
          padding: "16px",
          display: "flex",
          justifyContent: broken ? "space-between" : "end",
          backdropFilter: "blur(5px)",
          position: "fixed",
          zIndex: 99,
          width:
            browser.getBrowserName() === "Firefox"
              ? "-moz-available"
              : "-webkit-fill-available",
        }}
      >
        {broken && (
          <Button
            size="middle"
            onClick={() => toggleSidebar()}
            icon={<AiOutlineMenu />}
          />
        )}
        {userAuth && userAuth.accountType === "admin-rocket" ? (
          <Space style={{ cursor: "pointer" }}>
            {user?.photoURL ? (
              <Avatar src={user.photoURL} />
            ) : (
              <Avatar>{getUserName()}</Avatar>
            )}
            <FaAngleDown />
          </Space>
        ) : (
          <Space size="small">
            <div style={{ display: "flex", gap: "0.1rem" }}>
              <UserOutlined />
              <span>Support:</span>
              <a
                href="mailto:support@rocketfluence.com"
                style={{ textDecoration: "underline", color: "2C4E7A" }}
              >
                support@rocketfluence.com
              </a>
            </div>

            {user && userAuth && userAuth?.accountType === "pub" ? (
              <Button
                size="middle"
                icon={<FiDatabase style={{ marginRight: "4px" }} />}
              >
                {`${t("global-navbar.btn-1")} €${gain
                  .reduce(
                    (accumulator, currentItem) =>
                      parseFloat(accumulator) + parseFloat(currentItem.fund),
                    0
                  )
                  .toFixed(2)}`}
              </Button>
            ) : (
              <Button
                size="middle"
                icon={<FiDatabase style={{ marginRight: "4px" }} />}
              >
                {`€${funds
                  .reduce(
                    (accumulator, currentItem) =>
                      parseFloat(accumulator) + parseFloat(currentItem.fund),
                    0
                  )
                  .toFixed(2)}`}
              </Button>
            )}

            {user && userAuth.accountType === "pub" ? null : (
              <Badge count={cart?.length}>
                <Button
                  type="primary"
                  icon={<ShoppingCartOutlined />}
                  shape="circle"
                  onClick={() => showDrawer()}
                />
              </Badge>
            )}

            <Dropdown menu={{ items }} trigger={["click"]}>
              <Space style={{ cursor: "pointer" }}>
                {user?.photoURL ? (
                  <Avatar src={user.photoURL} />
                ) : (
                  <Avatar>{getUserName()}</Avatar>
                )}
                <FaAngleDown />
              </Space>
            </Dropdown>
          </Space>
        )}
      </div>
      <Drawer
        title={
          <Typography.Title level={5}>
            {t("global-navbar.text-1")} <Badge count={cart?.length} />
          </Typography.Title>
        }
        placement="right"
        onClose={onClose}
        open={open}
        footer={
          cart.length > 0 ? (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="primary"
                size="large"
                danger
                onClick={() => removeLocalStorageAll()}
              >
                {t("global-navbar.btn-4")}
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={() => navigate(`/${lang}/advertiser/me/new/order`)}
              >
                {t("global-navbar.btn-2")}
              </Button>
            </div>
          ) : null
        }
      >
        {cart.length > 0 ? (
          <div>
            {cart.map((item, key) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {item.networkType === "facebook" ? (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Card
                        bodyStyle={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Avatar src={facebookImg} />
                        <span>Facebook</span>
                      </Card>
                    </a>
                  ) : item.networkType === "twitter" ? (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Card
                        bodyStyle={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Avatar src={twitterImg} />
                        <span>Twitter</span>
                      </Card>
                    </a>
                  ) : item.networkType === "youtube" ? (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Card
                        bodyStyle={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Avatar src={youtubeImg} />
                        <span>Youtube</span>
                      </Card>
                    </a>
                  ) : item.networkType === "instagram" ? (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Card
                        bodyStyle={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Avatar src={instagramImg} />
                        <span>Instagram</span>
                      </Card>
                    </a>
                  ) : item.networkType === "tiktok" ? (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Card
                        bodyStyle={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Avatar src={tiktokImg} />
                        <span>TikTok</span>
                      </Card>
                    </a>
                  ) : item.networkType === "snapchat" ? (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Card
                        bodyStyle={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Avatar src={snapchatImg} />
                        <span>Snapchat</span>
                      </Card>
                    </a>
                  ) : (
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Card
                        bodyStyle={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <Avatar src={pressImg} />
                        <span>
                          {lang === "fr" ? "Blogue/Presse" : "Blog/Press"}
                        </span>
                      </Card>
                    </a>
                  )}
                  <b>€{item.price}</b>
                  <Tooltip title="Delete" placement="bottom">
                    <Button
                      danger
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={() => removeOneCart(item.id)}
                    />
                  </Tooltip>
                </div>
                <Divider />
              </div>
            ))}
            <Typography.Title style={{ textAlign: "end" }} level={4}>
              TOTAL:{" "}
              {`€${cart
                .reduce(
                  (accumulator, currentItem) =>
                    parseFloat(accumulator) + parseFloat(currentItem.price),
                  0
                )
                .toFixed(2)}`}
            </Typography.Title>
          </div>
        ) : (
          <div>
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={t("global-navbar.desc-1")}
            >
              <Button
                type="primary"
                size="large"
                onClick={() => navigate(`/${lang}/advertiser/me/new/order`)}
              >
                {t("global-navbar.btn-3")}
              </Button>
            </Empty>
          </div>
        )}
      </Drawer>
    </Fragment>
  );
};

export default NavBar;
