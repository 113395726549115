import React, { useCallback, useEffect, useState } from "react";
import { CardHeaderPub, Page } from "../../components";
import "../../assets/styles/my-account.css";
import { Button, Card, DatePicker, Table, Tag, Tooltip } from "antd";
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import { getCollectionAllSearchData } from "../../utils/getCollectionData";
import { useUserAuth } from "../../context/UserAuthContext";
import moment from "moment";
import { EyeOutlined } from "@ant-design/icons";

const { Meta } = Card;

const WithdrawalsHistory = () => {
  const { t } = useTranslation();
  const [dataSuccessGain, setDataSuccessGain] = useState(null);
  const [dataAllGain, setDataAllGain] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useUserAuth();
  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const columns = [
    {
      title: t("view-account-with-drawals-history.title-1"),
      dataIndex: "id",
      key: "id",
      width: 90,
      ellipsis: true,
    },
    {
      title: t("view-account-with-drawals-history.title-2"),
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 90,
      ellipsis: true,
    },
    {
      title: t("view-account-with-drawals-history.title-4"),
      dataIndex: "pDate",
      key: "pDate",
      sorter: (a, b) => a.pDate.length - b.pDate.length,
    },
    {
      title: t("view-account-with-drawals-history.title-6"),
      dataIndex: "amount",
      key: "amount",
      render: (amount, all) => `€${amount}`,
      sorter: (a, b) => a.amount.length - b.amount.length,
    },
    {
      title: t("view-account-with-drawals-history.title-9"),
      dataIndex: "status",
      key: "status",
      render: (status, all) =>
        status === "pending" ? (
          <Tag color="warning">
            {t("view-account-with-drawals-history.tag-2")}
          </Tag>
        ) : (
          <Tag color="success">
            {t("view-account-with-drawals-history.tag-1")}
          </Tag>
        ),
      filters: [
        {
          text: t("view-account-with-drawals-history.tag-1"),
          value: "success",
        },
        {
          text: t("view-account-with-drawals-history.tag-2"),
          value: "pending",
        },
      ],
      onFilter: (value, record) => record.status.startsWith(value),
    },
    {
      title: t("view-account-with-drawals-history.title-10"),
      dataIndex: "actions",
      key: "actions",
      render: (id, all) => (
        <Tooltip placement="bottomRight" title="View">
          <Button type="primary" icon={<EyeOutlined />} />
        </Tooltip>
      ),
      fixed: "right",
    },
  ];

  const getGain = useCallback(async () => {
    setLoading(true);
    const [successGain, allGain] = await Promise.all([
      getCollectionAllSearchData(
        "gain",
        "userId",
        user.uid,
        "status",
        "success"
      ),
      getCollectionAllSearchData("gain", "userId", user.uid, "", ""),
    ]);
    setDataAllGain(allGain.data);
    setDataSuccessGain(successGain.data);
    setData(
      allGain.data.map((doc) => ({
        id: doc.id,
        invoiceId: doc.idOrder,
        pDate: moment(doc.dateUpdated).format("L"),
        amount: doc.fund,
        paymentMethod: doc.paymentMethod,
        status: doc.status,
        idCampaign: doc.idCampaign,
        idNetwork: doc.idNetwork,
        actions: doc.id,
      }))
    );
    setLoading(false);
  }, [user.uid]);

  useEffect(() => {
    getGain();
  }, [getGain]);

  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = data.filter((record) =>
      record.aDate.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };

  return (
    <Page title={t("view-account-with-drawals-history.title-11")}>
      <div className="my-account">
        <CardHeaderPub
          title={t("view-account-with-drawals-history.title-12")}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <Card style={{ minWidth: "150px" }}>
            <Meta
              avatar={<GiReceiveMoney color="#00a" size="40px" />}
              title={t("view-account-with-drawals-history.title-13")}
              description={`€${
                dataSuccessGain &&
                dataSuccessGain
                  .reduce(
                    (accumulator, currentItem) =>
                      parseFloat(accumulator) + parseFloat(currentItem.fund),
                    0
                  )
                  .toFixed(2)
              }`}
            />
          </Card>
          <Card style={{ minWidth: "150px" }}>
            <Meta
              avatar={<GiTakeMyMoney color="#18a" size="40px" />}
              title={t("view-account-with-drawals-history.title-14")}
              description={`€${
                dataAllGain &&
                dataAllGain
                  .reduce(
                    (accumulator, currentItem) =>
                      parseFloat(accumulator) + parseFloat(currentItem.fund),
                    0
                  )
                  .toFixed(2)
              }`}
            />
          </Card>
        </div>
        <Card
          extra={
            <DatePicker
              onChange={(e) =>
                handleSearch(e ? moment(e.toDate()).format("L") : "")
              }
              size="large"
            />
          }
        >
          <Table
            scroll={{
              x: 986,
            }}
            loading={loading}
            columns={columns}
            dataSource={searchText === "" ? data : filteredData}
          />
        </Card>
      </div>
    </Page>
  );
};

export default WithdrawalsHistory;
