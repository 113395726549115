import React from "react";
import { Page } from "../components";
import { NavBarAuth } from "../global";
import "../assets/styles/nav-bar-auth.css";

const CheckEmail = () => {
  return (
    <Page title="Check your email">
      <div className="auth">
        <NavBarAuth />
        <div className="auth-content">Hello</div>
      </div>
    </Page>
  );
};

export default CheckEmail;
