import React from "react";
import Routes from "./routes";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { frFR, enUS } from "@mui/material/locale";
import { useUserAuth } from "./context/UserAuthContext";
import { ChatWidget } from "./components";

const App = () => {
  const theme = useTheme();
  const { lang, userAuth } = useUserAuth();
  //init ios scroll
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 600,
      easing: "linear",
      delay: 200,
    });
  }, []);

  return (
    <ThemeProvider theme={createTheme(theme, lang === "fr" ? frFR : enUS)}>
      <Routes />
      {userAuth && userAuth.accountType === "admin-rocket" ? null : (
        <ChatWidget />
      )}
    </ThemeProvider>
  );
};

export default App;
