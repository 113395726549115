import { Badge, Button, Card, Input, Select, Tabs, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { MdOutlineAddShoppingCart } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { Page } from "../../components";
import { useUserAuth } from "../../context/UserAuthContext";
import {
  AllTableAdv,
  CompletedTableAdv,
  DeclinedTableAdv,
  ProgressTableAdv,
  SetAsideTableAdv,
  TreatTableAdv,
  WaitingTableAdv,
} from "../../sections";
import {
  getCollectionAllData,
  getCollectionAllSearchData,
} from "../../utils/getCollectionData";
import "../../assets/styles/dashboard.css";
import { useTranslation } from "react-i18next";

const MyMissions = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const key = location.pathname.split("/")[5];
  const [idCampaign, setIdCampaign] = useState("");
  const [searchText, setSearchText] = useState("");
  const [treat, setTreat] = useState("0");
  const [waiting, setWaiting] = useState("0");
  const [progress, setProgress] = useState("0");
  const [completed, setCompleted] = useState("0");
  const [refused, setRefused] = useState("0");
  const [aside, setAside] = useState("0");
  const [all, setAll] = useState("0");
  const { user, lang } = useUserAuth();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCollectionAllData(
        "campaigns",
        user.uid,
        "",
        ""
      );
      setOptions(response?.data);
    };
    fetchData();
  }, [user.uid]);

  const getMyMission = useCallback(() => {
    // Call the function to retrieve the data and size
    getCollectionAllSearchData(
      "orders",
      "userAdv",
      user.uid,
      "idCampaign",
      idCampaign
    ).then((response) => {
      setAll(response.size);
    });
    getCollectionAllSearchData(
      "completed",
      "userAdv",
      user.uid,
      "idCampaign",
      idCampaign
    ).then((response) => {
      setCompleted(response.size);
    });
    getCollectionAllSearchData(
      "declined",
      "userAdv",
      user.uid,
      "idCampaign",
      idCampaign
    ).then((response) => {
      setRefused(response.size);
    });
    getCollectionAllSearchData(
      "progress",
      "userAdv",
      user.uid,
      "idCampaign",
      idCampaign
    ).then((response) => {
      setProgress(response.size);
    });
    getCollectionAllSearchData(
      "aside",
      "userAdv",
      user.uid,
      "idCampaign",
      idCampaign
    ).then((response) => {
      setAside(response.size);
    });
    getCollectionAllSearchData(
      "treat",
      "userAdv",
      user.uid,
      "idCampaign",
      idCampaign
    ).then((response) => {
      setTreat(response.size);
    });
    getCollectionAllSearchData(
      "waiting",
      "userAdv",
      user.uid,
      "idCampaign",
      idCampaign
    ).then((response) => {
      setWaiting(response.size);
    });
  }, [idCampaign, user.uid]);
  useEffect(() => {
    getMyMission();
  }, [getMyMission]);

  const items = [
    {
      key: "1",
      label: (
        <Badge count={all} size="small" showZero color="#2C4E7A">
          {t("view-advertisers-myMissions.label-1")}
        </Badge>
      ),
      children: <AllTableAdv idCampaign={idCampaign} searchText={searchText} />,
    },
    {
      key: "2",
      label: (
        <Badge count={waiting} size="small" showZero color="#FCAE7A">
          {t("view-advertisers-myMissions.label-2")}
        </Badge>
      ),
      children: (
        <WaitingTableAdv idCampaign={idCampaign} searchText={searchText} />
      ),
    },
    {
      key: "3",
      label: (
        <Badge size="small" count={aside} showZero color="#401717">
          {t("view-advertisers-myMissions.label-3")}
        </Badge>
      ),
      children: (
        <SetAsideTableAdv idCampaign={idCampaign} searchText={searchText} />
      ),
    },
    {
      key: "4",
      label: (
        <Badge count={treat} size="small" showZero color="#08f">
          {t("view-advertisers-myMissions.label-4")}
        </Badge>
      ),
      children: (
        <TreatTableAdv idCampaign={idCampaign} searchText={searchText} />
      ),
    },

    {
      key: "5",
      label: (
        <Badge size="small" count={progress} showZero color="#f80">
          {t("view-advertisers-myMissions.label-5")}
        </Badge>
      ),
      children: (
        <ProgressTableAdv idCampaign={idCampaign} searchText={searchText} />
      ),
    },

    {
      key: "6",
      label: (
        <Badge size="small" count={completed} showZero color="#1C8">
          {t("view-advertisers-myMissions.label-6")}
        </Badge>
      ),
      children: (
        <CompletedTableAdv idCampaign={idCampaign} searchText={searchText} />
      ),
    },
    {
      key: "7",
      label: (
        <Badge size="small" count={refused} showZero color="#f00">
          {t("view-advertisers-myMissions.label-7")}
        </Badge>
      ),
      children: (
        <DeclinedTableAdv idCampaign={idCampaign} searchText={searchText} />
      ),
    },
  ];
  return (
    <Page title={t("view-advertisers-myMissions.title-1")}>
      <div className="dashboard">
        <Card
          title={t("view-advertisers-myMissions.card-title")}
          extra={
            <Button
              type="primary"
              ghost
              icon={<MdOutlineAddShoppingCart style={{ marginRight: "8px" }} />}
              size="large"
              onClick={() => navigate(`/${lang}/advertiser/me/new/order`)}
            >
              {t("view-advertisers-myMissions.btn")}
            </Button>
          }
        >
          <div className="dashboard-select">
            <Typography.Title level={5}>
              {t("view-advertisers-myMissions.typography-title")}
            </Typography.Title>
            <Select
              style={{
                width: 240,
              }}
              size="large"
              defaultValue=""
              onChange={(e) => setIdCampaign(e)}
              placeholder={t("view-advertisers-myMissions.placeholder-1")}
            >
              <Select.Option value="">
                {t("view-advertisers-myMissions.select-title")}
              </Select.Option>
              {options &&
                options?.map((item, key) => (
                  <Select.Option value={item.id} key={key}>
                    {item.campaignName}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </Card>
        <Card>
          <Tabs
            defaultActiveKey={key}
            items={items}
            tabBarExtraContent={
              <Input.Search
                size="large"
                placeholder={t("view-advertisers-myMissions.placeholder-2")}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            }
          />
        </Card>
      </div>
    </Page>
  );
};

export default MyMissions;
