import React from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { useTranslation } from "react-i18next";
import { SuperSEO } from "react-super-seo";

const Page = ({ title, metaName, metaContent, children, ...props }) => {
  const { t } = useTranslation();
  const { lang } = useUserAuth();
  return (
    <div>
      <SuperSEO
        title={`${title} | Rocketfluence`}
        description={metaContent ? metaContent : t("view-home.metaContent")}
        lang={lang}
        {...props}
        openGraph={{
          ogImage: {
            ogImage:
              "https://www.rocketfluence.com/static/media/hero.3881f98d22548c5d6816.png",
            ogImageAlt: "Rocketfluence",
            ogImageWidth: 1200,
            ogImageHeight: 630,
            ogImageType: "image/jpeg",
          },
        }}
        twitter={{
          twitterSummaryCard: {
            summaryCardImage:
              "https://www.rocketfluence.com/static/media/hero.3881f98d22548c5d6816.png",
            summaryCardImageAlt: "Rocketfluence",
            summaryCardSiteUsername: "Rocketfluence",
          },
        }}
      />
      {children}
    </div>
  );
};

export default Page;
