import { EmailContentOrder } from "../components";
import { render } from "@react-email/render";

export const sendEmail = async (
  lang,
  orderId,
  email,
  userTo,
  userFrom,
  contentText,
  price,
  networkType
) => {
  let dataSend = {
    email: email,
    subject: lang === "fr" ? "NOUVELLE COMMANDE" : "NEW ORDER",
    html: render(
      <EmailContentOrder
        lang={lang}
        idOrder={orderId}
        userTo={userTo}
        userFrom={userFrom}
        contentText={contentText}
        price={price}
        typeNetwork={networkType}
      />
    ),
  };
  await fetch(`https://precious-puce-cheetah.cyclic.app/send-mail`, {
    method: "POST",
    body: JSON.stringify(dataSend),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
