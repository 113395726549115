import React from "react";
import "../assets/styles/admin-messages.css";
import { Typography } from "antd";
import { IoMdSend } from "react-icons/io";

const AdminMessages = ({ id, userName }) => {
  return (
    <div className="admin-messages">
      <div className="admin-messages__header">
        <Typography.Title level={4}>{userName}</Typography.Title>
      </div>
      <div className="admin-messages__body">
        <div className="admin-messages__body-message">
          <div className="admin-messages__body-text">Message</div>
          <div className="admin-messages__body-date">date</div>
        </div>
      </div>
      <form className="chat-form">
        <input type="text" />
        <button type="submit">
          <IoMdSend />
        </button>
      </form>
    </div>
  );
};

export default AdminMessages;
