import { Alert, Button, Card, Select, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { BsCartPlus } from "react-icons/bs";
import {
  FcDatabase,
  FcClock,
  FcSynchronize,
  FcApproval,
  FcCancel,
} from "react-icons/fc";
import { FaPause, FaEdit } from "react-icons/fa";
import { Loader, Page } from "../../components";
import "../../assets/styles/dashboard.css";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import {
  getCollectionAllData,
  getCollectionById,
} from "../../utils/getCollectionData";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { userAuth, user, lang } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [treat, setTreat] = useState("0");
  const [waiting, setWaiting] = useState("0");
  const [progress, setProgress] = useState("0");
  const [completed, setCompleted] = useState("0");
  const [refused, setRefused] = useState("0");
  const [aside, setAside] = useState("0");
  const [all, setAll] = useState("0");
  const [campaignName, setCampaignName] = useState("");
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCollectionAllData(
        "campaigns",
        user.uid,
        "",
        ""
      );
      setOptions(response?.data);
    };
    fetchData();
  }, [user.uid]);

  const getCountOrders = async (selectedCampaignId) => {
    if (selectedCampaignId !== "") {
      setLoading(true);
      const response = await getCollectionById("campaigns", selectedCampaignId);

      if (response.exists()) {
        const { campaignName } = response;
        const [completed, declined, progress, aside, treat, waiting, orders] =
          await Promise.all([
            getCollectionAllData(
              "completed",
              "",
              "idCampaign",
              selectedCampaignId
            ),
            getCollectionAllData(
              "declined",
              "",
              "idCampaign",
              selectedCampaignId
            ),
            getCollectionAllData(
              "progress",
              "",
              "idCampaign",
              selectedCampaignId
            ),
            getCollectionAllData("aside", "", "idCampaign", selectedCampaignId),
            getCollectionAllData("treat", "", "idCampaign", selectedCampaignId),
            getCollectionAllData(
              "waiting",
              "",
              "idCampaign",
              selectedCampaignId
            ),
            getCollectionAllData(
              "orders",
              "",
              "idCampaign",
              selectedCampaignId
            ),
          ]);

        setCompleted(`${completed?.size}`);
        setRefused(`${declined?.size}`);
        setProgress(`${progress?.size}`);
        setAside(`${aside?.size}`);
        setTreat(`${treat?.size}`);
        setWaiting(`${waiting?.size}`);
        setAll(`${orders?.size}`);
        setCampaignName(campaignName);
        setLoading(false);
      }
    } else {
      getAllCount();
    }
  };

  const getAllCount = useCallback(async () => {
    setLoading(true);
    const [completed, declined, progress, aside, treat, waiting, orders] =
      await Promise.all([
        getCollectionAllData("completed", "", "userAdv", user.uid),
        getCollectionAllData("declined", "", "userAdv", user.uid),
        getCollectionAllData("progress", "", "userAdv", user.uid),
        getCollectionAllData("aside", "", "userAdv", user.uid),
        getCollectionAllData("treat", "", "userAdv", user.uid),
        getCollectionAllData("waiting", "", "userAdv", user.uid),
        getCollectionAllData("orders", "", "userAdv", user.uid),
      ]);
    setCompleted(`${completed?.size}`);
    setRefused(`${declined?.size}`);
    setProgress(`${progress?.size}`);
    setAside(`${aside?.size}`);
    setTreat(`${treat?.size}`);
    setWaiting(`${waiting?.size}`);
    setAll(`${orders?.size}`);
    setCampaignName(t("view-advertisers-dashboard.Select-option-text"));
    setLoading(false);
  }, [t, user.uid]);
  useEffect(() => {
    getAllCount();
  }, [getAllCount]);

  return (
    <Page title={t("view-advertisers-dashboard.title-1")}>
      <div className="dashboard">
        <Alert
          message={`${t("view-advertisers-dashboard.alert-message-1")} ${
            userAuth?.firstName
          } ${userAuth?.lastName}`}
          description={t("view-advertisers-dashboard.description-1")}
          type="success"
          closable
        />
        <Card
          title={t("view-advertisers-dashboard.title-2")}
          extra={
            <Button
              type="primary"
              ghost
              icon={<BsCartPlus style={{ marginRight: "8px" }} />}
              onClick={() => navigate(`/${lang}/advertiser/me/new/order`)}
              size="large"
            >
              {t("view-advertisers-dashboard.btn-1")}
            </Button>
          }
        >
          <div className="dashboard-select">
            <Typography.Title level={5}>
              {t("view-advertisers-dashboard.typography-title-1")}
            </Typography.Title>
            <Select
              style={{
                width: 240,
              }}
              size="large"
              defaultValue=""
              onChange={(value) => getCountOrders(value)}
              placeholder={t("view-advertisers-dashboard.placeholder")}
            >
              <Select.Option value="">
                {t("view-advertisers-dashboard.Select-option-text")}
              </Select.Option>
              {options.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.campaignName}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Card>
        {loading ? (
          <Loader>
            <div className="dashboard-static">
              <Card
                actions={[campaignName]}
                hoverable
                onClick={() => navigate(`/${lang}/advertiser/me/missions/1`)}
              >
                <Card.Meta
                  avatar={<FcDatabase size="50px" />}
                  title={t("view-advertisers-dashboard.title-3")}
                  description={all}
                />
              </Card>
              <Card
                actions={[campaignName]}
                hoverable
                onClick={() => navigate(`/${lang}/advertiser/me/missions/2`)}
              >
                <Card.Meta
                  avatar={<FcClock size="50px" />}
                  title={t("view-advertisers-dashboard.title-4")}
                  description={waiting}
                />
              </Card>
              <Card
                actions={[campaignName]}
                hoverable
                onClick={() => navigate(`/${lang}/advertiser/me/missions/3`)}
              >
                <Card.Meta
                  avatar={<FaPause size="40px" />}
                  title={t("view-advertisers-dashboard.title-5")}
                  description={aside}
                />
              </Card>
              <Card
                actions={[campaignName]}
                hoverable
                onClick={() => navigate(`/${lang}/advertiser/me/missions/4`)}
              >
                <Card.Meta
                  avatar={<FaEdit color="#00a" size="40px" />}
                  title={t("view-advertisers-dashboard.title-6")}
                  description={treat}
                />
              </Card>
              <Card
                actions={[campaignName]}
                hoverable
                onClick={() => navigate(`/${lang}/advertiser/me/missions/5`)}
              >
                <Card.Meta
                  avatar={<FcSynchronize size="50px" />}
                  title={t("view-advertisers-dashboard.title-7")}
                  description={progress}
                />
              </Card>
              <Card
                actions={[campaignName]}
                hoverable
                onClick={() => navigate(`/${lang}/advertiser/me/missions/6`)}
              >
                <Card.Meta
                  avatar={<FcApproval size="50px" />}
                  title={t("view-advertisers-dashboard.title-8")}
                  description={completed}
                />
              </Card>
              <Card
                actions={[campaignName]}
                hoverable
                onClick={() => navigate(`/${lang}/advertiser/me/missions/7`)}
              >
                <Card.Meta
                  avatar={<FcCancel size="50px" />}
                  title={t("view-advertisers-dashboard.title-9")}
                  description={refused}
                />
              </Card>
            </div>
          </Loader>
        ) : (
          <div className="dashboard-static">
            <Card
              actions={[campaignName]}
              hoverable
              onClick={() => navigate(`/${lang}/advertiser/me/missions/1`)}
            >
              <Card.Meta
                avatar={<FcDatabase size="50px" />}
                title={t("view-advertisers-dashboard.title-10")}
                description={all}
              />
            </Card>
            <Card
              actions={[campaignName]}
              hoverable
              onClick={() => navigate(`/${lang}/advertiser/me/missions/2`)}
            >
              <Card.Meta
                avatar={<FcClock size="50px" />}
                title={t("view-advertisers-dashboard.title-11")}
                description={waiting}
              />
            </Card>
            <Card
              actions={[campaignName]}
              hoverable
              onClick={() => navigate(`/${lang}/advertiser/me/missions/3`)}
            >
              <Card.Meta
                avatar={<FaPause size="40px" />}
                title={t("view-advertisers-dashboard.title-12")}
                description={aside}
              />
            </Card>
            <Card
              actions={[campaignName]}
              hoverable
              onClick={() => navigate(`/${lang}/advertiser/me/missions/4`)}
            >
              <Card.Meta
                avatar={<FaEdit color="#00a" size="40px" />}
                title={t("view-advertisers-dashboard.title-13")}
                description={treat}
              />
            </Card>
            <Card
              actions={[campaignName]}
              hoverable
              onClick={() => navigate(`/${lang}/advertiser/me/missions/5`)}
            >
              <Card.Meta
                avatar={<FcSynchronize size="50px" />}
                title={t("view-advertisers-dashboard.title-14")}
                description={progress}
              />
            </Card>
            <Card
              actions={[campaignName]}
              hoverable
              onClick={() => navigate(`/${lang}/advertiser/me/missions/6`)}
            >
              <Card.Meta
                avatar={<FcApproval size="50px" />}
                title={t("view-advertisers-dashboard.title-15")}
                description={completed}
              />
            </Card>
            <Card
              actions={[campaignName]}
              hoverable
              onClick={() => navigate(`/${lang}/advertiser/me/missions/7`)}
            >
              <Card.Meta
                avatar={<FcCancel size="50px" />}
                title={t("view-advertisers-dashboard.title-16")}
                description={refused}
              />
            </Card>
          </div>
        )}
      </div>
    </Page>
  );
};

export default Dashboard;
