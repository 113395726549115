import { join } from "array-join";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { db } from "../firebase";
import moment from "moment";
import youtubeImg from "../assets/media/youtube.png";
import facebookImg from "../assets/media/facebook.svg";
import instagramImg from "../assets/media/instagram.png";
import snapchatImg from "../assets/media/snapchat.svg";
import twitterImg from "../assets/media/twitter.svg";
import tiktokImg from "../assets/media/tiktok.png";
import pressImg from "../assets/media/press.png";
import { Country } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { Avatar, Button, Table, Tag, Tooltip } from "antd";
import { CardUrl } from "../components";
import { EyeOutlined, MessageOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const WaitingTableAdv = ({ idCampaign, searchText }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const { lang } = useUserAuth();
  const { t } = useTranslation();

  const getFlag = (country) => {
    const val = Country.getAllCountries().find((item) => item.name === country);
    return val.flag;
  };

  const columns = [
    {
      title: t("sections-tableAdv.text-1"),
      dataIndex: "id",
      ellipsis: true,
      width: 80,
      key: "id",
    },
    {
      title: t("sections-tableAdv.text-2"),
      dataIndex: "campaign",
      width: 100,
      key: "campaign",
      sorter: (a, b) => a.campaign.length - b.campaign.length,
    },
    {
      title: t("sections-tableAdv.text-3"),
      dataIndex: "date",
      width: 100,
      sorter: (a, b) => a.date.length - b.date.length,
      key: "date",
    },
    {
      title: t("sections-tableAdv.text-4"),
      dataIndex: "network",
      width: 100,
      render: (network, all) => (
        <Tooltip placement="right" title={<CardUrl url={network} />}>
          <a href={network} target="_blank" rel="noopener noreferrer">
            <div>{getFlag(all.country)}</div>
            <div>{network}</div>
          </a>
        </Tooltip>
      ),
      sorter: (a, b) => a.network.length - b.network.length,
      key: "network",
    },
    {
      title: t("sections-tableAdv.text-5"),
      dataIndex: "price",
      width: 100,
      render: (price, all) => `€${parseFloat(price).toFixed(2)}`,
      sorter: (a, b) => a.price.length - b.price.length,
      key: "price",
    },
    {
      title: <div>{t("sections-tableAdv.text-6")}</div>,
      dataIndex: "networkType",
      render: (networkType, all) =>
        networkType === "facebook" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={facebookImg} />
          </a>
        ) : networkType === "twitter" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={twitterImg} />
          </a>
        ) : networkType === "youtube" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={youtubeImg} />
          </a>
        ) : networkType === "instagram" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={instagramImg} />
          </a>
        ) : networkType === "tiktok" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={tiktokImg} />
          </a>
        ) : networkType === "snapchat" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={snapchatImg} />
          </a>
        ) : (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={pressImg} />
          </a>
        ),
      width: 120,
      key: "networkType",
    },
    {
      title: t("sections-tableAdv.text-7"),
      dataIndex: "dateAccept",
      width: 100,
      ellipsis: true,
      sorter: (a, b) => a.dateAccept.length - b.dateAccept.length,
      key: "dateAccept",
    },
    {
      title: t("sections-tableAdv.text-8"),
      dataIndex: "publication",
      width: 100,
      ellipsis: true,
      sorter: (a, b) => a.publication.length - b.publication.length,
      key: "publication",
    },
    {
      title: t("sections-tableAdv.text-9"),
      dataIndex: "status",
      render: (status, all) =>
        status === "completed" ? (
          <Tag color="success">Completed</Tag>
        ) : status === "declined" ? (
          <Tag color="error">Declined</Tag>
        ) : null,
      width: 100,
      sorter: (a, b) => a.status.length - b.status.length,
      key: "status",
    },
    {
      title: t("sections-tableAdv.text-10"),
      dataIndex: "actions",
      fixed: "right",
      render: (id, all) => (
        <div style={{ display: "flex", gap: "2px" }}>
          <Tooltip placement="bottom" title={t("sections-tableAdv.tooltip-1")}>
            <Button
              type="primary"
              ghost
              icon={<EyeOutlined />}
              onClick={(e) => navigate(`/${lang}/advertiser/me/order/${id}`)}
            />
          </Tooltip>

          <Tooltip placement="bottom" title={t("sections-tableAdv.tooltip-4")}>
            <Button
              icon={<MessageOutlined />}
              onClick={(e) => navigate(`/${lang}/advertiser/me/messages/${id}`)}
            />
          </Tooltip>
        </div>
      ),
      width: 120,
    },
  ];
  const getAllOrders = useCallback(async () => {
    setLoadingData(true);
    const q1 = query(
      collection(db, "orders"),
      where("userAdv", "==", user.uid)
    );
    const q1i = query(
      collection(db, "orders"),
      where("userAdv", "==", user.uid),
      where("idCampaign", "==", idCampaign)
    );

    const q2 = query(
      collection(db, "waiting"),
      where("userAdv", "==", user.uid)
    );

    const get1 = await getDocs(idCampaign === "" ? q1 : q1i);
    const get2 = await getDocs(q2);

    const orders = join(
      get2.docs.map((doc) => ({
        idWaiting: doc.id,
        idOrder: doc.data().idOrder,
      })),
      get1.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      (left) => left.idOrder,
      (right) => right.id,
      (left, right) => ({ ...left, ...right })
    );

    setData(
      orders &&
        orders.map((order) => ({
          id: order.id,
          campaign: order.campaignName,
          date: moment(order.dateCreated).format("L"),
          network: order.url,
          price: order.priceOfAdv,
          networkType: order.networkType,
          dateAccept: order.dateAccept
            ? moment(order.dateAccept).format("L")
            : null,
          deliveryDate: order.deliveryDate,
          publication: order.publication
            ? moment(order.publication).format("L")
            : null,
          country: order.country,
          status: order.status,
          actions: order.id,
          idWaiting: order.idWaiting,
          idCampaign: order.idCampaign,
          idNetwork: order.idNetwork,
        }))
    );

    setLoadingData(false);
  }, [idCampaign, user.uid]);

  useEffect(() => {
    getAllOrders();
  }, [getAllOrders]);

  const handleSearch = useCallback(() => {
    const filteredData =
      data &&
      data.filter(
        (record) =>
          record.campaign.toLowerCase().includes(searchText.toLowerCase()) ||
          record.network.toLowerCase().includes(searchText.toLowerCase())
      );
    setFilteredData(filteredData);
  }, [data, searchText]);
  useEffect(() => {
    handleSearch();
  }, [handleSearch]);
  return (
    <Table
      loading={loadingData}
      scroll={{
        x: 986,
      }}
      columns={columns}
      dataSource={searchText === "" ? data : filteredData}
    />
  );
};

export default WaitingTableAdv;
