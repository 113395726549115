import React from "react";
import "../assets/styles/logo.css";
import LogoApp from "../assets/media/logo.png";

const Logo = () => {
  return (
    <div className='logo'>
      <img src={LogoApp} alt='logo' width='100%' height='100%' />
    </div>
  );
};
export default Logo;
