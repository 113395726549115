import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Modal,
  Table,
  Tooltip,
  Typography,
} from "antd";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { MdOutlineAddShoppingCart, MdAddCircleOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Page } from "../../components";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNotification } from "../../utils/util-notification";
import moment from "moment";
import {
  CloseOutlined,
  EditFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  getCollectionAllData,
  getCollectionAllSearchData,
  getCollectionById,
} from "../../utils/getCollectionData";
import "../../assets/styles/dashboard.css";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

const MyCampaigns = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { user, lang } = useUserAuth();
  const { notify } = useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [idCampaign, setIdCampaign] = useState(null);
  const [data, setData] = useState();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("view-advertisers-myCampaigns.title-1"),
      dataIndex: "id",
      ellipsis: true,
      width: 80,
    },
    {
      title: t("view-advertisers-myCampaigns.title-2"),
      dataIndex: "date",
      width: 120,
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: t("view-advertisers-myCampaigns.title-3"),
      dataIndex: "campaign",
      width: 120,
      sorter: (a, b) => a.campaign.length - b.campaign.length,
    },
    {
      title: t("view-advertisers-myCampaigns.title-4"),
      dataIndex: "treat",
      ellipsis: true,
      width: 80,
      sorter: (a, b) => a.treat.length - b.treat.length,
    },
    {
      title: t("view-advertisers-myCampaigns.title-5"),
      dataIndex: "waiting",
      ellipsis: true,
      width: 80,
      sorter: (a, b) => a.waiting.length - b.waiting.length,
    },
    {
      title: t("view-advertisers-myCampaigns.title-5-1"),
      dataIndex: "aside",
      ellipsis: true,
      width: 80,
      sorter: (a, b) => a.aside.length - b.aside.length,
    },
    {
      title: t("view-advertisers-myCampaigns.title-6"),
      dataIndex: "progress",
      ellipsis: true,
      width: 80,
      sorter: (a, b) => a.progress.length - b.progress.length,
    },
    {
      title: t("view-advertisers-myCampaigns.title-7"),
      dataIndex: "completed",
      ellipsis: true,
      width: 80,
      sorter: (a, b) => a.completed.length - b.completed.length,
    },
    {
      title: t("view-advertisers-myCampaigns.title-8"),
      dataIndex: "refused",
      ellipsis: true,
      width: 80,
      sorter: (a, b) => a.refused.length - b.refused.length,
    },
    {
      title: t("view-advertisers-myCampaigns.title-9"),
      dataIndex: "action",
      render: (id, all) => (
        <div style={{ display: "flex", gap: "2px" }}>
          {/* <Tooltip
            placement="bottom"
            title={t("view-advertisers-myCampaigns.title-10")}
          >
            <Button type="primary" ghost icon={<EyeOutlined />} />
          </Tooltip> */}
          <Tooltip
            placement="bottom"
            title={t("view-advertisers-myCampaigns.title-11")}
          >
            <Button
              type="primary"
              onClick={() => getCampaign(id)}
              icon={<EditFilled />}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={t("view-advertisers-myCampaigns.title-12")}
          >
            <Button
              type="primary"
              danger
              onClick={() => handleDeleteCampaign(id)}
              icon={<CloseOutlined />}
            />
          </Tooltip>
        </div>
      ),
      fixed: "right",
      width: 120,
    },
  ];

  //get all campaign

  const getAllCampaigns = useCallback(async () => {
    setLoadingData(true);
    const response = await getCollectionAllData("campaigns", user.uid, "", "");
    const campaigns = await Promise.all(
      response?.data.map(async (doc) => {
        const treatCount = await getCollectionAllSearchData(
          "treat",
          "userAdv",
          user.uid,
          "idCampaign",
          doc.id
        )
          .then((response) => `${response.size}`)
          .catch((error) => "0");
        const waitingCount = await getCollectionAllSearchData(
          "waiting",
          "userAdv",
          user.uid,
          "idCampaign",
          doc.id
        )
          .then((response) => `${response.size}`)
          .catch((error) => "0");
        const asideCount = await getCollectionAllSearchData(
          "aside",
          "userAdv",
          user.uid,
          "idCampaign",
          doc.id
        )
          .then((response) => `${response.size}`)
          .catch((error) => "0");
        const progressCount = await getCollectionAllSearchData(
          "progress",
          "userAdv",
          user.uid,
          "idCampaign",
          doc.id
        )
          .then((response) => `${response.size}`)
          .catch((error) => "0");
        const completedCount = await getCollectionAllSearchData(
          "completed",
          "userAdv",
          user.uid,
          "idCampaign",
          doc.id
        )
          .then((response) => `${response.size}`)
          .catch((error) => "0");
        const refusedCount = await getCollectionAllSearchData(
          "declined",
          "userAdv",
          user.uid,
          "idCampaign",
          doc.id
        )
          .then((response) => `${response.size}`)
          .catch((error) => "0");

        return {
          id: doc.id,
          date: moment(doc.dateCreated).format("L"),
          campaign: doc.campaignName,
          treat: treatCount,
          waiting: waitingCount,
          aside: asideCount,
          progress: progressCount,
          completed: completedCount,
          refused: refusedCount,
          action: doc.id,
        };
      })
    );
    setData(campaigns);
    setFilteredData(campaigns);
    setLoadingData(false);
  }, [user.uid]);

  const handleSearch = (value) => {
    setSearchText(value);
    const filteredData = data.filter((record) =>
      record.campaign.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredData);
  };
  //delete data
  const handleDeleteCampaign = (id) => {
    confirm({
      title: t("view-advertisers-myCampaigns.title-13"),
      icon: <ExclamationCircleFilled />,
      content: t("view-advertisers-myCampaigns.content-1"),
      okText: t("view-advertisers-myCampaigns.okText-1"),
      okType: "danger",
      cancelText: t("view-advertisers-myCampaigns.cancelText"),
      async onOk() {
        await deleteDoc(doc(db, "campaigns", id))
          .then(async () => {
            notify(
              "success",
              t("view-advertisers-myCampaigns.notify-1-2"),
              t("view-advertisers-myCampaigns.notify-1-3")
            );
            setLoading(false);
            getAllCampaigns();
          })
          .catch((error) => {
            notify(
              "error",
              t("view-advertisers-myCampaigns.notify-1-5"),
              t("view-advertisers-myCampaigns.notify-1-6")
            );
            setLoading(false);
          });
      },
    });
  };

  useEffect(() => {
    getAllCampaigns();
  }, [getAllCampaigns]);

  const getCampaign = async (id) => {
    const response = await getCollectionById("campaigns", id);
    if (response.exists) {
      setIdCampaign(response.id);
      form.setFieldsValue({
        campaignName: response.campaignName,
        budget: response.budget,
      });
    } else {
      // doc will be undefined in this case
      notify(
        "info",
        t("view-advertisers-myCampaigns.notify-1-8"),
        t("view-advertisers-myCampaigns.notify-1-9")
      );
    }
    setIsModalOpen(true);
  };

  const showModal = () => {
    form.resetFields();
    setIdCampaign(null);
    setIsModalOpen(true);
  };
  const onFinish = async (values) => {
    setLoading(true);
    if (idCampaign) {
      const q = doc(db, "campaigns", idCampaign);
      await updateDoc(q, {
        campaignName: values.campaignName,
        budget: values.budget,
        dateUpdated: moment().format(),
      })
        .then(async () => {
          notify(
            "success",
            t("view-advertisers-myCampaigns.notify-1-11"),
            t("view-advertisers-myCampaigns.notify-1-12")
          );
          setLoading(false);
          getAllCampaigns();
        })
        .catch((error) => {
          notify(
            "error",
            t("view-advertisers-myCampaigns.notify-1-14"),
            t("view-advertisers-myCampaigns.notify-1-15")
          );
          setLoading(false);
        });
    } else {
      const response = await getCollectionAllData(
        "campaigns",
        user.uid,
        "campaignName",
        values.campaignName
      );
      if (response && response.size > 0) {
        notify(
          "error",
          t("view-advertisers-myCampaigns.notify-1-17"),
          t("view-advertisers-myCampaigns.notify-1-18")
        );
        setLoading(false);
      } else {
        await addDoc(collection(db, "campaigns"), {
          userId: user.uid,
          campaignName: values.campaignName,
          budget: values.budget,
          dateCreated: moment().format(),
          dateUpdated: moment().format(),
        })
          .then(async () => {
            notify(
              "success",
              t("view-advertisers-myCampaigns.notify-1-20"),
              t("view-advertisers-myCampaigns.notify-1-21")
            );
            setLoading(false);
            getAllCampaigns();
            form.resetFields();
          })
          .catch((error) => {
            notify(
              "error",
              t("view-advertisers-myCampaigns.notify-1-23"),
              t("view-advertisers-myCampaigns.notify-1-24")
            );
            setLoading(false);
          });
      }
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setIdCampaign(null);
    setIsModalOpen(false);
  };

  return (
    <Page title={t("view-advertisers-myCampaigns.title-14")}>
      <div className="dashboard">
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Title level={4}>Campaigns</Typography.Title>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Button
                type="primary"
                icon={<MdAddCircleOutline style={{ marginRight: "8px" }} />}
                onClick={showModal}
                size="large"
              >
                {t("view-advertisers-myCampaigns.btn-1")}
              </Button>
              <Button
                type="primary"
                ghost
                icon={
                  <MdOutlineAddShoppingCart style={{ marginRight: "8px" }} />
                }
                size="large"
                onClick={() => navigate(`/${lang}/advertiser/me/new/order`)}
              >
                {t("view-advertisers-myCampaigns.btn-2")}
              </Button>
            </div>
          </div>
        </Card>
        <Card>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Input.Search
              style={{ width: "250px" }}
              size="large"
              placeholder={t("view-advertisers-myCampaigns.placeholder-1")}
              allowClear
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </Card>
        <Table
          scroll={{
            x: 986,
          }}
          loading={loadingData}
          columns={columns}
          dataSource={searchText === "" ? data : filteredData}
        />
      </div>

      <Modal
        title={
          idCampaign
            ? t("view-advertisers-myCampaigns.title-15")
            : t("view-advertisers-myCampaigns.title-16")
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            name="campaignName"
            label={t("view-advertisers-myCampaigns.label-1")}
            rules={[
              {
                required: true,
                message: t("view-advertisers-myCampaigns.message-1"),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t("view-advertisers-myCampaigns.placeholder-2")}
            />
          </Form.Item>
          <Form.Item
            name="budget"
            label={t("view-advertisers-myCampaigns.label-2")}
            rules={[
              {
                required: true,
                message: t("view-advertisers-myCampaigns.message-2"),
              },
            ]}
          >
            <InputNumber
              prefix="€"
              decimalSeparator=","
              min={1}
              step="0.01"
              placeholder="1,00"
              style={{ width: "100%" }}
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "1rem",
              }}
            >
              <Button size="large" type="default" onClick={handleCancel}>
                {t("view-advertisers-myCampaigns.btn-3")}
              </Button>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {idCampaign
                  ? t("view-advertisers-myCampaigns.btn-4")
                  : t("view-advertisers-myCampaigns.btn-5")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  );
};

export default MyCampaigns;
