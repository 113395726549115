import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "./context/UserAuthContext";
import { Loader } from "./components";

export const RouteAuthPub = ({ children }) => {
  const { lang, user, userAuth, loading } = useUserAuth();
  return loading ? (
    <Loader />
  ) : user && userAuth ? (
    userAuth.accountType === "pub" ? (
      children
    ) : (
      <Navigate to={`/${lang}/login`} />
    )
  ) : (
    <Navigate to={`/${lang}/login`} />
  );
};

export const RouteAdmin = ({ children }) => {
  const { lang, user, userAuth, loading } = useUserAuth();
  return loading ? (
    <Loader />
  ) : user && userAuth ? (
    userAuth.accountType === "admin-rocket" ? (
      children
    ) : (
      <Navigate to={`/${lang}/404`} />
    )
  ) : (
    <Navigate to={`/${lang}/login`} />
  );
};

export const RouteAuthAdv = ({ children }) => {
  const { lang, user, userAuth, loading } = useUserAuth();
  return loading ? (
    <Loader />
  ) : user && userAuth ? (
    userAuth.accountType === "adv-brand" ? (
      children
    ) : user && userAuth.accountType === "adv-agency" ? (
      children
    ) : (
      <Navigate to={`/${lang}/login`} />
    )
  ) : (
    <Navigate to={`/${lang}/login`} />
  );
};

export const RouteAuth = ({ children }) => {
  const { lang, user, userAuth, totalAdv, totalPub } = useUserAuth();
  return user && userAuth ? (
    userAuth.accountType === "pub" ? (
      <Navigate to={`/${lang}/publisher/me/dashboard`} />
    ) : userAuth.accountType === "adv-agency" ? (
      <Navigate to={`/${lang}/advertiser/me/dashboard`} />
    ) : userAuth.accountType === "adv-brand" ? (
      <Navigate to={`/${lang}/advertiser/me/dashboard`} />
    ) : userAuth.accountType === "admin-rocket" ? (
      <Navigate to={`/${lang}/admin/dashboard`} />
    ) : (
      children
    )
  ) : totalAdv && totalPub ? (
    children
  ) : (
    <Loader>{children}</Loader>
  );
};
