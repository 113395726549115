import React from "react";
import { Outlet } from "react-router-dom";

import { NavBar, SideBar } from "../global";

const Layout = () => {
  return (
    <div style={{ display: "flex", height: "100%", overflowX: "hidden" }}>
      <SideBar />
      <main
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f5f5f5",
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <NavBar />
        <div
          style={{
            minHeight: "85vh",
            marginTop: "5rem",
            padding: "0 20px",
            overflowX: "hidden",
          }}
        >
          <Outlet />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "30px 20px",
            color: "#687690",
          }}
        >
          <a
            href="https://www.ferberenterprises.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#2c4e7a",
            }}
          >
            ©{new Date().getFullYear()} Ferber Enterprises
          </a>
        </div>
      </main>
    </div>
  );
};
export default Layout;
