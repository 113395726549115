import { Alert, Button, Form, Input, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Page } from "../../components";
import { NavBarAuth } from "../../global";
import { useUserAuth } from "../../context/UserAuthContext";
import { useTranslation } from "react-i18next";

const ResetPwd = () => {
  const { t } = useTranslation();
  const { forgotPassword, lang } = useUserAuth();
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const onFinish = (values) => {
    forgotPassword(values.email);
    setEmail(values.email);
    setStep(1);
  };

  return (
    <Page title={t("view-auth-reset-pwd.title-1")}>
      <div className="auth">
        <NavBarAuth />
        <div className="auth-content">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              minHeight: "55vh",
            }}
          >
            <Typography.Title
              style={{
                fontSize: "42px",
                color: "#000336",
                fontWeight: "bold",
                textAlign: "center",
              }}
              data-aos="fade-left"
            >
              {t("view-auth-reset-pwd.title-1")}
            </Typography.Title>
            <Form
              name="login"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              style={{ maxWidth: 360 }}
            >
              {step === 0 ? (
                <Form.Item
                  name="email"
                  label={t("view-auth-reset-pwd.label-1")}
                  rules={[
                    {
                      required: true,
                      message: t("view-auth-reset-pwd.message-1"),
                    },
                    {
                      type: "email",
                      message: t("view-auth-reset-pwd.message-2"),
                    },
                  ]}
                  data-aos="fade-right"
                >
                  <Input
                    prefix={<MailOutlined />}
                    placeholder={t("view-auth-reset-pwd.placeholder-1")}
                    style={{ minWidth: "18rem" }}
                    size="large"
                  />
                </Form.Item>
              ) : null}
              {step === 1 ? (
                <Form.Item data-aos="fade-right">
                  <Alert
                    message={
                      <p>
                        {t("view-auth-reset-pwd.message-3")} <b>{email}</b>
                      </p>
                    }
                    type="success"
                  />
                </Form.Item>
              ) : null}
              {step === 0 ? (
                <Form.Item style={{ marginTop: "2.5rem" }} data-aos="zoom-in">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    size="large"
                  >
                    {t("view-auth-reset-pwd.btn-1")}
                  </Button>
                </Form.Item>
              ) : null}
              <Form.Item data-aos="fade-left">
                <div style={{ display: "flex", gap: "2px" }}>
                  <p style={{ textAlign: "start", maxWidth: "200px" }}>
                    {t("view-auth-reset-pwd.title-2")}
                  </p>
                  <Link
                    to={`/${lang}/login`}
                    style={{
                      color: "#2C4E7A",
                      textDecoration: "underline",
                      textAlign: "justify",
                    }}
                  >
                    {t("view-auth-reset-pwd.title-3")}
                  </Link>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ResetPwd;
