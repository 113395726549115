import { Button, Card, Switch, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegCalendarAlt } from "react-icons/fa";

const CardHeaderPub = ({ title, props }) => {
  const { t } = useTranslation();
  return (
    <Card
      {...props}
      title={title}
      extra={
        <Button
          type="primary"
          ghost
          icon={<FaRegCalendarAlt style={{ marginRight: "8px" }} />}
          size="large"
        >
          {t("components-cardHeaderPub.btn")}
        </Button>
      }
    >
      <div className="dashboard-select">
        <Typography.Text level={4} type="success">
          {t("components-cardHeaderPub.text")}
        </Typography.Text>
        <Switch title={t("components-cardHeaderPub.switch")} />
      </div>
    </Card>
  );
};

export default CardHeaderPub;
