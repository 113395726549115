import { Avatar, Button, Card, Empty, Form, Input, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import { Page } from "../components";
import "../assets/styles/dashboard.css";
import "../assets/styles/new-message.css";
import { useLocation } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import {
  getCollectionAllData,
  getCollectionById,
} from "../utils/getCollectionData";
import { useNotification } from "../utils/util-notification";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";

const NewMessage = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const idOrder = location.pathname.split("/")[5];
  const { userAuth, user } = useUserAuth();
  const { notify } = useNotification();
  const [campaignName, setCampaignName] = useState("");
  const [networkName, setNetworkName] = useState("");
  const [idCampaign, setIdCampaign] = useState("");
  const [idNetwork, setIdNetwork] = useState("");
  const [idMessage, setIdMessage] = useState("");
  const [userAdv, setUserAdv] = useState("");
  const [userPub, setUserPub] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  // Get one network and campaign
  const getOrdersAndGetNetworkAndCampaign = useCallback(async () => {
    setLoading(true);
    if (idOrder !== "") {
      const response = await getCollectionById("orders", idOrder);
      if (response.exists) {
        setUserPub(response.userPub);
        setUserAdv(response.userAdv);
        const [network, campaign, message] = await Promise.all([
          getCollectionById("networks", response.idNetwork),
          getCollectionById("campaigns", response.idCampaign),
          getCollectionAllData("messages", "", "idOrder", idOrder),
        ]);

        if (network.exists) {
          setNetworkName(network?.networkName);
          setIdNetwork(network?.id);
        }
        if (campaign.exists) {
          setCampaignName(campaign?.campaignName);
          setIdCampaign(campaign?.id);
        }
        if (message.size > 0) {
          setIdMessage(message?.data[0].id);
        }
      } else {
        notify("error", "Error", "Error loading message");
      }
    }
    setLoading(false);
  }, [idOrder, notify]);

  useEffect(() => {
    getOrdersAndGetNetworkAndCampaign();
  }, [getOrdersAndGetNetworkAndCampaign]);
  //get all message

  const getAllMessage = useCallback(async () => {
    if (idMessage !== "") {
      // create a document reference for the parent document (it doesn't have to exist)
      const messages = doc(db, "messages", idMessage);
      const q = query(
        collection(messages, "submessages"),
        orderBy("dateCreated")
      );
      // create a reference to the subcollection within the parent document
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let messages = [];
        querySnapshot.forEach((doc) => {
          messages.push({ ...doc.data(), id: doc.id });
        });
        setData(messages);
      });
      return () => unsubscribe();
    }
  }, [idMessage]);

  useEffect(() => {
    getAllMessage();
  }, [getAllMessage]);

  const onFinish = async (values) => {
    if (idMessage) {
      // create a document reference for the parent document (it doesn't have to exist)
      const messageRef = doc(db, "messages", idMessage);
      // create a subcollection within the parent document reference
      await updateDoc(messageRef, {
        statusPub: userAuth.accountType === "pub" ? false : true,
        statusAdv: userAuth.accountType === "pub" ? true : false,
        dateUpdated: moment().format(),
      });
      // create a subcollection within the parent document reference
      const subMessageRef = collection(messageRef, "submessages");
      await addDoc(subMessageRef, {
        userId: user.uid,
        message: values.message,
        status: true,
        dateCreated: moment().format(),
        dateUpdated: moment().format(),
      })
        .then(() => {
          getOrdersAndGetNetworkAndCampaign();
          getAllMessage();
          form.resetFields();
        })
        .catch((error) => {
          notify("error", "Error", "Error send message");
        });
    } else {
      const messageRef = collection(db, "messages");
      const messages = await addDoc(messageRef, {
        statusPub: userAuth.accountType === "pub" ? false : true,
        statusAdv: userAuth.accountType === "pub" ? true : false,
        status: true,
        idOrder: idOrder,
        idCampaign: idCampaign,
        idNetwork: idNetwork,
        userAdv: userAdv,
        userPub: userPub,
        dateCreated: moment().format(),
        dateUpdated: moment().format(),
      });
      // create a subcollection within the parent document reference
      const subMessageRef = collection(messages, "submessages");
      await addDoc(subMessageRef, {
        userId: user.uid,
        message: values.message,
        status: true,
        dateCreated: moment().format(),
        dateUpdated: moment().format(),
      })
        .then(() => {
          getOrdersAndGetNetworkAndCampaign();
          getAllMessage();
          form.resetFields();
        })
        .catch((error) => {
          notify("error", "Error", "Error send message");
          console.log(error);
        });
    }
  };
  return (
    <Page title="New message">
      <div className="dashboard">
        {loading ? (
          <Spin tip="Loading...">
            <Card title="Loading..." style={{ minHeight: "80vh" }} />
          </Spin>
        ) : (
          <Card
            title={`${
              userAuth?.accountType === "pub" ? campaignName : networkName
            }`}
          >
            <div className="new-message">
              {data && data.length > 0 ? (
                <div className="message-content">
                  {data.map((item, key) => (
                    <div className="message-list" key={key}>
                      {item.userId !== user.uid ? (
                        <div className="other-message">
                          <Avatar>
                            {userAuth?.accountType === "pub"
                              ? campaignName !== "" && campaignName[0]
                              : networkName !== "" && networkName[0]}
                          </Avatar>
                          <div>
                            <p>{item.message}</p>
                            <small>
                              {moment(item.dateCreated).format("L")}
                            </small>
                          </div>
                        </div>
                      ) : (
                        <div className="my-message">
                          <div>
                            <div>
                              <p>{item.message}</p>
                              <small>
                                {moment(item.dateCreated).format("L")}
                              </small>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <Empty description="Message empty" />
              )}
              <div className="new-message-form">
                <Form onFinish={onFinish} form={form}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <Form.Item name="message" style={{ width: "100%" }}>
                      <Input.TextArea
                        size="small"
                        placeholder="Writing your message"
                        allowClear={true}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        icon={<SendOutlined />}
                        style={{ height: 45 }}
                      >
                        Send
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </Card>
        )}
      </div>
    </Page>
  );
};

export default NewMessage;
