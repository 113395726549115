import { Button, Form, Input } from "antd";
import { UserAddOutlined, UserSwitchOutlined } from "@ant-design/icons";

import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useUserAuth } from "../context/UserAuthContext";
import { useTranslation } from "react-i18next";

const MyInformation = () => {
  const { userAuth, updateUser, lang } = useUserAuth();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onFinish = async (values) => {
    setLoading(true);
    await updateUser(
      userAuth?.id,
      values.firstName,
      values.lastName,
      values.phoneNumber
    );
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      firstName: userAuth && userAuth?.firstName,
      lastName: userAuth && userAuth?.lastName,
      phoneNumber: userAuth && userAuth?.phoneNumber,
    });
  }, [form, userAuth]);

  return (
    <Form name="information" layout="vertical" form={form} onFinish={onFinish}>
      <Form.Item
        name="firstName"
        label={t("sections-my-information.label-1")}
        rules={[
          {
            required: true,
            message: t("sections-my-information.message-1"),
          },
          {
            min: 3,
            message: t("sections-my-information.message-2"),
          },
        ]}
      >
        <Input
          prefix={<UserAddOutlined />}
          size="large"
          placeholder={t("sections-my-information.label-1")}
        />
      </Form.Item>
      <Form.Item
        name="lastName"
        label={t("sections-my-information.label-2")}
        rules={[
          {
            required: true,
            message: t("sections-my-information.message-3"),
          },
          {
            min: 3,
            message: t("sections-my-information.message-4"),
          },
        ]}
      >
        <Input
          prefix={<UserSwitchOutlined />}
          size="large"
          placeholder={t("sections-my-information.label-2")}
        />
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        label={t("sections-my-information.label-3")}
        rules={[
          {
            required: true,
            message: t("sections-my-information.message-5"),
          },
          {
            min: 10,
            message: t("sections-my-information.message-6"),
          },
        ]}
      >
        <PhoneInput
          enableSearch={true}
          country={lang}
          searchPlaceholder={t("sections-my-information.search-placeholder")}
          searchNotFound={t("sections-my-information.search-not-found")}
          placeholder={t("sections-my-information.placeholder")}
          inputStyle={{ width: "100%" }}
          size="large"
        />
      </Form.Item>

      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" loading={loading}>
          {t("sections-my-information.btn")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MyInformation;
