import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import Cookies from "js-cookie";

const firebaseConfig = {
  apiKey: "AIzaSyBUpT0Nmo8GXbtJIqXM63TC3cYBt5I-OsU",
  authDomain: "www.rocketfluence.com",
  databaseURL: "https://f-influence-default-rtdb.firebaseio.com",
  projectId: "f-influence",
  storageBucket: "f-influence.appspot.com",
  messagingSenderId: "1091253489812",
  appId: "1:1091253489812:web:c88ecb525c1d9d015be481",
  measurementId: "G-79ZK2YEXV7",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
auth.languageCode = Cookies.get("i18next") || "en";
