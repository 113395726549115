import React, { useEffect, useState } from "react";
import { HiUser, HiOutlineMail } from "react-icons/hi";
import { BsFillCreditCardFill } from "react-icons/bs";
import { FaFileInvoice } from "react-icons/fa";
import { RiLogoutCircleFill } from "react-icons/ri";
import { CiGlobe } from "react-icons/ci";
import {
  AiOutlineDashboard,
  AiOutlineUser,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { TbShoppingCartDiscount } from "react-icons/tb";
import { Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { Badge, Tooltip } from "antd";
import { useUserAuth } from "../context/UserAuthContext";
import { join } from "array-join";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useTranslation } from "react-i18next";

const SidebarPub = () => {
  const { t } = useTranslation();
  const { collapsed } = useProSidebar();
  const { logOut, user, lang } = useUserAuth();
  const location = useLocation();
  const [size, setSize] = useState(0); // Initialize size state to 0
  const [dataNetworks, setDataNetworks] = useState([]); // Initialize state to an empty array
  const [dataWaiting, setDataWaiting] = useState([]); // Initialize state to an empty array
  const [dataMessage, setDataMessage] = useState([]); // Initialize state to an empty array

  useEffect(() => {
    if (user) {
      // Define Firestore queries to retrieve data
      const q = query(
        collection(db, "networks"),
        where("userId", "==", user.uid)
      );
      const q2 = query(collection(db, "waiting"));

      // Listen for changes in the 'networks' collection and update dataNetworks array
      const unsubscribeNetworks = onSnapshot(q, (querySnapshot) => {
        setDataNetworks(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      });

      // Listen for changes in the 'waiting' collection and update dataWaiting array
      const unsubscribeWaiting = onSnapshot(q2, (querySnapshot) => {
        setDataWaiting(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      });

      // Return cleanup function to unsubscribe from Firestore listeners
      return () => {
        unsubscribeNetworks();
        unsubscribeWaiting();
      };
    }
  }, [user]);

  useEffect(() => {
    // Merge the two arrays based on a common key and update the 'size' state with the total length of the merged array
    const mergedData = join(
      dataNetworks.map((doc) => ({ ...doc })),
      dataWaiting.map((doc) => ({ ...doc })),
      (left) => left.id,
      (right) => right.idNetwork,
      (left, right) => ({ ...left, ...right })
    );
    setSize(mergedData.length);
  }, [dataNetworks, dataWaiting]);

  useEffect(() => {
    if (user) {
      // Define Firestore queries to retrieve data
      const q = query(
        collection(db, "messages"),
        where("userPub", "==", user.uid),
        where("statusPub", "==", true)
      );

      // Listen for changes in the 'networks' collection and update dataNetworks array
      const unsubscribeMessage = onSnapshot(q, (querySnapshot) => {
        setDataMessage(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      });

      // Return cleanup function to unsubscribe from Firestore listeners
      return () => {
        unsubscribeMessage();
      };
    }
  }, [user]);

  return (
    <Menu
      menuItemStyles={{
        button: ({ level, active }) => {
          // only apply styles on first level elements of the tree
          if (level === 0)
            return {
              backgroundColor: active ? "#f5f5f5" : undefined,
            };
          if (level === 1)
            return {
              backgroundColor: active ? "#f5f5f5" : undefined,
            };
        },
      }}
    >
      {collapsed ? (
        <Tooltip
          placement="right"
          title={t("components-side-bar-pub.menu-item-1")}
        >
          <MenuItem
            icon={<AiOutlineDashboard />}
            component={<Link to={`/${lang}/publisher/me/dashboard`} />}
            active={
              location.pathname === `/${lang}/publisher/me/dashboard`
                ? true
                : false
            }
          >
            {t("components-side-bar-pub.menu-item-1")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<AiOutlineDashboard />}
          component={<Link to={`/${lang}/publisher/me/dashboard`} />}
          active={
            location.pathname === `/${lang}/publisher/me/dashboard`
              ? true
              : false
          }
        >
          {t("components-side-bar-pub.menu-item-2")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip placement="right" title={t("components-side-bar-pub.title-1")}>
          <SubMenu
            label={t("components-side-bar-pub.label-1")}
            icon={<AiOutlineUser />}
            active={
              location.pathname === `/${lang}/publisher/me/login` ||
              location.pathname ===
                `/${lang}/publisher/me/withdrawal/methods` ||
              location.pathname === `/${lang}/publisher/me/withdrawals/history`
                ? true
                : false
            }
          >
            <MenuItem
              icon={<HiUser />}
              component={<Link to={`/${lang}/publisher/me/login`} />}
              active={
                location.pathname === `/${lang}/publisher/me/login`
                  ? true
                  : false
              }
            >
              {t("components-side-bar-pub.menu-item-3")}
            </MenuItem>
            <MenuItem
              icon={<BsFillCreditCardFill />}
              component={
                <Link to={`/${lang}/publisher/me/withdrawal/methods`} />
              }
              active={
                location.pathname === `/${lang}/publisher/me/withdrawal/methods`
                  ? true
                  : false
              }
            >
              {t("components-side-bar-pub.menu-item-4")}
            </MenuItem>
            <MenuItem
              icon={<FaFileInvoice />}
              component={
                <Link to={`/${lang}/publisher/me/withdrawals/history`} />
              }
              active={
                location.pathname ===
                `/${lang}/publisher/me/withdrawals/history`
                  ? true
                  : false
              }
            >
              {t("components-side-bar-pub.menu-item-5")}
            </MenuItem>
            <MenuItem onClick={logOut} icon={<RiLogoutCircleFill />}>
              {t("components-side-bar-pub.menu-item-6")}
            </MenuItem>
          </SubMenu>
        </Tooltip>
      ) : (
        <SubMenu
          label={t("components-side-bar-pub.label-2")}
          icon={<AiOutlineUser />}
          active={
            location.pathname === `/${lang}/publisher/me/login` ||
            location.pathname === `/${lang}/publisher/me/withdrawal/methods` ||
            location.pathname === `/${lang}/publisher/me/withdrawals/history`
              ? true
              : false
          }
        >
          <MenuItem
            icon={<HiUser />}
            component={<Link to={`/${lang}/publisher/me/login`} />}
            active={
              location.pathname === `/${lang}/publisher/me/login` ? true : false
            }
          >
            {t("components-side-bar-pub.menu-item-7")}
          </MenuItem>
          <MenuItem
            icon={<BsFillCreditCardFill />}
            component={<Link to={`/${lang}/publisher/me/withdrawal/methods`} />}
            active={
              location.pathname === `/${lang}/publisher/me/withdrawal/methods`
                ? true
                : false
            }
          >
            {t("components-side-bar-pub.menu-item-8")}
          </MenuItem>
          <MenuItem
            icon={<FaFileInvoice />}
            component={
              <Link to={`/${lang}/publisher/me/withdrawals/history`} />
            }
            active={
              location.pathname === `/${lang}/publisher/me/withdrawals/history`
                ? true
                : false
            }
          >
            {t("components-side-bar-pub.menu-item-9")}
          </MenuItem>
          <MenuItem onClick={logOut} icon={<RiLogoutCircleFill />}>
            {t("components-side-bar-pub.menu-item-10")}
          </MenuItem>
        </SubMenu>
      )}

      {collapsed ? (
        <Tooltip
          placement="right"
          title={
            <p>
              {t("components-side-bar-pub.title-2")} <Badge count={size} />
            </p>
          }
        >
          <MenuItem
            icon={<AiOutlineUnorderedList />}
            component={<Link to={`/${lang}/publisher/me/proposals/1`} />}
            active={
              location.pathname === `/${lang}/publisher/me/proposals/1` ||
              location.pathname === `/${lang}/publisher/me/proposals/2` ||
              location.pathname === `/${lang}/publisher/me/proposals/3` ||
              location.pathname === `/${lang}/publisher/me/proposals/4` ||
              location.pathname === `/${lang}/publisher/me/proposals/5` ||
              location.pathname === `/${lang}/publisher/me/proposals/6`
                ? true
                : false
            }
            suffix={<Badge count={size} />}
          >
            {t("components-side-bar-pub.menu-item-11")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<AiOutlineUnorderedList />}
          component={<Link to={`/${lang}/publisher/me/proposals/1`} />}
          active={
            location.pathname === `/${lang}/publisher/me/proposals/1` ||
            location.pathname === `/${lang}/publisher/me/proposals/2` ||
            location.pathname === `/${lang}/publisher/me/proposals/3` ||
            location.pathname === `/${lang}/publisher/me/proposals/4` ||
            location.pathname === `/${lang}/publisher/me/proposals/5` ||
            location.pathname === `/${lang}/publisher/me/proposals/6`
              ? true
              : false
          }
          suffix={<Badge count={size} />}
        >
          {t("components-side-bar-pub.menu-item-11")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip
          placement="right"
          title={t("components-side-bar-pub.menu-item-12")}
        >
          <MenuItem
            icon={<CiGlobe />}
            component={<Link to={`/${lang}/publisher/me/networks`} />}
            active={
              location.pathname === `/${lang}/publisher/me/networks`
                ? true
                : false
            }
          >
            {t("components-side-bar-pub.menu-item-12")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<CiGlobe />}
          component={<Link to={`/${lang}/publisher/me/networks`} />}
          active={
            location.pathname === `/${lang}/publisher/me/networks`
              ? true
              : false
          }
        >
          {t("components-side-bar-pub.menu-item-13")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip
          placement="right"
          title={`${t("components-side-bar-pub.title-3")} ${(
            <Badge count={dataMessage.length} />
          )}`}
        >
          <MenuItem
            icon={<HiOutlineMail />}
            component={<Link to={`/${lang}/publisher/me/messages`} />}
            active={
              location.pathname === `/${lang}/publisher/me/messages`
                ? true
                : false
            }
            suffix={<Badge count={dataMessage.length} />}
          >
            {t("components-side-bar-pub.menu-item-14")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<HiOutlineMail />}
          component={<Link to={`/${lang}/publisher/me/messages`} />}
          active={
            location.pathname === `/${lang}/publisher/me/messages`
              ? true
              : false
          }
          suffix={<Badge count={dataMessage.length} />}
        >
          {t("components-side-bar-pub.menu-item-15")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip placement="right" title={t("components-side-bar-pub.title-4")}>
          <MenuItem
            icon={<TbShoppingCartDiscount />}
            component={<Link to={`/${lang}/publisher/me/discounts`} />}
            active={
              location.pathname === `/${lang}/publisher/me/discounts`
                ? true
                : false
            }
          >
            {t("components-side-bar-pub.menu-item-16")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<TbShoppingCartDiscount />}
          component={<Link to={`/${lang}/publisher/me/discounts`} />}
          active={
            location.pathname === `/${lang}/publisher/me/discounts`
              ? true
              : false
          }
        >
          {t("components-side-bar-pub.menu-item-17")}
        </MenuItem>
      )}
    </Menu>
  );
};

export default SidebarPub;
