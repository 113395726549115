import React from "react";

import {
  Body,
  Container,
  Column,
  Head,
  Heading,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Text,
} from "@react-email/components";
import { useTranslation } from "react-i18next";

const EmailContentOrder = ({
  idOrder,
  userTo,
  lang,
  contentText,
  typeNetwork,
  price,
  userFrom,
}) => {
  const baseUrl = "https://www.rocketfluence.com";
  const { t } = useTranslation();
  return (
    <Html>
      <Head />
      <Preview>
        {t("component-emailOrder.text-1")} €{price}
      </Preview>
      <Body style={main}>
        <Container style={container}>
          <Link href={`${baseUrl}/${lang}`}>
            <Img
              src="https://res.cloudinary.com/bezavo/image/upload/v1684682780/rocketfluence/logo_j155jq.png"
              width="200"
              height="auto"
              alt="Rocketfluence"
            />
          </Link>
          <Heading style={h1}>{t("component-emailOrder.text-2")}</Heading>
          <Text style={heroText}>Hello {userTo}</Text>
          <Text style={text}>
            {t("component-emailOrder.text-3")}{" "}
            {typeNetwork === "youtube"
              ? "Youtube"
              : typeNetwork === "facebook"
              ? "Facebook"
              : typeNetwork === "instagram"
              ? "Instagram"
              : typeNetwork === "twitter"
              ? "Twitter"
              : typeNetwork === "tiktok"
              ? "TikTok"
              : typeNetwork === "snapchat"
              ? "Snapchat"
              : lang === "fr"
              ? "Blogue/Presse"
              : "Blog/press"}{" "}
            {t("component-emailOrder.text-4")} <b>{userFrom}</b>{" "}
            {t("component-emailOrder.text-5")} <b>€{price}</b>,{" "}
            {t("component-emailOrder.text-6")}:{" "}
            <Link
              href={`${baseUrl}/${lang}/publisher/me/order/${idOrder}`}
            >{`${baseUrl}/en/publisher/${lang}/order/${idOrder}`}</Link>
          </Text>

          <Section style={codeBox}>
            <Text style={heroText}>Instruction</Text>
            <Text
              style={confirmationCodeText}
              dangerouslySetInnerHTML={{
                __html: contentText,
              }}
            />
          </Section>

          <Text style={text}>{t("component-emailOrder.text-7")}</Text>

          <Section>
            <Row style={footerLogos}>
              <Column style={{ width: "66%" }}>
                <Link href={`${baseUrl}/${lang}`}>
                  <Img
                    src="https://res.cloudinary.com/bezavo/image/upload/v1684682780/rocketfluence/logo_j155jq.png"
                    width="200"
                    height="auto"
                    alt="Rocketfluence"
                  />
                </Link>
              </Column>
              <Column>
                <Row>
                  <Column>
                    <Link
                      href="https://www.youtube.com/@rocketfluence"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        src="https://res.cloudinary.com/bezavo/image/upload/v1684682820/rocketfluence/youtube_madew3.png"
                        style={socialMediaIcon}
                        width="22"
                        height="22"
                        alt="Youtube"
                      />
                    </Link>
                  </Column>
                  <Column>
                    <Link
                      href="https://www.facebook.com/rocketfluence"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        src="https://res.cloudinary.com/bezavo/image/upload/v1684683573/rocketfluence/facebook_dghlrc.png"
                        style={socialMediaIcon}
                        width="27"
                        height="27"
                        alt="Facebook"
                      />
                    </Link>
                  </Column>
                  <Column>
                    <Link
                      href="https://www.instagram.com/rocketfluence"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        src="https://res.cloudinary.com/bezavo/image/upload/v1684682795/rocketfluence/instagram_sskdfh.png"
                        style={socialMediaIcon}
                        width="22"
                        height="22"
                        alt="Instagram"
                      />
                    </Link>
                  </Column>
                  <Column>
                    <Link
                      href="https://twitter.com/rocketfluence "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Img
                        src="https://res.cloudinary.com/bezavo/image/upload/v1684683573/rocketfluence/twitter_wypzry.png"
                        style={socialMediaIcon}
                        width="27"
                        height="27"
                        alt="Twitter"
                      />
                    </Link>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Section>

          <Section>
            <Link
              style={footerLink}
              href={`${baseUrl}/${lang}/legal-notice`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("global-footer.link-1")}
            </Link>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <Link
              style={footerLink}
              href={`${baseUrl}/${lang}/terms-conditions`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("global-footer.link-2")}
            </Link>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <Link
              style={footerLink}
              href={`${baseUrl}/${lang}/terms-advertiser`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("global-footer.link-3")}
            </Link>
            <Text style={{ textAlign: "center" }}>
              <Link
                href="https://www.ferberenterprises.com"
                target="_blank"
                rel="noopener noreferrer"
                style={footerText}
              >
                ©{new Date().getFullYear()} Ferber Enterprises
              </Link>
            </Text>
          </Section>
        </Container>
      </Body>
    </Html>
  );
};

export default EmailContentOrder;

const footerText = {
  fontSize: "12px",
  color: "#b7b7b7",
  lineHeight: "15px",
  textAlign: "center",
  marginTop: "80px",
};

const footerLink = {
  color: "#b7b7b7",
  textDecoration: "underline",
};

const footerLogos = {
  marginBottom: "32px",
  paddingLeft: "8px",
  paddingRight: "8px",
  width: "100%",
};

const main = {
  backgroundColor: "#ffffff",
  margin: "0 auto",
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};

const container = {
  maxWidth: "600px",
  margin: "0 auto",
};

const socialMediaIcon = {
  display: "inline",
};

const h1 = {
  color: "#1d1c1d",
  fontSize: "27px",
  fontWeight: "700",
  margin: "30px 0",
  padding: "0",
  lineHeight: "42px",
};

const heroText = {
  fontSize: "20px",
  lineHeight: "28px",
  marginBottom: "30px",
};

const codeBox = {
  borderRadius: "4px",
  marginRight: "50px",
  marginBottom: "30px",
  padding: "43px 23px",
};

const confirmationCodeText = {
  fontSize: "30px",
  textAlign: "center",
  verticalAlign: "middle",
};

const text = {
  color: "#000",
  fontSize: "14px",
  lineHeight: "24px",
};
