import React from "react";
import { Page } from "../components";
import { NavBarAuth } from "../global";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import "../assets/styles/about-us.css";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("view-aboutUs.title")}>
      <NavBarAuth />
      <div className="about-us">
        <Typography.Title>{t("view-aboutUs.text-1")}</Typography.Title>
        <div className="about-us-content">
          <div className="about-us-item">
            <Typography.Title level={4}>
              {t("view-aboutUs.text-2")}
            </Typography.Title>
            <Typography.Paragraph>
              {t("view-aboutUs.text-3")}
            </Typography.Paragraph>
          </div>
          <div className="about-us-item">
            <Typography.Title level={4}>
              {t("view-aboutUs.text-4")}
            </Typography.Title>
            <Typography.Paragraph>
              {t("view-aboutUs.text-5")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-6")}
            </Typography.Paragraph>
          </div>
          <div className="about-us-item">
            <Typography.Title level={4}>
              {t("view-aboutUs.text-7")}
            </Typography.Title>
            <Typography.Paragraph>
              {t("view-aboutUs.text-8")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-9")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-10")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-11")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-12")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-13")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-14")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-15")}
            </Typography.Paragraph>
          </div>
          <div className="about-us-item">
            <Typography.Title level={4}>
              {t("view-aboutUs.text-16")}
            </Typography.Title>
            <Typography.Paragraph>
              {t("view-aboutUs.text-17")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-18")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-19")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-20")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-21")}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {t("view-aboutUs.text-22")}
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default AboutUs;
