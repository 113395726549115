import { Typography } from "antd";
import React from "react";
import youtubeImg from "../assets/media/youtube.png";
import facebookImg from "../assets/media/facebook.svg";
import instagramImg from "../assets/media/instagram.png";
import snapchatImg from "../assets/media/snapchat.svg";
import twitterImg from "../assets/media/twitter.svg";
import tiktokImg from "../assets/media/tiktok.png";
import pressImg from "../assets/media/press.png";
import "../assets/styles/social-media.css";
import { useTranslation } from "react-i18next";

const Social = () => {
  const { t } = useTranslation();
  return (
    <div className="social-media">
      <Typography.Title
        level={3}
        style={{
          color: "#000336",
          fontWeight: "bold",
          textAlign: "center",
        }}
        data-aos="fade-up-right"
      >
        {t("section-social.text-1")}
      </Typography.Title>

      <div className="social-media-container" data-aos="zoom-in">
        <div className="social-media-item">
          <img src={pressImg} alt="press" />
          <span>Press/Blog</span>
        </div>
        <div className="social-media-item">
          <img src={youtubeImg} alt="Youtube" />
          <span>Youtube</span>
        </div>
        <div className="social-media-item">
          <img src={facebookImg} alt="facebook" />
          <span>Facebook</span>
        </div>
        <div className="social-media-item">
          <img src={instagramImg} alt="instagram" />
          <span>Instagram</span>
        </div>
        <div className="social-media-item">
          <img src={twitterImg} alt="twitter" />
          <span>Twitter</span>
        </div>
        <div className="social-media-item">
          <img src={tiktokImg} alt="tiktok" />
          <span>TikTok</span>
        </div>
        <div className="social-media-item">
          <img src={snapchatImg} alt="snapchat" />
          <span>Snapchat</span>
        </div>
      </div>
      <Typography.Paragraph
        style={{
          color: "#687690",
          fontWeight: "500",
          textAlign: "center",
        }}
        data-aos="fade-up-left"
      >
        {t("section-social.text-2")}
      </Typography.Paragraph>
    </div>
  );
};

export default Social;
