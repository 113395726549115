import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";

import React from "react";
import { Sidebar, useProSidebar } from "react-pro-sidebar";
import {
  SidebarAdmin,
  SidebarAdv,
  SidebarHeader,
  SidebarPub,
} from "../components";
import { useUserAuth } from "../context/UserAuthContext";
import bgImg from "../assets/media/hero.png";

const SideBar = () => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const { userAuth } = useUserAuth();
  return (
    <Sidebar breakPoint="md" image={bgImg}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <SidebarHeader />
        <div style={{ flex: 1, marginBottom: "32px" }}>
          {userAuth && userAuth?.accountType === "pub" ? (
            <SidebarPub />
          ) : userAuth && userAuth?.accountType === "admin-rocket" ? (
            <SidebarAdmin />
          ) : (
            <SidebarAdv />
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: collapsed ? "center" : "end",
            backgroundColor: "transparent",
            cursor: "pointer",
            padding: "1rem",
            position: "fixed",
            bottom: 0,
            transition: "width,left,right,300ms",
            width: collapsed ? "80px" : "250px",
          }}
          onClick={() => collapseSidebar(!collapsed)}
        >
          {collapsed ? (
            <AiOutlineMenuUnfold style={{ fontSize: "1.2rem" }} />
          ) : (
            <AiOutlineMenuFold style={{ fontSize: "1.2rem" }} />
          )}
        </div>
      </div>
    </Sidebar>
  );
};

export default SideBar;
