import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { Layout, LayoutAuth } from "./layouts";
import {
  RouteAuthPub,
  RouteAuthAdv,
  RouteAuth,
  RouteAdmin,
} from "./protectedRoute";
import {
  Proposals,
  DashboardPub,
  Networks,
  Home,
  Login,
  Discounts,
  Page404,
  Register,
  ResetPwd,
  UpdatePwd,
  MyLogin,
  WithdrawalMethods,
  WithdrawalsHistory,
  DashboardAdv,
  MoneyDeposit,
  Invoices,
  MyCampaigns,
  MyMissions,
  MyFavorite,
  CheckEmail,
  NewOrder,
  OrderViewAdv,
  OrderViewPub,
  NewMessage,
  LegalNotice,
  TermsConditions,
  TermsAdvertiser,
  Completion,
  Publisher,
  AboutUs,
  MessagesAdv,
  MessagesPub,
  DashboardAdmin,
  GainsAdmin,
  DepotsAdmin,
  UsersAdmin,
  NetworksAdmin,
  MissionsAdmin,
  ProposalsAdmin,
  CampaignsAdmin,
  WithdrawalsAdmin,
  MessagesAdmin,
} from "./view";
import { useUserAuth } from "./context/UserAuthContext";
import { EmailContentOrder, Loader } from "./components";

const Routes = () => {
  const { lang, user } = useUserAuth();

  const loader = () => {
    return !user && <Loader />;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to={`/${lang}`} replace={true} />,
    },
    {
      path: "/",
      element: <LayoutAuth />,
      children: [
        {
          path: `${lang}`,
          element: (
            <RouteAuth>
              <Home />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/email`,
          element: (
            <RouteAuth>
              <EmailContentOrder />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/publishers`,
          element: (
            <RouteAuth>
              <Publisher />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/about-us`,
          element: (
            <RouteAuth>
              <AboutUs />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/register`,
          element: (
            <RouteAuth>
              <Register />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/login`,
          element: (
            <RouteAuth>
              <Login />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/password/reset`,
          element: (
            <RouteAuth>
              <ResetPwd />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/password/update`,
          element: (
            <RouteAuth>
              <UpdatePwd />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/check/email`,
          element: (
            <RouteAuth>
              <CheckEmail />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/legal-notice`,
          element: (
            <RouteAuth>
              <LegalNotice />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/terms-conditions`,
          element: (
            <RouteAuth>
              <TermsConditions />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/terms-advertiser`,
          element: (
            <RouteAuth>
              <TermsAdvertiser />
            </RouteAuth>
          ),
        },
        {
          path: `${lang}/404`,
          element: (
            <RouteAuth>
              <Page404 />
            </RouteAuth>
          ),
        },
      ],
    },
    {
      path: "/",
      element: <Layout />,
      loader: () => loader(),
      children: [
        {
          path: `${lang}/publisher/me/dashboard`,
          element: (
            <RouteAuthPub>
              <DashboardPub />
            </RouteAuthPub>
          ),
        },
        {
          path: `${lang}/publisher/me/proposals/:key`,
          element: (
            <RouteAuthPub>
              <Proposals />
            </RouteAuthPub>
          ),
        },
        {
          path: `${lang}/publisher/me/order/:key`,
          element: (
            <RouteAuthPub>
              <OrderViewPub />
            </RouteAuthPub>
          ),
        },
        {
          path: `${lang}/publisher/me/networks`,
          element: (
            <RouteAuthPub>
              <Networks />
            </RouteAuthPub>
          ),
        },
        {
          path: `${lang}/publisher/me/discounts`,
          element: (
            <RouteAuthPub>
              <Discounts />
            </RouteAuthPub>
          ),
        },
        {
          path: `${lang}/publisher/me/messages`,
          element: (
            <RouteAuthPub>
              <MessagesPub />
            </RouteAuthPub>
          ),
        },
        {
          path: `${lang}/publisher/me/messages/:key`,
          element: (
            <RouteAuthPub>
              <NewMessage />
            </RouteAuthPub>
          ),
        },
        {
          path: `${lang}/publisher/me/login`,
          element: (
            <RouteAuthPub>
              <MyLogin />
            </RouteAuthPub>
          ),
        },
        {
          path: `${lang}/publisher/me/withdrawal/methods`,
          element: (
            <RouteAuthPub>
              <WithdrawalMethods />
            </RouteAuthPub>
          ),
        },
        {
          path: `${lang}/publisher/me/withdrawals/history`,
          element: (
            <RouteAuthPub>
              <WithdrawalsHistory />
            </RouteAuthPub>
          ),
        },
        //advertiser
        {
          path: `${lang}/advertiser/me/dashboard`,
          element: (
            <RouteAuthAdv>
              <DashboardAdv />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/new/order`,
          element: (
            <RouteAuthAdv>
              <NewOrder />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/campaigns`,
          element: (
            <RouteAuthAdv>
              <MyCampaigns />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/missions/:key`,
          element: (
            <RouteAuthAdv>
              <MyMissions />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/order/:key`,
          element: (
            <RouteAuthAdv>
              <OrderViewAdv />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/messages`,
          element: (
            <RouteAuthAdv>
              <MessagesAdv />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/messages/:key`,
          element: (
            <RouteAuthAdv>
              <NewMessage />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/favorites`,
          element: (
            <RouteAuthAdv>
              <MyFavorite />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/login`,
          element: (
            <RouteAuthAdv>
              <MyLogin />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/money`,
          element: (
            <RouteAuthAdv>
              <MoneyDeposit />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/completion`,
          element: (
            <RouteAuthAdv>
              <Completion />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/advertiser/me/invoices`,
          element: (
            <RouteAuthAdv>
              <Invoices />
            </RouteAuthAdv>
          ),
        },
        {
          path: `${lang}/admin/dashboard`,
          element: (
            <RouteAdmin>
              <DashboardAdmin />
            </RouteAdmin>
          ),
        },
        {
          path: `${lang}/admin/gains`,
          element: (
            <RouteAdmin>
              <GainsAdmin />
            </RouteAdmin>
          ),
        },
        {
          path: `${lang}/admin/depots`,
          element: (
            <RouteAdmin>
              <DepotsAdmin />
            </RouteAdmin>
          ),
        },
        {
          path: `${lang}/admin/users`,
          element: (
            <RouteAdmin>
              <UsersAdmin />
            </RouteAdmin>
          ),
        },
        {
          path: `${lang}/admin/networks`,
          element: (
            <RouteAdmin>
              <NetworksAdmin />
            </RouteAdmin>
          ),
        },
        {
          path: `${lang}/admin/missions`,
          element: (
            <RouteAdmin>
              <MissionsAdmin />
            </RouteAdmin>
          ),
        },
        {
          path: `${lang}/admin/proposals`,
          element: (
            <RouteAdmin>
              <ProposalsAdmin />
            </RouteAdmin>
          ),
        },
        {
          path: `${lang}/admin/campaigns`,
          element: (
            <RouteAdmin>
              <CampaignsAdmin />
            </RouteAdmin>
          ),
        },
        {
          path: `${lang}/admin/withdrawals`,
          element: (
            <RouteAdmin>
              <WithdrawalsAdmin />
            </RouteAdmin>
          ),
        },
        {
          path: `${lang}/admin/messages`,
          element: (
            <RouteAdmin>
              <MessagesAdmin />
            </RouteAdmin>
          ),
        },
        {
          path: `${lang}/admin/login`,
          element: (
            <RouteAdmin>
              <MyLogin />
            </RouteAdmin>
          ),
        },
      ],
    },

    {
      path: "*",
      element: <Navigate to={`/${lang}/404`} />,
    },
  ]);

  return <RouterProvider router={router} />;
};
export default Routes;
