import { Button, Card, Result, Steps } from "antd";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  ShoppingCartOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import React from "react";
import { Page } from "../../components";

import {
  AdvertorialRequirements,
  CartSummary,
  ChooseNetwork,
} from "../../sections";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/dashboard.css";
import { useTranslation } from "react-i18next";

const NewOrder = () => {
  const { lang, steps, onDecreasingSteps, cart, summary } = useUserAuth();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const items = [
    {
      title: (
        <div onClick={() => onDecreasingSteps(0)} style={{ cursor: "pointer" }}>
          1
        </div>
      ),
      description: t("view-advertisers-new-order.desc-1"),
      icon:
        steps <= 0 ? (
          <LoadingOutlined />
        ) : (
          <GlobalOutlined
            style={{ cursor: "pointer" }}
            onClick={() => onDecreasingSteps(0)}
          />
        ),
    },
    {
      title: (
        <div onClick={() => onDecreasingSteps(1)} style={{ cursor: "pointer" }}>
          2
        </div>
      ),
      description: t("view-advertisers-new-order.desc-2"),
      icon:
        steps === 1 ? (
          <LoadingOutlined />
        ) : (
          <ShoppingCartOutlined
            style={{ cursor: "pointer" }}
            onClick={() => onDecreasingSteps(1)}
          />
        ),
      status: cart.length > 0 && "finish",
    },
    {
      title: (
        <div onClick={() => onDecreasingSteps(2)} style={{ cursor: "pointer" }}>
          3
        </div>
      ),
      description: t("view-advertisers-new-order.desc-3"),
      icon:
        steps === 2 ? (
          <LoadingOutlined />
        ) : (
          <QuestionCircleOutlined
            style={{ cursor: "pointer" }}
            onClick={() => onDecreasingSteps(2)}
          />
        ),
      status: summary.length > 0 && "finish",
    },
    {
      title: (
        <div onClick={() => onDecreasingSteps(3)} style={{ cursor: "pointer" }}>
          4
        </div>
      ),
      description: t("view-advertisers-new-order.desc-4"),
      icon: (
        <CheckCircleOutlined
          style={{ cursor: "pointer" }}
          onClick={() => onDecreasingSteps(3)}
        />
      ),
    },
  ];

  return (
    <Page title={t("view-advertisers-new-order.title-1")}>
      <div className="dashboard">
        <Card>
          <Steps current={steps} items={items} />
        </Card>

        {steps <= 0 ? (
          <div style={{ marginTop: "2rem", position: "relative" }}>
            <ChooseNetwork />
          </div>
        ) : steps === 1 ? (
          <CartSummary />
        ) : steps === 2 ? (
          <AdvertorialRequirements />
        ) : (
          <Card>
            <Result
              title={t("view-advertisers-new-order.text-1")}
              subTitle={t("view-advertisers-new-order.text-2")}
              status="success"
              extra={
                <Button
                  type="primary"
                  onClick={() => navigation(`/${lang}/advertiser/me/dashboard`)}
                  size="large"
                >
                  {t("view-advertisers-new-order.btn")}
                </Button>
              }
            />
          </Card>
        )}
      </div>
    </Page>
  );
};

export default NewOrder;
