import React, { useCallback, useEffect, useState } from "react";
import "../assets/styles/chat-widget.css";
import { useUserAuth } from "../context/UserAuthContext";
import { BsChatQuote } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import { Avatar, Badge, Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import userAvatar from "../assets/media/user-1.jpg";
import {
  addDoc,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";
import { getCollectionAllData } from "../utils/getCollectionData";
import { useRef } from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";

const ChatWidget = () => {
  const { lang, user, userAuth } = useUserAuth();
  moment.locale(lang);
  const [showChat, setShowChat] = useState(false);
  const { t } = useTranslation();
  const [messageText, setMessageText] = useState("");
  const [userChatEmail, setUserChatEmail] = useState("");
  const [messages, setMessages] = useState([]);
  const chatBodyRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const getUsersChat = await getCollectionAllData(
      "users-chat",
      "",
      "email",
      values.email
    );
    if (getUsersChat.size > 0) {
      for (let index = 0; index < messages.length; index++) {
        if (index === 0) {
          setTimeout(async () => {
            await addDoc(collection(db, "chats"), {
              user: values.email,
              text: messages[index].text,
              date: new Date(messages[index].date),
              sender: messages[index].sender,
              component: messages[index].component,
              seen: messages[index].seen,
            }).then(() => {
              localStorage.setItem(
                "__usersChat_rocket",
                JSON.stringify({ user: getUsersChat.data[0].email })
              );
              getUserChatEmail();
              localStorage.removeItem("__message_rocket");
            });
          }, 1000);
        }
        break;
      }
    } else {
      localStorage.setItem(
        "__usersChat_rocket",
        JSON.stringify({ user: values.email })
      );
      await addDoc(collection(db, "users-chat"), {
        username: values.userName,
        email: values.email,
        dateCreated: new Date(),
        dateUpdated: new Date(),
      })
        .then(async (res) => {
          setTimeout(async () => {
            await addDoc(collection(db, "chats"), {
              user: values.email,
              text: t("chat-widget.message-2"),
              date: new Date(),
              sender: "admin",
              component: false,
              seen: false,
            }).then(() => {
              getUserChatEmail();
            });
          }, 1000);
          for (let index = 0; index < messages.length; index++) {
            if (index === 0) {
              setTimeout(async () => {
                await addDoc(collection(db, "chats"), {
                  user: values.email,
                  text: messages[index].text,
                  date: new Date(messages[index].date),
                  sender: messages[index].sender,
                  component: messages[index].component,
                  seen: messages[index].seen,
                }).then(() => {
                  localStorage.removeItem("__message_rocket");
                });
              }, 1000);
            }
            break;
          }
        })
        .catch((error) => {});
    }
    setLoading(false);
  };

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  const getMessageFromLocalStorage = () => {
    setMessages(
      localStorage.getItem("__message_rocket")
        ? JSON.parse(localStorage.getItem("__message_rocket"))
        : []
    );
  };

  const getUserChatEmail = useCallback(() => {
    if (user === null) {
      setUserChatEmail(
        localStorage.getItem("__usersChat_rocket")
          ? JSON.parse(localStorage.getItem("__usersChat_rocket")).user
          : ""
      );
    } else {
      setUserChatEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    getUserChatEmail();
  }, [getUserChatEmail]);

  useEffect(() => {
    const arrayMessages = [];
    if (user) {
      const q = query(
        collection(db, "chats"),
        where("user", "==", user.email)
        // orderBy("date")
      );
      const unsubscribeChats = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docs.map((doc) =>
          arrayMessages.push({ ...doc.data(), id: doc.id })
        );
        setMessages(arrayMessages.sort((a, b) => a.date - b.date));
      });
      return () => {
        unsubscribeChats();
      };
    } else if (userChatEmail !== "") {
      const q = query(
        collection(db, "chats"),
        where("user", "==", userChatEmail)
        // orderBy("date")
      );

      const unsubscribeChats = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docs.map((doc) =>
          arrayMessages.push({ ...doc.data(), id: doc.id })
        );
        setMessages(arrayMessages.sort((a, b) => a.date - b.date));
      });
      return () => {
        unsubscribeChats();
      };
    } else {
      getMessageFromLocalStorage();
    }
  }, [messages, user, userChatEmail]);

  const sendMessage = useCallback(() => {
    const firstMessage = [
      {
        text: messageText,
        sender: "user",
        date: new Date(),
        component: false,
        seen: false,
      },
      {
        text: t("chat-widget.message-1"),
        date: new Date(),
        sender: "admin",
        component: false,
        seen: false,
      },
      {
        component: true,
        date: new Date(),
        sender: "admin",
        seen: false,
      },
    ];
    let index = 0;

    const send = [];
    const intervalId = setInterval(() => {
      send.push(firstMessage[index]);
      localStorage.setItem("__message_rocket", JSON.stringify(send));
      index++;
      if (index >= firstMessage.length) {
        clearInterval(intervalId);
      }
      getMessageFromLocalStorage();
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }, 1000);
  }, [messageText, t]);

  const handleSendMessage = useCallback(
    async (e) => {
      e.preventDefault();
      if (user) {
        const getUsersChat = await getCollectionAllData(
          "users-chat",
          "",
          "email",
          user.email
        );
        if (getUsersChat.size > 0) {
          await addDoc(collection(db, "chats"), {
            user: user.email,
            text: messageText,
            date: new Date(),
            sender: "user",
            component: false,
            seen: false,
          }).then(() => {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
          });
        } else {
          await addDoc(collection(db, "users-chat"), {
            username: userAuth
              ? `${userAuth.firstName} ${userAuth.lastName}`
              : "User",
            email: user.email,
            dateCreated: new Date(),
            dateUpdated: new Date(),
          }).then(async () => {
            await addDoc(collection(db, "chats"), {
              user: user.email,
              text: messageText,
              date: new Date(),
              sender: "user",
              component: false,
              seen: false,
            }).then(() => {
              chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
            });
          });
        }
      } else {
        if (userChatEmail !== "") {
          await addDoc(collection(db, "chats"), {
            user: userChatEmail,
            text: messageText,
            date: new Date(),
            sender: "user",
            component: false,
            seen: false,
          }).then(() => {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
          });
        } else {
          sendMessage();
        }
      }
      setMessageText("");
    },
    [messageText, sendMessage, user, userAuth, userChatEmail]
  );

  return (
    <div className="chat-widget">
      <Badge count={0} style={{ top: 8, right: 4 }}>
        <button className="chat-toggle" onClick={toggleChat}>
          {showChat ? <IoClose /> : <BsChatQuote />}
        </button>
      </Badge>
      <div className={`chat-window ${showChat ? "open" : "closed"}`}>
        <div className="chat-header">
          <Badge style={{ backgroundColor: "#52c41a" }} dot>
            <Avatar src={userAvatar} />
          </Badge>
          <span>RocketFluence</span>
          <button className="close-chat" onClick={toggleChat}>
            <span>&times;</span>
          </button>
        </div>
        <p
          style={{
            fontSize: "9px",
            textAlign: "center",
            padding: "8px 3px",
            borderRadius: "1px",
            backgroundColor: "#f4f4ff",
          }}
        >
          {t("chat-widget.text-1")}
        </p>
        <div ref={chatBodyRef} className="chat-body">
          {messages &&
            messages.map((message, index) => (
              <div
                key={index}
                className={`message ${
                  message.sender === "user" ? "sent" : "received"
                }`}
              >
                {message.sender === "user" && (
                  <div className="dropdown-container">
                    <div
                      className="dropdown-header"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <IoEllipsisHorizontal />
                    </div>

                    {isOpen && (
                      // <ul className="dropdown-options">
                      //   <li>Delete</li>
                      // </ul>
                      <div />
                    )}
                  </div>
                )}
                {message.component && (
                  <div className="message-text">
                    <Form layout="vertical" onFinish={onFinish} key="form">
                      <Form.Item
                        name="userName"
                        rules={[
                          {
                            required: true,
                            message: t("chat-widget.rules-1"),
                          },
                        ]}
                      >
                        <Input placeholder={t("chat-widget.placeholder-1")} />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t("chat-widget.rules-2"),
                          },
                          {
                            type: "email",
                            message: t("chat-widget.rules-3"),
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          placeholder={t("chat-widget.placeholder-2")}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          type="primary"
                          style={{ width: "100%" }}
                          loading={loading}
                        >
                          OK
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                )}
                {message.text && (
                  <div className="message-text">{message.text}</div>
                )}
                {localStorage.getItem("__message_rocket") ? (
                  <div className="message-date">
                    {moment(message.date).format("LLL")}
                  </div>
                ) : (
                  <div className="message-date">
                    {moment(message.date.toDate()).format("LLL")}
                  </div>
                )}
              </div>
            ))}
        </div>

        {messages.length && messages[2]?.component && userChatEmail === "" ? (
          <form className="chat-form" onSubmit={handleSendMessage}>
            <div />
          </form>
        ) : (
          <form className="chat-form" onSubmit={handleSendMessage}>
            <input
              type="text"
              onChange={(e) => {
                setMessageText(e.target.value);
              }}
              value={messageText}
              placeholder={t("chat-widget.placeholder-3")}
            />
            <button type="submit" disabled={messageText !== "" ? false : true}>
              <IoMdSend />
            </button>
          </form>
        )}
        <div className="chat-footer">
          Chat By{" "}
          <a
            href={`https://www.rocketfluence.com/${lang}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            rocketfluence
          </a>{" "}
          |{" "}
          <a
            href={`https://www.rocketfluence.com/${lang}/legal-notice`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChatWidget;
