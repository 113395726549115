import React from "react";
import "../assets/styles/loader.css";
import { useTranslation } from "react-i18next";
import logo from "../assets/media/logo-collapsed.png";
import { Spin } from "antd";

const Loader = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Spin
      indicator={<img src={logo} alt="Loading" className="loader-image" />}
      tip={<h3 className="loader-text">{t("components-loader.spin")}</h3>}
      size="large"
    >
      {children}
    </Spin>
  );
};

export default Loader;
