import { Card, Switch, Typography } from "antd";
import React from "react";
import { Page } from "../../components";
import { MessageTableAdv } from "../../sections";
import { useTranslation } from "react-i18next";

const Messages = () => {
  const { t } = useTranslation();
  return (
    <Page title="Messages">
      <div className="dashboard">
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Title level={4}>
              {t("view-advertiser-message.text-1")}
            </Typography.Title>
            <Switch title={t("view-advertiser-message.switch")} />
          </div>
        </Card>
        <MessageTableAdv />
      </div>
    </Page>
  );
};

export default Messages;
