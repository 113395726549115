import React, { useCallback, useEffect, useState } from "react";
import { CardHeaderPub, Page } from "../../components";
import "../../assets/styles/my-account.css";
import { Button, Card, Form, Input, Select, Spin } from "antd";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import { useNotification } from "../../utils/util-notification";
import { useUserAuth } from "../../context/UserAuthContext";
import { useTranslation } from "react-i18next";
import { getCollectionAllSearchData } from "../../utils/getCollectionData";

const WithdrawalMethods = () => {
  const { t } = useTranslation();
  const [formOne] = Form.useForm();
  const [formTwo] = Form.useForm();
  const [formThree] = Form.useForm();
  const [idWithdrawals, setIdWithdrawals] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [withdrawalMethod, setWithdrawalMethod] = useState("1");
  const { notify } = useNotification();
  const { user } = useUserAuth();

  const onFinishOne = async (values) => {
    setLoading(true);
    if (idWithdrawals) {
      const q = doc(db, "withdrawals", idWithdrawals);
      await updateDoc(q, {
        method: withdrawalMethod,
        bankAccount: values.bankAccount,
        iban: values.iban,
        swift: values.swift,
        dateUpdated: moment().format(),
      })
        .then(async () => {
          notify(
            "success",
            t("view-account-with-drawal-methods.notify-1-1"),
            t("view-account-with-drawal-methods.notify-1-2")
          );
          setLoading(false);
        })
        .catch((error) => {
          notify(
            "error",
            "Error Update",
            t("view-account-with-drawal-methods.notify-1-3"),
            t("view-account-with-drawal-methods.notify-1-5")
          );
          setLoading(false);
        });
    } else {
      await addDoc(collection(db, "withdrawals"), {
        userId: user.uid,
        method: withdrawalMethod,
        bankAccount: values.bankAccount,
        iban: values.iban,
        swift: values.swift,
        emailCredit: "",
        payPalEmail: "",
        dateCreated: moment().format(),
        dateUpdated: moment().format(),
      })
        .then(async () => {
          notify(
            "success",
            t("view-account-with-drawal-methods.notify-1-6"),
            t("view-account-with-drawal-methods.notify-1-8")
          );
          setLoading(false);
        })
        .catch((error) => {
          notify(
            "error",
            t("view-account-with-drawal-methods.notify-1-9"),
            t("view-account-with-drawal-methods.notify-1-10")
          );
          setLoading(false);
        });
    }
  };
  const onFinishTwo = async (values) => {
    setLoading(true);
    if (idWithdrawals) {
      const q = doc(db, "withdrawals", idWithdrawals);
      await updateDoc(q, {
        method: withdrawalMethod,
        payPalEmail: values.payPalEmail,
        dateUpdated: moment().format(),
      })
        .then(async () => {
          notify(
            "success",
            t("view-account-with-drawal-methods.notify-1-11"),
            t("view-account-with-drawal-methods.notify-1-12")
          );
          setLoading(false);
        })
        .catch((error) => {
          notify(
            "error",
            t("view-account-with-drawal-methods.notify-1-14"),
            t("view-account-with-drawal-methods.notify-1-15")
          );
          setLoading(false);
        });
    } else {
      await addDoc(collection(db, "withdrawals"), {
        userId: user.uid,
        method: withdrawalMethod,
        payPalEmail: values.payPalEmail,
        bankAccount: "",
        iban: "",
        swift: "",
        emailCredit: "",
        dateCreated: moment().format(),
        dateUpdated: moment().format(),
      })
        .then(async () => {
          notify(
            "success",
            t("view-account-with-drawal-methods.notify-1-16"),
            t("view-account-with-drawal-methods.notify-1-17")
          );
          setLoading(false);
        })
        .catch((error) => {
          notify(
            "error",
            t("view-account-with-drawal-methods.notify-1-18"),
            t("view-account-with-drawal-methods.notify-1-19")
          );
          setLoading(false);
        });
    }
  };

  const getWithdrawal = useCallback(async () => {
    setLoadingData(true);
    const getAll = await getCollectionAllSearchData(
      "withdrawals",
      "userId",
      user.uid,
      "",
      ""
    );
    if (getAll.size > 0) {
      const id = getAll.docs[0].id;
      const qr = doc(db, "withdrawals", id);
      const get = await getDoc(qr);
      if (get.exists()) {
        setIdWithdrawals(get.id);
        formOne.setFieldsValue({
          bankAccount: get.data().bankAccount,
          iban: get.data().iban,
          swift: get.data().swift,
        });
        formTwo.setFieldsValue({
          payPalEmail: get.data().payPalEmail,
        });
        formThree.setFieldsValue({
          emailCredit: get.data().emailCredit,
        });
        setLoadingData(false);
      } else {
        // doc.data() will be undefined in this case
        notify(
          "info",
          t("view-account-with-drawal-methods.notify-1-28"),
          t("view-account-with-drawal-methods.notify-1-29")
        );
        setLoadingData(false);
      }
    } else {
      setLoadingData(false);
    }
    setLoadingData(false);
  }, [formOne, formThree, formTwo, notify, t, user.uid]);

  useEffect(() => {
    getWithdrawal();
  }, [getWithdrawal]);

  const onSelectMethod = (value) => {
    withdrawalMethod(value);
    getWithdrawal();
  };

  return (
    <Page title={t("view-account-with-drawal-methods.title-1")}>
      <div className="my-account">
        <CardHeaderPub title={t("view-account-with-drawal-methods.title-2")} />
        {loadingData ? (
          <Spin tip={t("view-account-with-drawal-methods.tip-1")}>
            <Card title={t("view-account-with-drawal-methods.title-3")}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <label>{t("view-account-with-drawal-methods.label-1")}</label>
                <Select
                  size="large"
                  defaultValue={"1"}
                  value={withdrawalMethod}
                  onChange={onSelectMethod}
                  options={[
                    {
                      value: "1",
                      label: t("view-account-with-drawal-methods.label-2"),
                    },
                    {
                      value: "2",
                      label: t("view-account-with-drawal-methods.label-3"),
                    },
                  ]}
                />
              </div>

              {withdrawalMethod === "1" ? (
                <Form
                  name="bankAccount"
                  layout="vertical"
                  initialValues={{}}
                  onFinish={onFinishOne}
                  form={formOne}
                >
                  <Form.Item
                    name="bankAccount"
                    label={t("view-account-with-drawal-methods.label-5")}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "view-account-with-drawal-methods.message-1"
                        ),
                      },
                      {
                        min: 3,
                        message: t(
                          "view-account-with-drawal-methods.message-2"
                        ),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t(
                        "view-account-with-drawal-methods.placeholder-1"
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    name="iban"
                    label={t("view-account-with-drawal-methods.label-6")}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "view-account-with-drawal-methods.message-3"
                        ),
                      },
                      {
                        min: 3,
                        message: t(
                          "view-account-with-drawal-methods.message-4"
                        ),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t(
                        "view-account-with-drawal-methods.placeholder-2"
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    name="swift"
                    label={t("view-account-with-drawal-methods.label-7")}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "view-account-with-drawal-methods.message-5"
                        ),
                      },
                      {
                        min: 3,
                        message: t(
                          "view-account-with-drawal-methods.message-6"
                        ),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t(
                        "view-account-with-drawal-methods.placeholder-3"
                      )}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={loading}
                      size="large"
                      type="primary"
                      htmlType="submit"
                    >
                      {t("view-account-with-drawal-methods.btn-1")}
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Form
                  name="payPalEmail"
                  layout="vertical"
                  initialValues={{}}
                  onFinish={onFinishTwo}
                  form={formTwo}
                >
                  <Form.Item
                    name="payPalEmail"
                    label={t("view-account-with-drawal-methods.label-8")}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "view-account-with-drawal-methods.message-7"
                        ),
                      },
                      {
                        type: "email",
                        message: t(
                          "view-account-with-drawal-methods.message-8"
                        ),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t(
                        "view-account-with-drawal-methods.placeholder-4"
                      )}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      loading={loading}
                      size="large"
                      type="primary"
                      htmlType="submit"
                    >
                      {t("view-account-with-drawal-methods.btn-1")}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Card>
          </Spin>
        ) : (
          <Card title={t("view-account-with-drawal-methods.title-1")}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                marginBottom: "1rem",
                width: "100%",
              }}
            >
              <label>{t("view-account-with-drawal-methods.label-1")}</label>
              <Select
                size="large"
                defaultValue={"1"}
                value={withdrawalMethod}
                onChange={(e) => setWithdrawalMethod(e)}
                options={[
                  {
                    value: "1",
                    label: t("view-account-with-drawal-methods.label-2"),
                  },
                  {
                    value: "2",
                    label: t("view-account-with-drawal-methods.label-3"),
                  },
                ]}
              />
            </div>

            {withdrawalMethod === "1" ? (
              <Form
                name="bankAccount"
                layout="vertical"
                initialValues={{}}
                onFinish={onFinishOne}
                form={formOne}
              >
                <Form.Item
                  name="bankAccount"
                  label={t("view-account-with-drawal-methods.label-5")}
                  rules={[
                    {
                      required: true,
                      message: t("view-account-with-drawal-methods.message-1"),
                    },
                    {
                      min: 3,
                      message: t("view-account-with-drawal-methods.message-2"),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t(
                      "view-account-with-drawal-methods.placeholder-1"
                    )}
                  />
                </Form.Item>
                <Form.Item
                  name="iban"
                  label={t("view-account-with-drawal-methods.label-6")}
                  rules={[
                    {
                      required: true,
                      message: t("view-account-with-drawal-methods.message-3"),
                    },
                    {
                      min: 3,
                      message: t("view-account-with-drawal-methods.message-4"),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t(
                      "view-account-with-drawal-methods.placeholder-2"
                    )}
                  />
                </Form.Item>
                <Form.Item
                  name="swift"
                  label={t("view-account-with-drawal-methods.label-7")}
                  rules={[
                    {
                      required: true,
                      message: t("view-account-with-drawal-methods.message-5"),
                    },
                    {
                      min: 3,
                      message: t("view-account-with-drawal-methods.message-6"),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t(
                      "view-account-with-drawal-methods.placeholder-3"
                    )}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    {t("view-account-with-drawal-methods.btn-1")}
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <Form
                name="payPalEmail"
                layout="vertical"
                initialValues={{}}
                onFinish={onFinishTwo}
                form={formTwo}
              >
                <Form.Item
                  name="payPalEmail"
                  label={t("view-account-with-drawal-methods.label-8")}
                  rules={[
                    {
                      required: true,
                      message: t("view-account-with-drawal-methods.message-7"),
                    },
                    {
                      type: "email",
                      message: t("view-account-with-drawal-methods.message-8"),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t(
                      "view-account-with-drawal-methods.placeholder-4"
                    )}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    size="large"
                    type="primary"
                    htmlType="submit"
                  >
                    {t("view-account-with-drawal-methods.btn-1")}
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
        )}
      </div>
    </Page>
  );
};

export default WithdrawalMethods;
