import React from "react";
import { AdminMessages, Page } from "../../components";
import "../../assets/styles/message-all.css";
import { useState } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { Avatar, Divider, List, Skeleton, Typography } from "antd";
import { useCallback } from "react";
import InfiniteScroll from "react-infinite-scroller";

const Messages = () => {
  const [usersChat, setUsersChat] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scrollParentRef, setScrollParentRef] = useState(null);

  const getUsersChat = useCallback(() => {
    if (loading) {
      return;
    }

    setLoading(true);

    const arrayMessages = [];
    const q = query(collection(db, "users-chat"));
    const unsubscribeChats = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docs.map((doc) =>
        arrayMessages.push({ ...doc.data(), id: doc.id })
      );
      setUsersChat(arrayMessages.sort((a, b) => a.date - b.date));
    });
    setLoading(false);
    return () => {
      unsubscribeChats();
    };
  }, [loading]);

  useEffect(() => {
    getUsersChat();
  }, [getUsersChat]);

  const avatarUser = (val) => {
    const name = val.split(" ");
    const firstLetter = name[0];
    const secondLetter = name[1] === undefined ? " " : name[1];
    const userName = `${firstLetter[0]}${secondLetter[0]}`;
    return userName;
  };

  return (
    <Page title="All Message">
      <div className="message-all">
        <div className="message-all__sidebar">
          <div className="message-all__sidebar-header">
            <Typography.Title level={3}>All messages</Typography.Title>
            <Divider />
          </div>

          <div
            ref={(ref) => setScrollParentRef(ref)}
            className="message-all__sidebar-content"
          >
            {usersChat && (
              <InfiniteScroll
                pageStart={0}
                hasMore={true || false}
                loadMore={usersChat}
                loader={
                  <Skeleton
                    avatar
                    paragraph={{
                      rows: 1,
                    }}
                    active
                    key={0}
                    style={{ padding: "25px" }}
                  />
                }
                useWindow={false}
                getScrollParent={() => scrollParentRef}
              >
                <List
                  dataSource={usersChat}
                  renderItem={(item) => (
                    <List.Item
                      key={item.id}
                      className="message-all__sidebar-content__item"
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar size="large">
                            {avatarUser(item.username)}
                          </Avatar>
                        }
                        title={item.username}
                        description={item.email}
                      />
                      <div>seen</div>
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            )}
          </div>
        </div>
        <AdminMessages />
      </div>
    </Page>
  );
};

export default Messages;
