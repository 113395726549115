import React from "react";
import { HiUser, HiOutlineMail, HiOutlineUsers } from "react-icons/hi";
import { RiLogoutCircleFill } from "react-icons/ri";
import { CiViewList } from "react-icons/ci";
import { FiEdit, FiShoppingCart } from "react-icons/fi";
import {
  AiOutlineDashboard,
  AiOutlineUser,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { Menu, MenuItem, SubMenu, useProSidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { Badge, Tooltip } from "antd";
import { useUserAuth } from "../context/UserAuthContext";
import { useTranslation } from "react-i18next";
import { GiTakeMyMoney } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";

const SidebarAdmin = () => {
  const { collapsed } = useProSidebar();
  const { logOut, lang } = useUserAuth();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <Menu
      menuItemStyles={{
        button: ({ level, active }) => {
          // only apply styles on first level elements of the tree
          if (level === 0)
            return {
              backgroundColor: active ? "#f5f5f5" : undefined,
            };
          if (level === 1)
            return {
              backgroundColor: active ? "#f5f5f5" : undefined,
            };
        },
      }}
    >
      {collapsed ? (
        <Tooltip placement="right" title={t("components-sidebarAdmin.text-1")}>
          <MenuItem
            icon={<AiOutlineDashboard />}
            component={<Link to={`/${lang}/admin/dashboard`} />}
            active={location.pathname === `/${lang}/admin/dashboard` && true}
          >
            {t("components-sidebarAdmin.text-1")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<AiOutlineDashboard />}
          component={<Link to={`/${lang}/admin/dashboard`} />}
          active={
            location.pathname === `/${lang}/admin/dashboard` ? true : false
          }
        >
          {t("components-sidebarAdmin.text-1")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip placement="right" title={t("components-sidebarAdmin.text-2")}>
          <SubMenu
            label={t("components-sidebarAdmin.text-2")}
            icon={<AiOutlineUser />}
            active={location.pathname === `/${lang}/admin/login` ? true : false}
          >
            <MenuItem
              icon={<HiUser />}
              component={<Link to={`/${lang}/admin/login`} />}
              active={
                location.pathname === `/${lang}/admin/login` ? true : false
              }
            >
              {t("components-sidebarAdmin.text-3")}
            </MenuItem>
            <MenuItem onClick={logOut} icon={<RiLogoutCircleFill />}>
              {t("components-sidebarAdmin.text-6")}
            </MenuItem>
          </SubMenu>
        </Tooltip>
      ) : (
        <SubMenu
          label={t("components-sidebarAdmin.text-2")}
          icon={<AiOutlineUser />}
          active={
            location.pathname === `/${lang}/advertiser/me/login` ? true : false
          }
        >
          <MenuItem
            icon={<HiUser />}
            component={<Link to={`/${lang}/admin/login`} />}
            active={location.pathname === `/${lang}/admin/login` ? true : false}
          >
            {t("components-sidebarAdmin.text-3")}
          </MenuItem>
          <MenuItem onClick={logOut} icon={<RiLogoutCircleFill />}>
            {t("components-sidebarAdmin.text-6")}
          </MenuItem>
        </SubMenu>
      )}
      {collapsed ? (
        <Tooltip placement="right" title={t("components-sidebarAdmin.text-14")}>
          <MenuItem
            component={<Link to={`/${lang}/admin/users`} />}
            active={location.pathname === `/${lang}/admin/users` ? true : false}
            icon={<HiOutlineUsers />}
          >
            {t("components-sidebarAdmin.text-14")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          component={<Link to={`/${lang}/admin/users`} />}
          active={location.pathname === `/${lang}/admin/users` ? true : false}
          icon={<HiOutlineUsers />}
        >
          {t("components-sidebarAdmin.text-14")}
        </MenuItem>
      )}
      {collapsed ? (
        <Tooltip title={t("components-sidebarAdmin.text-7")} placement="right">
          <SubMenu
            label={t("components-sidebarAdmin.text-7")}
            icon={<FiShoppingCart />}
            active={
              location.pathname === `/${lang}/admin/campaigns` ||
              location.pathname === `/${lang}/admin/missions` ||
              location.pathname === `/${lang}/admin/depots`
                ? true
                : false
            }
          >
            <MenuItem
              icon={<GrMoney />}
              component={<Link to={`/${lang}/admin/depots`} />}
              active={
                location.pathname === `/${lang}/admin/depots` ? true : false
              }
            >
              {t("components-sidebarAdmin.text-5")}
            </MenuItem>
            <MenuItem
              icon={<AiOutlineUnorderedList />}
              component={<Link to={`/${lang}/admin/campaigns`} />}
              active={
                location.pathname === `/${lang}/admin/campaigns` ? true : false
              }
            >
              {t("components-sidebarAdmin.text-8")}
            </MenuItem>
            <MenuItem
              icon={<CiViewList />}
              component={<Link to={`/${lang}/admin/missions`} />}
              active={
                location.pathname === `/${lang}/admin/missions` ? true : false
              }
            >
              {t("components-sidebarAdmin.text-9")}
            </MenuItem>
          </SubMenu>
        </Tooltip>
      ) : (
        <SubMenu
          label={t("components-sidebarAdmin.text-7")}
          icon={<FiShoppingCart />}
          active={
            location.pathname === `/${lang}/admin/campaigns` ||
            location.pathname === `/${lang}/admin/missions` ||
            location.pathname === `/${lang}/admin/depots`
              ? true
              : false
          }
        >
          <MenuItem
            icon={<GrMoney />}
            component={<Link to={`/${lang}/admin/depots`} />}
            active={
              location.pathname === `/${lang}/admin/depots` ? true : false
            }
          >
            {t("components-sidebarAdmin.text-5")}
          </MenuItem>
          <MenuItem
            icon={<AiOutlineUnorderedList />}
            component={<Link to={`/${lang}/admin/campaigns`} />}
            active={
              location.pathname === `/${lang}/admin/campaigns` ? true : false
            }
          >
            {t("components-sidebarAdmin.text-8")}
          </MenuItem>
          <MenuItem
            icon={<CiViewList />}
            component={<Link to={`/${lang}/admin/missions`} />}
            active={
              location.pathname === `/${lang}/admin/missions` ? true : false
            }
          >
            {t("components-sidebarAdmin.text-9")}
          </MenuItem>
        </SubMenu>
      )}
      {collapsed ? (
        <Tooltip title={t("components-sidebarAdmin.text-10")} placement="right">
          <SubMenu
            label={t("components-sidebarAdmin.text-10")}
            icon={<FiEdit />}
            active={
              location.pathname === `/${lang}/admin/networks` ||
              location.pathname === `/${lang}/admin/proposals` ||
              location.pathname === `/${lang}/admin/gains`
                ? true
                : false
            }
          >
            <MenuItem
              icon={<GiTakeMyMoney />}
              component={<Link to={`/${lang}/admin/gains`} />}
              active={
                location.pathname === `/${lang}/admin/gains` ? true : false
              }
            >
              {t("components-sidebarAdmin.text-4")}
            </MenuItem>
            <MenuItem
              icon={<AiOutlineUnorderedList />}
              component={<Link to={`/${lang}/admin/networks`} />}
              active={
                location.pathname === `/${lang}/admin/networks` ? true : false
              }
            >
              {t("components-sidebarAdmin.text-11")}
            </MenuItem>
            <MenuItem
              icon={<CiViewList />}
              component={<Link to={`/${lang}/admin/proposals`} />}
              active={
                location.pathname === `/${lang}/admin/proposals` ? true : false
              }
            >
              {t("components-sidebarAdmin.text-12")}
            </MenuItem>
          </SubMenu>
        </Tooltip>
      ) : (
        <SubMenu
          label={t("components-sidebarAdmin.text-10")}
          icon={<FiEdit />}
          active={
            location.pathname === `/${lang}/admin/networks` ||
            location.pathname === `/${lang}/admin/proposals` ||
            location.pathname === `/${lang}/admin/gains`
              ? true
              : false
          }
        >
          <MenuItem
            icon={<GiTakeMyMoney />}
            component={<Link to={`/${lang}/admin/gains`} />}
            active={location.pathname === `/${lang}/admin/gains` ? true : false}
          >
            {t("components-sidebarAdmin.text-4")}
          </MenuItem>
          <MenuItem
            icon={<AiOutlineUnorderedList />}
            component={<Link to={`/${lang}/admin/networks`} />}
            active={
              location.pathname === `/${lang}/admin/networks` ? true : false
            }
          >
            {t("components-sidebarAdmin.text-11")}
          </MenuItem>
          <MenuItem
            icon={<CiViewList />}
            component={<Link to={`/${lang}/admin/proposals`} />}
            active={
              location.pathname === `/${lang}/admin/proposals` ? true : false
            }
          >
            {t("components-sidebarAdmin.text-12")}
          </MenuItem>
        </SubMenu>
      )}
      {collapsed ? (
        <Tooltip
          placement="right"
          title={
            <div>
              <span>{t("components-sidebarAdmin.text-13")}</span>
              <Badge count={1} />
            </div>
          }
        >
          <MenuItem
            icon={<HiOutlineMail />}
            component={<Link to={`/${lang}/admin/messages`} />}
            active={
              location.pathname === `/${lang}/admin/messages` ? true : false
            }
            suffix={<Badge count={1} />}
          >
            {t("components-sidebarAdmin.text-13")}
          </MenuItem>
        </Tooltip>
      ) : (
        <MenuItem
          icon={<HiOutlineMail />}
          component={<Link to={`/${lang}/admin/messages`} />}
          active={
            location.pathname === `/${lang}/admin/messages` ? true : false
          }
          suffix={<Badge count={1} />}
        >
          {t("components-sidebarAdmin.text-13")}
        </MenuItem>
      )}
    </Menu>
  );
};

export default SidebarAdmin;
