import React from "react";
import "../assets/styles/card-publisher.css";
import { Card, Typography } from "antd";
import { useTranslation } from "react-i18next";

const CardPublisher = () => {
  const { t } = useTranslation();
  return (
    <div className="card-publisher">
      <Card
        size="small"
        style={{ height: "230px", boxShadow: " 1px 1px 5px #888888" }}
        data-aos="zoom-in"
      >
        <Typography.Title level={5}>
          {t("section-cardPublisher.text-1")}
        </Typography.Title>
        <Typography.Title level={4}>
          {t("section-cardPublisher.text-2")}
        </Typography.Title>
        <Typography.Paragraph>
          {t("section-cardPublisher.text-3")}
        </Typography.Paragraph>
      </Card>
      <Card
        size="small"
        style={{ height: "230px", boxShadow: " 1px 1px 5px #888888" }}
        data-aos="zoom-in"
      >
        <Typography.Title level={5}>
          {t("section-cardPublisher.text-4")}
        </Typography.Title>
        <Typography.Title level={4}>
          {t("section-cardPublisher.text-5")}
        </Typography.Title>
        <Typography.Paragraph>
          {t("section-cardPublisher.text-6")}
        </Typography.Paragraph>
      </Card>
      <Card
        size="small"
        style={{ height: "230px", boxShadow: " 1px 1px 5px #888888" }}
        data-aos="zoom-in"
      >
        <Typography.Title level={5}>
          {t("section-cardPublisher.text-7")}
        </Typography.Title>
        <Typography.Title level={4}>
          {t("section-cardPublisher.text-8")}
        </Typography.Title>
        <Typography.Paragraph>
          {t("section-cardPublisher.text-9")}
        </Typography.Paragraph>
      </Card>
    </div>
  );
};

export default CardPublisher;
