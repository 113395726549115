import React, { useCallback, useEffect, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { useNotification } from "../utils/util-notification";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { leftJoin } from "array-join";
import { Country } from "country-state-city";
import { Avatar, Button, Card, Space, Tooltip } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import CardUrl from "./CardUrl";
import moment from "moment";
import snapchatImg from "../assets/media/snapchat.svg";

const SnapchatTable = ({ networkType, getMyDeposit }) => {
  const { user, lang, onValidate } = useUserAuth();
  const { notify } = useNotification();
  const [data, setData] = useState([]);
  const [activeTable, setActiveTable] = useState(0);
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getAllNetworks = useCallback(async () => {
    if (networkType !== "") {
      setActiveTable(1);
      setLoading(true);
      const q1 = query(
        collection(db, "networks"),
        where("networkType", "==", networkType)
      );
      const q2 = query(
        collection(db, "favorites"),
        where("userId", "==", user.uid)
      );
      const get1 = await getDocs(q1);
      const get2 = await getDocs(q2);
      setData(
        leftJoin(
          get1.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
          get2.docs.map((doc) => ({ idFavorite: doc.id, ...doc.data() })),
          (left) => left.id,
          (right) => right.idNetwork,
          (left, right) => ({ ...left, ...right })
        ).map((doc) => ({
          id: doc.id,
          idFavorite: doc.idFavorite,
          networkName: doc.networkName,
          url: doc.url,
          price: doc.gain * 1.4,
          visits: doc.visits,
          status: doc.status,
          country: doc.country,
          networkType: doc.networkType,
        }))
      );
      setLoading(false);
    }
  }, [networkType, user.uid]);

  useEffect(() => {
    getAllNetworks();
  }, [getAllNetworks]);

  //get flag country
  const getFlag = (country) => {
    const val = Country.getAllCountries().find((item) => item.name === country);
    return val.flag;
  };

  // Security network name and url
  const securityNetworkName = (name) => {
    const firstTwoLetters = name.substring(0, 3);
    if (getMyDeposit) {
      if (getMyDeposit > 0) {
        return name;
      } else {
        return `${firstTwoLetters}*****************`;
      }
    } else {
      return `${firstTwoLetters}*****************`;
    }
  };

  const securityUrl = (url) => {
    if (getMyDeposit) {
      if (getMyDeposit > 0) {
        return url;
      } else {
        return `https://www.snapchat.com/************`;
      }
    } else {
      return `https://www.snapchat.com/************`;
    }
  };

  const onClickFavorite = async (idNetwork, id, status) => {
    if (id !== undefined) {
      const q = doc(db, "favorites", id);
      await updateDoc(q, {
        status: !status,
        dateUpdated: moment().format(),
      })
        .then(async () => {
          notify(
            "success",
            t("sections-chooseNetwork.notify-1"),
            t("sections-chooseNetwork.notify-2")
          );
          getAllNetworks();
        })
        .catch((error) => {
          notify(
            "error",
            t("sections-chooseNetwork.notify-3"),
            t("sections-chooseNetwork.notify-4")
          );
        });
    } else {
      await addDoc(collection(db, "favorites"), {
        userId: user.uid,
        idNetwork: idNetwork,
        status: true,
        dateCreated: moment().format(),
        dateUpdated: moment().format(),
      })
        .then(() => {
          notify(
            "success",
            t("sections-chooseNetwork.notify-1"),
            t("sections-chooseNetwork.notify-5")
          );
          getAllNetworks();
        })
        .catch((error) => {
          notify(
            "error",
            t("sections-chooseNetwork.notify-3"),
            t("sections-chooseNetwork.notify-6")
          );
        });
    }
  };

  const getSumPrice = (rows) => {
    let sum = 0.0;
    rows.flatRows.map((row) => (sum += parseFloat(row.getValue("price"))));
    return `€${sum.toFixed(2)}`;
  };

  //defining columns outside of the component is fine, is stable
  const columns = [
    {
      accessorKey: "networkName",
      header: t("sections-chooseNetwork.text-1"),
      Cell: ({ row }) => securityNetworkName(row.original.networkName),
      size: 80,
    },
    {
      accessorKey: "country",
      size: 80,
      header: t("sections-chooseNetwork.text-2"),
      Cell: ({ row }) => (
        <Space size="small">
          <div>{getFlag(row.original.country)}</div>
          <div>{row.original.country}</div>
        </Space>
      ),
    },
    {
      accessorKey: "url",
      header: t("sections-chooseNetwork.text-3"),
      size: 50,
      Cell: ({ row }) =>
        getMyDeposit > 0 ? (
          <Tooltip placement="right" title={<CardUrl url={row.original.url} />}>
            <a
              href={row.original.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.original.url}
            </a>
          </Tooltip>
        ) : (
          <p style={{ color: "#1669d5" }}>{securityUrl(row.original.url)}</p>
        ),
    },
    {
      accessorKey: "price",
      header: t("sections-chooseNetwork.text-4"),
      Cell: ({ row }) => `€${parseFloat(row.original.price).toFixed(2)}`,
      size: 80,
    },
    {
      accessorKey: "visits",
      header: t("sections-chooseNetwork.text-6"),
      size: 80,
    },
    {
      accessorKey: "networkType",
      size: 80,
      header: t("sections-chooseNetwork.text-7"),
      Cell: ({ row }) =>
        getMyDeposit > 0 ? (
          <a href={row.original.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={snapchatImg} />
          </a>
        ) : (
          <Avatar src={snapchatImg} />
        ),
    },
  ];
  return (
    activeTable === 1 && (
      <div>
        <Card title={`${t("sections-chooseNetwork.title-2")} Snapchat`}>
          <MaterialReactTable
            enableRowSelection
            data={data && data}
            columns={columns}
            enableRowActions
            state={{ isLoading: loading }}
            localization={
              lang === "fr" ? MRT_Localization_FR : MRT_Localization_EN
            }
            renderTopToolbarCustomActions={({ table }) => {
              return (
                <div style={{ width: "100%" }}>
                  {getMyDeposit && getMyDeposit > 0 ? null : (
                    <Button
                      type="primary"
                      size="large"
                      style={{ width: "100%" }}
                      onClick={() => navigate(`/${lang}/advertiser/me/money`)}
                    >
                      {t("sections-chooseNetwork.btn-1")}
                    </Button>
                  )}
                </div>
              );
            }}
            renderRowActions={({ row }) => (
              <div
                style={{ display: "flex", flexWrap: "nowrap", gap: "0.5rem" }}
              >
                {row.original.status ? (
                  <Tooltip
                    placement="bottom"
                    title={t("sections-chooseNetwork.tooltip-1")}
                  >
                    <Button
                      icon={<HeartFilled />}
                      onClick={() =>
                        onClickFavorite(
                          row.original.idFavorite,
                          row.original.idFavorite,
                          row.original.status
                        )
                      }
                      disabled={getMyDeposit && getMyDeposit > 0 ? false : true}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="bottom"
                    title={t("sections-chooseNetwork.tooltip-2")}
                  >
                    <Button
                      icon={<HeartOutlined />}
                      onClick={() =>
                        onClickFavorite(
                          row.original.id,
                          row.original.idFavorite,
                          row.original.status
                        )
                      }
                      disabled={getMyDeposit && getMyDeposit > 0 ? false : true}
                    />
                  </Tooltip>
                )}
              </div>
            )}
            muiTableFooterProps={({ table }) => {
              setTable(table.getSelectedRowModel());
            }}
          />
        </Card>
        {getMyDeposit && getMyDeposit > 0
          ? table &&
            table.rows.length > 0 && (
              <div
                style={{
                  display: "flex",
                  position: "sticky",
                  bottom: 0,
                  zIndex: 99,
                }}
              >
                <Button
                  size="large"
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => onValidate(table)}
                >
                  {t("sections-chooseNetwork.btn")} {getSumPrice(table)}
                </Button>
              </div>
            )
          : null}
      </div>
    )
  );
};

export default SnapchatTable;
