import {
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  Typography,
  Segmented,
  Avatar,
} from "antd";
import PhoneInput from "react-phone-input-2";
import React, { Fragment, useState } from "react";
import { Page } from "../../components";
import { NavBarAuth } from "../../global";
import {
  LockOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import AdvImg from "../../assets/media/type_annonceur.png";
import PubImg from "../../assets/media/type_editeur.png";
import { useUserAuth } from "../../context/UserAuthContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Register = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  //hooks state
  const [currentSegmented, setCurrentSegmented] = useState("adv");
  const [steps, setSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataStepFirst, setDataStepFirst] = useState([]);
  const [dataStepSecond, setDataStepSecond] = useState([]);
  const { signUp, userAuth, user, lang } = useUserAuth();
  const navigate = useNavigate();

  // onChage for Segmented
  const onChangeSegmented = (value) => {
    setCurrentSegmented(value);
  };

  // const clickGoogleBtn = async () => {
  //   googleSignIn();
  //   if (user && userAuth) {
  //     navigate(`/${lang}`);
  //   } else {
  //     form.setFieldsValue({
  //       email: user?.email,
  //     });
  //     navigate(`/${lang}/register`);
  //   }
  // };

  useEffect(() => {
    if (user && userAuth) {
      navigate(`/${lang}`);
    } else {
      form.setFieldsValue({
        email: user?.email,
      });
      navigate(`/${lang}/register`);
    }
  }, [form, lang, navigate, user, userAuth]);

  //data option for segmented
  const optionsSegmented = [
    {
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Avatar shape="square" size="large" src={AdvImg} />
          <Typography.Text>{t("view-auth-register.text-1")}</Typography.Text>
        </div>
      ),
      value: "adv",
    },
    {
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Avatar shape="square" size="large" src={PubImg} />
          <Typography>{t("view-auth-register.text-2")}</Typography>
        </div>
      ),
      value: "pub",
    },
  ];
  //onclick next and prev
  const clickPrev = () => {
    if (user && steps > 1) {
      setSteps(steps - 1);
    } else {
      setSteps(steps - 1);
    }
  };
  //on click finish
  const onFinishStepOne = (values) => {
    setLoading(true);
    setDataStepFirst(values);
    if (dataStepFirst) {
      setSteps(1);
    }
    setLoading(false);
  };
  const onFinishStepTwoAdv = (values) => {
    setLoading(true);
    setDataStepSecond(values);
    if (dataStepSecond) {
      setSteps(2);
    }
    setLoading(false);
  };

  const onFinishStepTwoPub = (values) => {
    setLoading(true);

    setDataStepSecond({ type: "pub" });
    if (dataStepSecond) {
      setSteps(2);
    }
    setLoading(false);
  };

  const onFinishStepFree = async (values) => {
    setLoading(true);
    await signUp(
      dataStepFirst.email,
      dataStepFirst.password,
      dataStepSecond.type,
      values.firstName,
      values.lastName,
      `+${values.phoneNumber}`
    );
    setLoading(false);
  };

  return (
    <Page title={t("view-auth-register.title-1")}>
      <div className="auth">
        <NavBarAuth />
        <div className="auth-content">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography.Title
              style={{
                fontSize: "42px",
                color: "#000336",
                fontWeight: "bold",
                paddingBottom: "10px",
                borderBottom: "4px solid #2c4e7a",
                marginBottom: "3rem",
              }}
              data-aos="fade-left"
            >
              {t("view-auth-register.title-1")}
            </Typography.Title>
            {/* <Button
              type="default"
              icon={
                <span style={{ marginRight: "0.5rem" }}>
                  <FcGoogle />
                </span>
              }
              onClick={clickGoogleBtn}
              size="large"
              data-aos="fade-right"
            >
              {t("view-auth-register.btn-1")}
            </Button>
            <Divider data-aos="zoom-in">
              {t("view-auth-register.text-2-1")}
            </Divider> */}
            {steps === 0 ? (
              <Form
                name="login-1"
                layout="vertical"
                onFinish={onFinishStepOne}
                form={form}
                data-aos="fade-left"
                style={{ maxWidth: 360 }}
              >
                <Fragment>
                  <Form.Item
                    name="email"
                    label={t("view-auth-register.label-1")}
                    rules={[
                      {
                        required: true,
                        message: t("view-auth-register.message-1"),
                      },
                      {
                        type: "email",
                        message: t("view-auth-register.message-2"),
                      },
                    ]}
                  >
                    <Input
                      prefix={<MailOutlined />}
                      placeholder={t("view-auth-register.placeholder-1")}
                      size="large"
                    />
                  </Form.Item>

                  {!user ? (
                    <Form.Item
                      name="password"
                      label={t("view-auth-register.label-2")}
                      rules={[
                        {
                          required: true,
                          message: t("view-auth-register.message-3"),
                        },
                        {
                          min: 6,
                          message: t("view-auth-register.message-4"),
                        },
                        {
                          pattern: "(?=.*[A-Z])",
                          message: t("view-auth-register.message-5"),
                        },
                        {
                          pattern: "(?=.*[0-9])",
                          message: t("view-auth-register.message-6"),
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        placeholder={t("view-auth-register.placeholder-2")}
                        size="large"
                      />
                    </Form.Item>
                  ) : null}
                  <Form.Item>
                    <Form.Item
                      name="terms"
                      valuePropName="checked"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: t("view-auth-register.message-7"),
                        },
                      ]}
                    >
                      <Checkbox>
                        {t("view-auth-register.text-3")}{" "}
                        <Link
                          to={`/${lang}/terms-conditions`}
                          style={{
                            color: "#2C4E7A",
                            textDecoration: "underline",
                          }}
                        >
                          {t("view-auth-register.text-4")}
                        </Link>
                      </Checkbox>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item name="accept" valuePropName="checked" noStyle>
                      <Checkbox>
                        <p style={{ maxWidth: "300px" }}>
                          {t("view-auth-register.text-5")}
                        </p>
                        <p style={{ marginTop: "-1rem" }}>
                          {t("view-auth-register.text-6")}
                        </p>
                      </Checkbox>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      size="large"
                    >
                      {t("view-auth-register.text-7")}
                    </Button>
                  </Form.Item>
                </Fragment>
              </Form>
            ) : steps === 1 ? (
              currentSegmented === "adv" ? (
                <Form
                  name="login-2"
                  layout="vertical"
                  initialValues={{ type: "pub" }}
                  onFinish={onFinishStepTwoAdv}
                  data-aos="fade-right"
                  style={{ maxWidth: 360 }}
                >
                  <Form.Item>
                    <Segmented
                      options={optionsSegmented}
                      onChange={onChangeSegmented}
                      value={currentSegmented}
                      block
                    />
                  </Form.Item>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: t("view-auth-register.message-8"),
                      },
                    ]}
                  >
                    <Radio.Group style={{ marginTop: "1rem" }}>
                      <Radio value="adv-agency">
                        {t("view-auth-register.text-9")}
                      </Radio>
                      <Radio value="adv-brand">
                        {t("view-auth-register.text-10")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item>
                    <div
                      style={{
                        margin: "2rem 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button type="default" onClick={clickPrev} size="large">
                        {t("view-auth-register.btn-2")}
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                        size="large"
                      >
                        {t("view-auth-register.btn-3")}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              ) : (
                <Form
                  onFinish={onFinishStepTwoPub}
                  layout="vertical"
                  initialValues={{ type: "pub" }}
                  style={{ maxWidth: 360 }}
                >
                  <Form.Item>
                    <Segmented
                      options={optionsSegmented}
                      onChange={onChangeSegmented}
                      value={currentSegmented}
                      block
                    />
                  </Form.Item>
                  <Form.Item>
                    <div
                      style={{
                        margin: "2rem 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button type="default" onClick={clickPrev} size="large">
                        {t("view-auth-register.btn-2")}
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                        size="large"
                      >
                        {t("view-auth-register.btn-3")}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              )
            ) : (
              <Form
                name="login-3"
                layout="vertical"
                initialValues={{}}
                onFinish={onFinishStepFree}
                style={{ maxWidth: 360 }}
              >
                <Fragment>
                  <Form.Item
                    name="firstName"
                    label={t("view-auth-register.label-3")}
                    rules={[
                      {
                        required: true,
                        message: t("view-auth-register.message-9"),
                      },
                      {
                        min: 3,
                        message: t("view-auth-register.message-10"),
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserAddOutlined />}
                      placeholder={t("view-auth-register.placeholder-3")}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    label={t("view-auth-register.label-4")}
                    rules={[
                      {
                        required: true,
                        message: t("view-auth-register.message-11"),
                      },
                      {
                        min: 3,
                        message: t("view-auth-register.message-12"),
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserSwitchOutlined />}
                      placeholder={t("view-auth-register.placeholder-4")}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="phoneNumber"
                    label={t("view-auth-register.label-5")}
                    rules={[
                      {
                        required: true,
                        message: t("view-auth-register.message-13"),
                      },
                      {
                        min: 10,
                        message: t("view-auth-register.message-14"),
                      },
                    ]}
                  >
                    <PhoneInput
                      enableSearch={true}
                      country={lang === "en" ? "us" : lang}
                      searchPlaceholder={t(
                        "view-auth-register.searchPlacehorder-1"
                      )}
                      searchNotFound={t("view-auth-register.searchNotFound-1")}
                      placeholder={t("view-auth-register.placeholder-5")}
                      size="large"
                    />
                  </Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "2rem 0",
                    }}
                  >
                    <Button type="default" onClick={clickPrev} size="large">
                      {t("view-auth-register.btn-4")}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      loading={loading}
                    >
                      {t("view-auth-register.btn-5")}
                    </Button>
                  </div>
                </Fragment>
              </Form>
            )}

            <Form.Item>
              {t("view-auth-register.text-13")}{" "}
              <Link
                to={`/${lang}/login`}
                style={{ color: "#2C4E7A", textDecoration: "underline" }}
              >
                {t("view-auth-register.text-12")}
              </Link>
            </Form.Item>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Register;
