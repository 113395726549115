import { Button, Result } from "antd";
import React from "react";
import { Page } from "../components";
import NotFoundImg from "../assets/media/404-Error.svg";
import { NavBarAuth } from "../global";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page404 = () => {
  //hooks navigate
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Page title={t("view-page404.title-1")}>
      <div className="page-404">
        <NavBarAuth />
        <Result
          style={{ marginTop: "-100px" }}
          subTitle={t("view-page404.subTitle-1")}
          icon={
            <img
              style={{ maxHeight: "32rem" }}
              src={NotFoundImg}
              alt="NotFoundImg"
            />
          }
          extra={
            <Button size="large" type="primary" onClick={() => navigate(-1)}>
              {t("view-page404.btn")}
            </Button>
          }
        />
      </div>
    </Page>
  );
};

export default Page404;
