import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { Card, Skeleton } from "antd";

const CardUrl = ({ url }) => {
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);
  const apiKey = "4784ead2e5e529b50300b03e013d6442";
  const apiUrl = `https://api.linkpreview.net/?key=${apiKey}&q=${url}`;

  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        setPreview(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, [apiUrl]);

  return (
    <Fragment>
      {!error && preview ? (
        <a href={preview.url} target="_blank" rel="noopener noreferrer">
          <Card
            size="small"
            hoverable
            cover={
              preview.image && <img src={preview.image} alt={preview.title} />
            }
            style={{ maxWidth: "350px" }}
            bodyStyle={{
              display: "flex",
              flexDirection: "column",
              gap: "0.6rem",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <h3>{preview.title}</h3>
            <h5>{preview.description}</h5>
            <a href={preview.url} target="_blank" rel="noopener noreferrer">
              {preview.url}
            </a>
          </Card>
        </a>
      ) : (
        <Skeleton></Skeleton>
      )}
    </Fragment>
  );
};


export default CardUrl;
