import {
  Avatar,
  Button,
  Form,
  Table,
  Tooltip,
  Space,
  Card,
  Empty,
  Select,
  InputNumber,
  Modal,
  Input,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React from "react";

import { CardUrl } from "../components";
import pressImg from "../assets/media/press.png";
import youtubeImg from "../assets/media/youtube.png";
import facebookImg from "../assets/media/facebook.svg";
import instagramImg from "../assets/media/instagram.png";
import snapchatImg from "../assets/media/snapchat.svg";
import twitterImg from "../assets/media/twitter.svg";
import tiktokImg from "../assets/media/tiktok.png";
import { useUserAuth } from "../context/UserAuthContext";
import { useCallback } from "react";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getCollectionAllData } from "../utils/getCollectionData";
import moment from "moment";
import { useNotification } from "../utils/util-notification";
import { Country } from "country-state-city";
import { Fragment } from "react";

const CartSUmmary = () => {
  const [formSummary] = Form.useForm();
  const [form] = Form.useForm();
  const {
    cart,
    removeOneCart,
    user,
    onDecreasingSteps,
    onFinishSummary,
    summary,
  } = useUserAuth();
  const { notify } = useNotification();
  const [dataCampaign, setDataCampaign] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  //get flag country
  const getFlag = (country) => {
    const val = Country.getAllCountries().find((item) => item.name === country);
    return val.flag;
  };

  const columns = [
    {
      title: t("sections-cartSummary.text-1"),
      dataIndex: "networkName",
      width: 120,
      sorter: (a, b) => a.networkName.length - b.networkName.length,
    },
    {
      title: t("sections-cartSummary.text-2"),
      dataIndex: "country",
      width: 120,
      render: (country, all) => (
        <Space size="small">
          <div>{getFlag(country)}</div>
          <div>{country}</div>
        </Space>
      ),
      sorter: (a, b) => a.networkName.length - b.networkName.length,
    },
    {
      title: t("sections-cartSummary.text-3"),
      dataIndex: "url",
      render: (url, all) => (
        <Tooltip placement="right" title={<CardUrl url={url} />}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Tooltip>
      ),
      width: 120,
      sorter: (a, b) => a.url.length - b.url.length,
    },
    {
      title: t("sections-cartSummary.text-4"),
      dataIndex: "price",
      render: (price, all) => `€${parseFloat(price).toFixed(2)}`,
      width: 120,
      sorter: (a, b) => a.price.length - b.price.length,
    },
    {
      title: t("sections-cartSummary.text-5"),
      dataIndex: "visits",
      width: 120,
      sorter: (a, b) => a.visits.length - b.visits.length,
    },
    {
      title: <div>{t("sections-cartSummary.text-6")}</div>,
      dataIndex: "networkType",
      render: (networkType, all) =>
        networkType === "facebook" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={facebookImg} />
          </a>
        ) : networkType === "twitter" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={twitterImg} />
          </a>
        ) : networkType === "youtube" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={youtubeImg} />
          </a>
        ) : networkType === "instagram" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={instagramImg} />
          </a>
        ) : networkType === "tiktok" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={tiktokImg} />
          </a>
        ) : networkType === "snapchat" ? (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={snapchatImg} />
          </a>
        ) : (
          <a href={all.url} target="_blank" rel="noopener noreferrer">
            <Avatar src={pressImg} />
          </a>
        ),
      width: 120,
    },
    {
      title: t("sections-cartSummary.text-7"),
      dataIndex: "actions",
      width: 100,
      fixed: "right",
      render: (id, all) => (
        <Tooltip placement="bottom" title={t("sections-cartSummary.tooltip-1")}>
          <Button
            danger
            onClick={() => removeOneCart(id)}
            icon={<DeleteOutlined />}
          />
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    if (summary) {
      formSummary.setFieldsValue({
        idCampaign: summary.id,
      });
    }
  }, [formSummary, summary]);

  const getAllCampaigns = useCallback(async () => {
    const q = query(
      collection(db, "campaigns"),
      where("userId", "==", user.uid)
    );
    const get = await getDocs(q);
    setDataCampaign(
      get.docs.map((doc) => ({
        id: doc.id,
        campaignName: doc.data().campaignName,
      }))
    );
  }, [user.uid]);

  useEffect(() => {
    getAllCampaigns();
  }, [getAllCampaigns]);

  const onFinishCampaign = async (values) => {
    setLoading(true);
    const response = await getCollectionAllData(
      "campaigns",
      user.uid,
      "campaignName",
      values.campaignName
    );
    if (response && response.size > 0) {
      notify(
        "error",
        t("view-advertisers-myCampaigns.notify-1-17"),
        t("view-advertisers-myCampaigns.notify-1-18")
      );
      setLoading(false);
    } else {
      await addDoc(collection(db, "campaigns"), {
        userId: user.uid,
        campaignName: values.campaignName,
        budget: values.budget,
        dateCreated: moment().format(),
        dateUpdated: moment().format(),
      })
        .then(async () => {
          notify(
            "success",
            t("view-advertisers-myCampaigns.notify-1-20"),
            t("view-advertisers-myCampaigns.notify-1-21")
          );
          setLoading(false);
          getAllCampaigns();
          form.resetFields();
        })
        .catch((error) => {
          notify(
            "error",
            t("view-advertisers-myCampaigns.notify-1-23"),
            t("view-advertisers-myCampaigns.notify-1-24")
          );
          setLoading(false);
        });
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const showModal = () => {
    form.resetFields();
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!cart || cart.length <= 0) {
      onDecreasingSteps(0);
    }
  }, [cart, onDecreasingSteps]);

  return (
    <Fragment>
      <Space size="large" direction="vertical">
        <Card>
          {cart.length > 0 ? (
            <Fragment>
              <Table
                scroll={{
                  x: 900,
                }}
                dataSource={cart && cart}
                columns={columns}
                loading={!cart ? true : false}
              />
              <h3 style={{ textAlign: "end", marginTop: "10px" }}>
                TOTAL:{" "}
                {`€${cart
                  .reduce(
                    (accumulator, currentItem) =>
                      parseFloat(accumulator) + parseFloat(currentItem.price),
                    0
                  )
                  .toFixed(2)}`}
              </h3>
            </Fragment>
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>{t("sections-cartSummary.desc-1")}</span>}
            >
              <Button
                type="primary"
                onClick={() => onDecreasingSteps(0)}
                size="large"
              >
                {t("sections-cartSummary.btn-1")}
              </Button>
            </Empty>
          )}
        </Card>

        {dataCampaign && cart.length > 0 && (
          <Card
            title={t("sections-cartSummary.title-1")}
            extra={
              <Button onClick={() => showModal()} size="large">
                {t("sections-cartSummary.btn-2")}
              </Button>
            }
          >
            {dataCampaign.length > 0 ? (
              <Form
                onFinish={onFinishSummary}
                layout="vertical"
                form={formSummary}
              >
                <Form.Item
                  label={t("sections-cartSummary.label-1")}
                  name="idCampaign"
                  rules={[
                    {
                      required: true,
                      message: t("sections-cartSummary.rules-1"),
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder={t("sections-cartSummary.placeholder-1")}
                  >
                    {dataCampaign?.map((item, key) => (
                      <Select.Option value={item.id} key={key}>
                        {item.campaignName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      margin: "2rem 2rem",
                      display: "flex",
                      justifyContent: "space-between",
                      position: "sticky",
                      bottom: 0,
                    }}
                  >
                    <Button onClick={() => onDecreasingSteps(0)} size="large">
                      {t("sections-cartSummary.btn-3")}
                    </Button>
                    <Button type="primary" htmlType="submit" size="large">
                      {t("sections-cartSummary.btn-4")}
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            ) : (
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 60 }}
                description={<span>{t("sections-cartSummary.desc-2")}</span>}
              >
                <Button type="primary" onClick={() => showModal()} size="large">
                  {t("sections-cartSummary.btn-5")}
                </Button>
              </Empty>
            )}
          </Card>
        )}
      </Space>
      <Modal
        title={t("view-advertisers-myCampaigns.title-16")}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={onFinishCampaign} form={form}>
          <Form.Item
            name="campaignName"
            label={t("view-advertisers-myCampaigns.label-1")}
            rules={[
              {
                required: true,
                message: t("view-advertisers-myCampaigns.message-1"),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t("view-advertisers-myCampaigns.placeholder-2")}
            />
          </Form.Item>
          <Form.Item
            name="budget"
            label={t("view-advertisers-myCampaigns.label-2")}
            rules={[
              {
                required: true,
                message: t("view-advertisers-myCampaigns.message-2"),
              },
            ]}
          >
            <InputNumber
              prefix="€"
              decimalSeparator=","
              min={1}
              step="0.01"
              placeholder="1,00"
              style={{ width: "100%" }}
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "1rem",
              }}
            >
              <Button type="default" onClick={handleCancel} size="large">
                {t("view-advertisers-myCampaigns.btn-3")}
              </Button>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t("view-advertisers-myCampaigns.btn-5")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default CartSUmmary;
