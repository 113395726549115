import React from "react";
import { Page } from "../components";
import {
  Blogs,
  CardPublisher,
  Count,
  GetStarted,
  Hero,
  Social,
  UseApp,
} from "../sections";
import { useTranslation } from "react-i18next";

const Publisher = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("view-publisher.title")}>
      <Hero />
      <CardPublisher />
      {/* <Partner /> */}
      <Count />
      <Social />
      <UseApp />
      <GetStarted />
      <Blogs />
    </Page>
  );
};

export default Publisher;
